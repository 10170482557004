import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import firebase from "../../../firebase";
import Loading from "../../loading/loading";
import ProposalIntroForm from "./proposal-intro-form";
import ProposalWorkingForm from "./proposal-working-form";
import ProposalOverviewForm from "./proposal-overview-form";
import ProposalBeautifulForm from "./proposal-lets-make-it-b";
import ProposalTechForm from "./proposal-tech-form";
import {
  uploadFileBranding,
  deleteFileBranding,
} from "../../../actions/tasks-actions";
import ProposalCostForm from "./proposal-cost-form";
import { Link } from "react-router-dom";
import { Button, Form } from "semantic-ui-react";
import {
  addProjectCommentC,
  updateProjectCommentC,
  deleteProjectCommentC,
  addProjectJourney,
} from "../../../actions/project-actions";
import cuid from "cuid";
import ProposalJourneyForm from "./proposal-journey-form";

const mapStateToProps = (state, ownProps) => {
  const profile = state.firebase.profile;
  return {
    profile,
  };
};

const actions = {
  uploadFileBranding,
  deleteFileBranding,
  addProjectCommentC,
  updateProjectCommentC,
  deleteProjectCommentC,
  addProjectJourney,
};

class ProjectOverviewProposal extends Component {
  state = {
    project: null,
    comments: [],
    packages: [],
    services: [],
    clients: [],
    journies: [],
    showForm: false,
    addText: "",
    editText: "",
    editId: "",
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);

    const db = firebase.firestore();

    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const projectSnap = await projectQuery.get();

    const pricingQuery = await db.collection("pricing");
    const pricingSnap = await pricingQuery.get();

    const usersQuery = await db.collection("users");
    const usersSnap = await usersQuery.get();

    const journeyQuery = await db.collection("journey");
    const journeySnap = await journeyQuery.get();

    const packages = pricingSnap.docs
      .filter((pricing) => pricing.data().type === "package")
      .map((pricing) => pricing.data());
    const services = pricingSnap.docs
      .filter((pricing) => pricing.data().type === "service")
      .map((pricing) => pricing.data());
    const clients = usersSnap.docs
      .filter((user) => user.data().role === "client")
      .map((client) => client.data());
    const journies = journeySnap.docs.map((j) => j.data());

    console.log(journies);

    await this.setState({
      project: projectSnap.data(),
      comments: projectSnap.data().commentsC || [],
      packages,
      services,
      clients,
      journies,
    });
  }

  submitForm = (id, value, field) => {
    this.props.changeField(id, value, field);
  };
  handleAddChange = async (e) => {
    await this.setState({ addText: e.target.value });
  };
  handleEditChange = async (e) => {
    await this.setState({ editText: e.target.value });
  };
  handleSubmitAdd = async (e) => {
    e.preventDefault();
    const id = cuid();
    await this.props.addProjectCommentC(
      this.state.project.id,
      this.state.addText,
      id,
      this.props.profile.name
    );
    const newComments = [
      ...this.state.comments,
      { id: id, text: this.state.addText, author: this.props.profile.name },
    ];
    await this.setState({ addText: "", comments: newComments });
  };
  handleShowForm = async (text, id) => {
    await this.setState({ editText: text, showForm: true, showId: id });
  };
  handleSubmitEdit = async (e, commentId) => {
    e.preventDefault();
    await this.props.updateProjectCommentC(
      this.state.project,
      this.state.editText,
      commentId,
      this.props.profile.name
    );
    const newComments = this.state.comments.map((comment) =>
      comment.id === commentId
        ? {
            id: commentId,
            text: this.state.editText,
            author: this.props.profile.name,
          }
        : comment
    );
    await this.setState({
      editText: "",
      showForm: false,
      comments: newComments,
    });
  };
  handleDelete = async (comment) => {
    await this.props.deleteProjectCommentC(this.state.project.id, comment);
    const newComments = this.state.comments.filter(
      (oldComment) => oldComment.id !== comment.id
    );
    await this.setState({
      editText: "",
      showForm: false,
      comments: newComments,
    });
  };

  render() {
    const { project, packages, services, clients, journies } = this.state;

    if (project === null) {
      return <Loading />;
    }

    return (
      <div>
        <div className="project-proposal">
          <div className={"project-proposal-header"}>
            <div>
              <h2>{project.title}</h2>
              <h2 style={{ fontSize: "2.1em" }}>PACKAGE AND COSTS</h2>
            </div>
            <p onClick={(e) => window.open("/")}>Client's view</p>
          </div>
          <div>
            <ProposalIntroForm project={project} clients={clients} />
            <ProposalJourneyForm project={project} journies={journies} />
            <ProposalWorkingForm project={project} />
            <ProposalOverviewForm project={project} />
            <ProposalBeautifulForm project={project} />
            <ProposalTechForm project={project} />
            <ProposalCostForm
              project={project}
              packages={packages}
              services={services}
            />
            <div className="proposal-form-border">
              <h4 className="project-proposal-section-header">PageList</h4>
              {project.pages && project.pages.length > 0 ? (
                <div>
                  {project.pages.map((page) => (
                    <div
                      style={{ marginBottom: "5px", fontSize: "15px" }}
                      key={page.id}
                    >
                      <Link
                        className={"proposal-pages-page"}
                        to={`/dashboard/project-overview/${project.id}/page-sketches-editor/${page.id}`}
                      >
                        {page.pageName}
                      </Link>
                      <p style={{ fontSize: "13px" }}>
                        {page.description || ""}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <Link
                    className={"proposal-pages-link"}
                    to={`/dashboard/project-overview/${project.id}/page-sketche`}
                  >
                    PUT PAGES TOGETHER
                  </Link>
                </div>
              )}
            </div>
            <div className="proposal-form-border">
              <h4 className="project-proposal-section-header">Comments</h4>
              <br />
              <div className="project-overview-container-bottom">
                {this.state.comments.map((comment) => (
                  <div
                    key={comment.id}
                    style={{ width: "75%" }}
                    className="proposal-comment-flex"
                  >
                    <div className="proposal-comment" key={comment.id}>
                      {this.state.showForm &&
                      this.state.showId === comment.id ? (
                        <div>
                          <p className={"comment-inside-h"}>{comment.author}</p>
                          <form
                            style={{}}
                            key={comment.id}
                            action=""
                            onSubmit={(e) => {
                              this.handleSubmitEdit(e, comment.id);
                            }}
                          >
                            <input
                              value={this.state.editText}
                              className="comments-add-input-po"
                              placeholder="Add Comment..."
                              onChange={this.handleEditChange}
                              autoFocus="true"
                            />
                          </form>
                          <div className={"comment-inside-f"}>
                            <span
                              onClick={() =>
                                this.handleShowForm(comment.text, comment.id)
                              }
                            >
                              EDIT
                            </span>
                            <span onClick={(e) => this.handleDelete(comment)}>
                              DELETE
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className={"comment-inside"}>
                          <p className={"comment-inside-h"}>{comment.author}</p>
                          <p className={"comment-inside-t"}>{comment.text}</p>
                          <div className={"comment-inside-f"}>
                            <span
                              onClick={() =>
                                this.handleShowForm(comment.text, comment.id)
                              }
                            >
                              EDIT
                            </span>
                            <span onClick={(e) => this.handleDelete(comment)}>
                              DELETE
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="revision-tasks-form proposal-ph"
                style={{ width: "75%", marginTop: "0px" }}
              >
                <form action="" onSubmit={(e) => this.handleSubmitAdd(e)}>
                  <textarea
                    onChange={this.handleAddChange}
                    id="text"
                    type="text"
                    required={true}
                    value={this.state.addText}
                    placeholder="Add Comment..."
                    autoComplete="off"
                    style={{ fontSize: "16px", height: "100px" }}
                  />
                  <div className="revision-tasks-form-form">
                    <div className="revision-tasks-form-control">
                      <div className={"revision-tasks-form-control-func"}>
                        <img
                          height={"32px"}
                          src={"/assets/icons/add.png"}
                          alt="."
                        />
                        <button type="submit">ADD COMMENT</button>
                      </div>
                      <div className="revision-tasks-form-control-func"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect([
    { collection: "projects" },
    { collection: "users" },
    { collection: "pricing" },
  ]),
  withRouter,
  connect(mapStateToProps, actions)
)(ProjectOverviewProposal);
