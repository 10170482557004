import React, {Component} from 'react';

import "./admin-settings.css";

import DashboardHeader from "../../layout-components/dashboard-header/dashboard-header";
import DashboardUsersTable from "../../layout-components/dashboard-users-table/dashboard-users-table";
import FormNewButton from "../../form-components/form-new-button/form-new-button";
import {deleteUser} from "../../../actions/user-actions";
import {connect} from "react-redux";
import {compose} from "redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import Loading from "../../loading/loading";
import AdminSettingsTable from "./admin-settings-table";
import {Link} from "react-router-dom";
import {createJourney, deleteJourney} from "../../../actions/journey-actions";
import AdminSettingsTablePricing from "./admin-settings-table-pricing";
import AdminSettingsTablePJ from "./admin-settings-table-pj";
import firebase from "../../../firebase";
import {Form, Input, Modal} from "semantic-ui-react";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import cuid from "cuid";

const mapStateToProps = state => ({
    journey: state.firestore.ordered.journey,
});

const actions = {
    createJourney,
    deleteJourney,
};


const ModalForm = ({handleSubmitModal, val, handleModalState, showModal, toggleModal}) => (
    <Modal open={showModal} onClose={toggleModal}>
        <Modal.Header>Create Project Journey</Modal.Header>
        <Modal.Content>
            <Form action="" onSubmit={handleSubmitModal}>
                <Form.Field>
                    <Input placeholder={"Title"} type='text'required value={val} onChange={handleModalState}/>
                </Form.Field>
                <Form.Field>
                    <FormSubmitButton text={"Add Journey"}/>
                </Form.Field>
            </Form>
        </Modal.Content>
    </Modal>
);

class PricingPackages extends Component {

    state = {
      journeys: [],
      showModal: false,
      modalText: "",
    };


    toggleModal = (e, modalText = "") => {
        this.setState(prevState =>({
            showModal: !prevState.showModal,
            modalText: modalText,
        }))
    };

    handleModalState = (e) => {
        this.setState({
            modalText: e.target.value
        })
    };

    handleSubmitModal =  async (e) => {
        e.preventDefault();
        const journey = {
            title: this.state.modalText,
            id: cuid(),
            devComponents: [],
            clientComponents: [],
        };
        await this.props.createJourney(journey);
        console.log(journey);
        this.setState(prevState => ({
            showModal: false,
            modalText: "",
            journeys: [...prevState.journeys, journey]
        }))
    };


    async componentDidMount() {
        const {firestore} = this.props;
        firestore.setListener(`journey`);

        const db = firebase.firestore();
        let journeys = [];
        await db.collection("journey").get()
            .then(function (querySnapshot) {
                for (let doc of querySnapshot.docs) {
                    console.log(doc.data());
                    journeys.push(doc.data());
                }
            })
            .catch(function (e) {
                console.log(e);
            });

        await this.setState({
            journeys
        })

    }

    async componentWillUnmount() {
        const {firestore} = this.props;
        await firestore.unsetListener(`journey`);
    }

    deleteJourney = (id) => {
        this.setState(prevState=> ({
            journeys: prevState.journeys.filter(journey => journey.id !== id)
        }));
        this.props.deleteJourney(id);
    };

    render() {

        if(!this.props.journey) {
            return <Loading/>
        }

        return (
            <div className="admin-settings-content-table">
                <ModalForm
                    handleSubmitModal={this.handleSubmitModal}
                    val={this.state.modalText}
                    handleModalState={this.handleModalState}
                    showModal={this.state.showModal}
                    toggleModal={this.toggleModal}


                />
                <h2 className="admin-settings-content-table-h">Project Journey</h2>
                <div className="admin-settings-content-table-add" onClick={this.toggleModal}><span>+ Add new project journey</span></div>
                <AdminSettingsTablePJ
                    deleteJourney={this.deleteJourney}
                    journeys={this.state.journeys}
                />
            </div>
        );
    }
}


export default compose(
    withFirestore,
    firestoreConnect([{collection: "journey"}]),
    connect(mapStateToProps, actions)
)(PricingPackages)
