import React, {Component} from 'react';

import "./dashboard-project-grid.css";

import DashboardProjectNew from "../dashboard-project-card-new/dashboard-project-new";
import DashboardProjectCard from "../dashboard-project-card/dashboard-project-card";

// {key: "1", value: "newEnquiry", text: "New enquiry"},
// {key: "2", value: "depositInvoiceSent", text: "Deposit invoice sent"},
// {key: "3", value: "depositPaid", text: "Deposit paid"},
// {key: "4", value: "InitialSketchesPutTogether", text: "Initial sketches put together"},
// {key: "5", value: "contentReceived", text: "Content Received"},
// {key: "6", value: "contentDeadlineMissed", text: "Content deadline missed"},
// {key: "7", value: "contentUnderRevision ", text: "Content under revission"},
// {key: "8", value: "readyForSEO", text: "Ready for SEO"},
// {key: "9", value: "readyForInvoice", text: "Ready for invoice"},
// {key: "10", value: "completed", text: "Completed"},

class DashboardProjectGrid extends Component {

    render() {


        const {projects, filter, deleteProject, toggleArchiveProject, createTemplate, search} = this.props;
        let projectsList = [];
        console.log(filter);
        console.log(projects);
        if(filter === "recent") {
            projectsList = projects && projects.filter(project => (project.status !== "depositInvoiceSent" && project.status !== "newEnquiry" && project.status !== "templates" && project.status !== "archived") && project.title.toLowerCase().includes(search)).map(recent => (
                <DashboardProjectCard
                    createTemplate={createTemplate}
                    project={recent}
                    deleteProject={deleteProject}
                    toggleArchiveProject={toggleArchiveProject}
                    archiveType={(filter !== "archived") ? "archived" : "newEnquiry"}
                    archiveText={(filter !== "archived") ? "Archive" : "Restore"}
                    key={recent.id}
                    filter={filter}
                />
            ));
        } else {
            projectsList = projects && projects.filter(project => project.status === filter && project.title.toLowerCase().includes(search)).map(recent => (
                <DashboardProjectCard
                    createTemplate={createTemplate}
                    project={recent}
                    deleteProject={deleteProject}
                    toggleArchiveProject={toggleArchiveProject}
                    archiveType={(filter !== "archived") ? "archived" : "newEnquiry"}
                    archiveText={(filter !== "archived") ? "Archive" : "Restore"}
                    key={recent.id}
                    filter={filter}
                />
            ));

        }


        return (
            <div className="ui grid" style={{"margin": "0 auto 20px", "maxWidth": "1200px"}}>
                {filter === "newEnquiry" && <DashboardProjectNew />}
                {projectsList}
            </div>
        );
    }
}

export default DashboardProjectGrid;