import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import firebase from "../../../firebase";
import { Dimmer, Loader } from "semantic-ui-react";

import "./client-dropzone.css";

const Dropzone = ({ uploadImage, type }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadImage(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
        type
      );
    },
    [uploadImage]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={""}>
        <img width={"100%"} src="/assets/icons/brand-upload.png" alt="" />
      </div>
    </div>
  );
};

class ClientDropzone extends React.Component {
  state = {
    img: null,
    imagesToDisplay: [],
  };

  onImageUpload = async (files) => {
    console.log(files);
    await this.setState({ img: files });
    await this.props.uploadFile(this.props.form, this.state.img[0]);
    this.setState({ img: null });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.form.files) {
      this.setState({ imagesToDisplay: nextProps.form.files });
    }
  }

  componentDidMount() {
    if (this.props.form.files) {
      this.setState({ imagesToDisplay: this.props.form.files });
    }
  }

  detectImage = (name) => {
    let getExt = name.split(".");
    getExt = getExt[getExt.length - 1];
    return (
      getExt === "jpg" ||
      getExt === "jpeg" ||
      getExt === "JPG" ||
      getExt === "JPEG" ||
      getExt === "png" ||
      getExt === "PNG"
    );
  };

  downloadImage = (imgUrl, imgName) => {
    var storage = firebase.storage();
    var storageRef = storage.refFromURL(imgUrl);
    storageRef
      .getDownloadURL()
      .then(function (url) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function (event) {
          var blob = xhr.response;
          let a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          //Create a DOMString representing the blob
          //and point the link element towards it
          let urlNew = window.URL.createObjectURL(blob);
          a.href = urlNew;
          a.setAttribute("id", "tempDownloadFile");
          a.download = imgName;
          a.click();
          let element = document.getElementById("tempDownloadFile");
          element.parentNode.removeChild(element);
          window.URL.revokeObjectURL(url);
        };
        xhr.open("GET", url);
        xhr.send();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { form, deleteImage } = this.props;

    return (
      <div className="client-dnd-main">
        {this.state.img != null && (
          <Dimmer active page>
            <Loader />
          </Dimmer>
        )}
        <h4>{form.title}</h4>
        {form.description.length > 0 && (
          <p>
            {form.description}{" "}
            {form.length && form.length > 0 && (
              <span>Upload up to {form.length} files.</span>
            )}
          </p>
        )}
        <div className="branding-proposal-upload-files">
          {this.state.imagesToDisplay &&
            this.state.imagesToDisplay.map((file) => (
              <div key={file.name} className="branding-border">
                {this.detectImage(file.name) ? (
                  <img
                    key={file.name}
                    className="branding-border-image"
                    src={file.url}
                    alt={file.name}
                    onClick={() => this.toggleModal(file.url, file.name)}
                  />
                ) : (
                  <img
                    className="branding-border-image"
                    src="/assets/form/Document-placeholder.png"
                    alt={file.name}
                  />
                )}
                <div className="branding-border-image-control">
                  <span onClick={(e) => deleteImage(form, file)}>REMOVE</span>
                  <span
                    onClick={(e) => this.downloadImage(file.url, file.name)}
                  >
                    DOWNLOAD
                  </span>
                </div>
              </div>
            ))}
        </div>
        <div>
          <Dropzone uploadImage={this.onImageUpload} type={"img"} />
        </div>
      </div>
    );
  }
}

export default ClientDropzone;
