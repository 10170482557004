import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Select } from "semantic-ui-react";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import SelectHook from "./select-hook";
import { connect } from "react-redux";
import { updateProject } from "../../../actions/project-actions";

const ProposalOverviewForm = ({ project, updateProject }) => {
  const { register, control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      scope: project.scope || "",
      usp: project.usp || "",
      hywwwfy: project.hywwwfy || "",
      yourTargetAudience: project.yourTargetAudience || "",
      yourCompetitors: project.yourCompetitors || "",
    },
  });

  const onSubmit = (data) => {
    const updatedProject = { ...project, ...data };
    updateProject(updatedProject);
  };

  return (
    <div className="proposal-form-border">
      <h4 className="project-proposal-section-header">Project Overview</h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <Controller
            setValue={setValue}
            label="Scope"
            value={getValues("scope")}
            name="scope"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <Controller
            setValue={setValue}
            label="How your website will work for you"
            value={getValues("hywwwfy")}
            name="hywwwfy"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <Controller
            setValue={setValue}
            value={getValues("yourTargetAudience")}
            label="Your Target Audience"
            name="yourTargetAudience"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <Controller
            setValue={setValue}
            value={getValues("usp")}
            label="USP's"
            name="usp"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Button type="submit" className="fluid">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default connect(null, { updateProject })(ProposalOverviewForm);
