import React, {Component} from 'react';
import {Link, NavLink, Route, Redirect, Switch} from "react-router-dom";
import "./admin-settings.css";
import ManageClients from "./manage-clients";
import ManageTeam from "./manage-team";
import PricingPackages from "./pricing-packages";
import Sketch from "./sketch";
import ProjectJourneys from "./project-journeys";
import DashboardProfile from "../dashboard-profile/dashboard-profile"
import Profile from "./profile";

class AdminSettings extends Component {
    render() {
        return (
            <div className={"admin-settings"}>
                <div className={"admin-settings-header"}>
                    <Link to="/dashboard/recent">
                        <img src={"/assets/logo/logo.png"} alt="logo"/>
                    </Link>
                </div>
                <div className={"admin-settings-main"}>
                    <div className={"admin-settings-side"}>
                        <Link to="/dashboard/recent">
                            <div className={"admin-settings-side-back"}>
                                <div><img src={"/assets/icons/arrow.svg"} alt=""/></div>
                                <div className={"admin-settings-side-back-text"}>Back to dashboard</div>
                            </div>
                        </Link>
                        <div className={"admin-settings-side-main"}>
                            <NavLink exact activeClassName={"admin-settings-active"} to="/dashboard/admin-settings/profile"><div>your account</div></NavLink>
                            <NavLink exact activeClassName={"admin-settings-active"} to="/dashboard/admin-settings/manage-clients"><div>manage clients</div></NavLink>
                            <NavLink exact activeClassName={"admin-settings-active"} to="/dashboard/admin-settings/manage-team"><div>manage team</div></NavLink>
                            <NavLink activeClassName={"admin-settings-active"} to="/dashboard/admin-settings/sketch-components"><div>sketch components</div></NavLink>
                            <NavLink activeClassName={"admin-settings-active"} to="/dashboard/admin-settings/pricing-packages"><div>pricing & packages</div></NavLink>
                            <NavLink activeClassName={"admin-settings-active"} to="/dashboard/admin-settings/project-journeys"><div>project journeys</div></NavLink>
                        </div>
                    </div>
                    <div className={"admin-settings-content"}>
                        <Switch>
                            <Route exact path="/dashboard/admin-settings" render={() => <Redirect to="/dashboard/admin-settings/manage-clients" />}/>
                            <Route
                                path={"/dashboard/admin-settings/manage-clients"}
                                component={ManageClients}
                            />
                            <Route path={"/dashboard/admin-settings/manage-team"}
                                   component={ManageTeam}
                            />
                            <Route path={"/dashboard/admin-settings/profile"}
                                   component={Profile}
                            />
                            <Route path={"/dashboard/admin-settings/pricing-packages"}
                                   component={PricingPackages}
                            />
                            <Route path={"/dashboard/admin-settings/sketch-components"}
                                   component={Sketch}
                            />
                            <Route path={"/dashboard/admin-settings/project-journeys"}
                                   component={ProjectJourneys}
                            />
                        </Switch>



                    </div>
                </div>
            </div>
        );
    }
}

export default AdminSettings;
