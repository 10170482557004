import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./revision.css";
import { compose } from "redux";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import cuid from "cuid";
import {
  manageTasks,
  uploadFile,
  deleteFile,
  manageTaskComments,
  archiveRevision,
} from "../../../actions/tasks-actions";
import Loading from "../../loading/loading";
import firebase from "../../../firebase";
import { Form, Input, Modal } from "semantic-ui-react";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import TextareaAutosize from "react-textarea-autosize";
import PreviousRevisions from "./previous-revisions";

const actions = {
  manageTasks,
  uploadFile,
  deleteFile,
  manageTaskComments,
  archiveRevision,
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const profile = state.firebase.profile;

  return {
    id,
    profile,
  };
};

const FileModals = ({
  files,
  toggleModal,
  modalOpened,
  deleteFile,
  downloadFile,
  detectImage,
  modalId,
}) => (
  <Modal open={modalOpened} onClose={(e) => toggleModal(e, [])}>
    <Modal.Header>Attached Files</Modal.Header>
    <Modal.Content>
      <div style={{ display: "flex" }}>
        {files &&
          files.map((file) => (
            <div key={file.id} className="branding-border">
              {detectImage(file.name) ? (
                <img
                  className="branding-border-image"
                  src={file.url}
                  alt={file.name}
                />
              ) : (
                <img
                  className="branding-border-image"
                  src="/assets/form/Document-placeholder.png"
                  alt={file.name}
                />
              )}
              <div className="branding-border-image-control">
                <span onClick={(e) => deleteFile(file.id, file.name, modalId)}>
                  REMOVE
                </span>
                <span onClick={(e) => downloadFile(file.url, file.name)}>
                  DOWNLOAD
                </span>
              </div>
            </div>
          ))}
      </div>
    </Modal.Content>
  </Modal>
);

const ConfirmModal = ({
  modalOpened,
  toggleModal,
  archiveCurrentSession,
  header,
  text,
}) => (
  <Modal open={modalOpened} onClose={toggleModal}>
    <Modal.Header>
      <h4>{header}</h4>
    </Modal.Header>

    <Modal.Content>
      <p>{text}</p>
    </Modal.Content>
    <Modal.Actions>
      <button className="ui button" onClick={toggleModal}>
        CANCEL
      </button>
      <button className="ui button primary" onClick={archiveCurrentSession}>
        CONFIRM
      </button>
    </Modal.Actions>
  </Modal>
);

class Revision extends Component {
  state = {
    modalConfirmOpened: false,
    modalConfirmOpenedRefer: false,
    modalOpened: false,
    modalFiles: [],
    modalId: "",
    modalType: "",
    tasksToDisplay: [],
    taskCommentsToDisplay: [],
    projectRevisions: 1,
    task: {
      text: "",
      files: [],
      finished: false,
      id: "",
      author: "",
    },
    taskComment: {
      text: "",
      files: [],
      id: "",
      author: "",
      taskId: "",
    },
    showId: "",
    showReply: "",
    commentSubmitted: false,
    editTask: { id: null },
  };

  toggleModal = (e, modalFiles = [], modalId = null, modalType = "") => {
    this.setState((prevState) => ({
      modalFiles,
      modalId,
      modalType,
      modalOpened: !prevState.modalOpened,
    }));
  };

  toggleConfirmModal = () => {
    this.setState((prevState) => ({
      modalConfirmOpened: !prevState.modalConfirmOpened,
    }));
  };

  toggleConfirmModalRefer = () => {
    this.setState((prevState) => ({
      modalConfirmOpenedRefer: !prevState.modalConfirmOpenedRefer,
    }));
  };

  downloadImage = (imgUrl, imgName) => {
    var storage = firebase.storage();
    var storageRef = storage.refFromURL(imgUrl);
    storageRef
      .getDownloadURL()
      .then(function (url) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function (event) {
          var blob = xhr.response;
          let a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          //Create a DOMString representing the blob
          //and point the link element towards it
          let urlNew = window.URL.createObjectURL(blob);
          a.href = urlNew;
          a.setAttribute("id", "tempDownloadFile");
          a.download = imgName;
          a.click();
          let element = document.getElementById("tempDownloadFile");
          element.parentNode.removeChild(element);
          window.URL.revokeObjectURL(url);
        };
        xhr.open("GET", url);
        xhr.send();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  detectImage = (name) => {
    let getExt = name.split(".");
    getExt = getExt[getExt.length - 1];
    return (
      getExt === "jpg" ||
      getExt === "jpeg" ||
      getExt === "JPG" ||
      getExt === "JPEG" ||
      getExt === "png" ||
      getExt === "PNG"
    );
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);
    const db = firebase.firestore();
    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const form = await projectQuery.get();
    const formComponents = form.data().tasks;
    await this.setState({
      tasksToDisplay: formComponents || [],
    });
    const projectQuery2 = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const tasks = await projectQuery2.get();
    const taskComments = tasks.data().taskComments;
    await this.setState({
      taskCommentsToDisplay: taskComments || [],
      projectRevisions: tasks.data().revisions,
      project: form.data(),
    });
  }

  async componentWillUnmount() {
    const { firestore } = this.props;
    await firestore.unsetListener(`projects`);
  }

  handleChangeAddTask = (e) => {
    e.persist();
    this.setState((prevState) => ({
      task: {
        ...prevState.task,
        text: e.target.value,
      },
    }));
  };

  handleChangeAddComment = async (e, id) => {
    e.persist();

    if (this.state.taskComment.taskId !== id) {
      await this.setState((prevState) => ({
        taskComment: {
          text: "",
          files: [],
          id: "",
          author: "",
          taskId: "",
        },
      }));
    }

    if (!this.state.taskComment.id) {
      await this.setState((prevState) => ({
        taskComment: {
          ...prevState.taskComment,
          id: cuid(),
        },
      }));
    }

    if (!this.state.taskComment.taskId) {
      await this.setState((prevState) => ({
        taskComment: {
          ...prevState.taskComment,
          taskId: id,
        },
      }));
    }
    await this.setState((prevState) => ({
      taskComment: {
        ...prevState.taskComment,
        text: e.target.value,
      },
    }));
  };

  handleFileAddComment = async (e, id) => {
    e.persist();
    const file = e.target.files[0];
    if (!this.state.taskComment.id) {
      await this.setState((prevState) => ({
        taskComment: {
          ...prevState.taskComment,
          id: cuid(),
        },
      }));
    }
    if (!this.state.taskComment.taskId) {
      await this.setState((prevState) => ({
        taskComment: {
          ...prevState.taskComment,
          taskId: id,
        },
      }));
    }
    const fileURL = await this.props.uploadFile(
      file,
      this.state.taskComment.id
    );
    const fileObj = {
      id: cuid(),
      name: file.name,
      url: fileURL,
    };
    await this.setState((prevState) => ({
      taskComment: {
        ...prevState.taskComment,
        files: [...prevState.taskComment.files, fileObj],
      },
    }));
  };

  handleAddTaskComment = async (e, id) => {
    e.preventDefault();
    const newTaskComment = {
      text: this.state.taskComment.text,
      files: this.state.taskComment.files,
      id: this.state.taskComment.id || cuid(),
      author: this.props.profile.name,
      taskId: id,
    };

    await this.props.manageTaskComments(
      [...this.state.taskCommentsToDisplay, newTaskComment],
      this.props.id
    );
    await this.setState((prevState) => ({
      taskCommentsToDisplay: [
        ...prevState.taskCommentsToDisplay,
        newTaskComment,
      ],
      taskComment: {
        text: "",
        files: [],
        id: "",
        author: "",
        taskId: "",
      },
      showReply: "",
      showId: "",
    }));
  };

  handleFileAddTask = async (e) => {
    e.persist();
    const file = e.target.files[0];
    if (!this.state.task.id) {
      await this.setState((prevState) => ({
        task: {
          ...prevState.task,
          id: cuid(),
        },
      }));
    }
    const fileURL = await this.props.uploadFile(file, this.state.task.id);
    const fileObj = {
      id: cuid(),
      name: file.name,
      url: fileURL,
    };
    await this.setState((prevState) => ({
      task: {
        ...prevState.task,
        files: [...prevState.task.files, fileObj],
      },
    }));
  };

  handleAddTask = async (e) => {
    e.preventDefault();
    const newTask = {
      text: this.state.task.text,
      files: this.state.task.files,
      id: this.state.task.id || cuid(),
      author: this.props.profile.name,
      finished: false,
    };

    await this.props.manageTasks(
      [...this.state.tasksToDisplay, newTask],
      this.props.id
    );

    await this.setState((prevState) => ({
      tasksToDisplay: [...prevState.tasksToDisplay, newTask],
      task: {
        text: "",
        files: [],
        finished: false,
        id: "",
        author: "",
      },
    }));
  };

  handleToggle = async (e, id) => {
    let task = this.state.tasksToDisplay.find((t) => t.id === id);
    console.log(task);
    task = { ...task, finished: !task.finished };
    console.log(task);
    let tasks = this.state.tasksToDisplay.map((oldTask) =>
      oldTask.id === id ? task : oldTask
    );
    await this.props.manageTasks(tasks, this.props.id);

    await this.setState((prevState) => ({
      tasksToDisplay: tasks,
    }));
  };

  handleCancelFile = async (id, name) => {
    await this.setState((prevState) => ({
      task: {
        ...prevState.task,
        files: [...prevState.task.files.filter((file) => file.id !== id)],
      },
    }));
    await this.props.deleteFile(name, this.state.task.id);
  };

  handleCancelFileComment = async (id, name) => {
    await this.setState((prevState) => ({
      taskComment: {
        ...prevState.taskComment,
        files: [
          ...prevState.taskComment.files.filter((file) => file.id !== id),
        ],
      },
    }));
    await this.props.deleteFile(name, this.state.taskComment.id);
  };

  handleDeleteTaskFile = async (id, name, task) => {
    let newTask = {
      ...task,
      files: task.files.filter((file) => file.id !== id),
    };
    let newTasks = this.state.tasksToDisplay.map((oldTask) =>
      oldTask.id === task.id ? newTask : oldTask
    );
    await this.setState((prevState) => ({
      tasksToDisplay: newTasks,
    }));
    console.log(newTasks);
    this.toggleModal();
    await this.props.deleteFile(name, task.id);
    await this.props.manageTasks(newTasks, this.props.id);
  };

  handleDeleteTaskCommentFile = async (id, name, taskComment) => {
    let newTaskComment = {
      ...taskComment,
      files: taskComment.files.filter((file) => file.id !== id),
    };
    let newTaskComments = this.state.taskCommentsToDisplay.map(
      (oldTaskComment) =>
        oldTaskComment.id === taskComment.id ? newTaskComment : oldTaskComment
    );
    await this.setState((prevState) => ({
      taskCommentsToDisplay: newTaskComments,
    }));
    await this.props.deleteFile(name, taskComment.id);
    this.toggleModal();
    await this.props.manageTaskComments(newTaskComments, this.props.id);
  };

  handleDeleteTaskComment = async (taskComment) => {
    let newTaskComments = this.state.taskCommentsToDisplay.filter(
      (oldTaskComment) => oldTaskComment.id !== taskComment.id
    );
    await this.props.manageTaskComments(newTaskComments, this.props.id);
    for (let file of taskComment.files) {
      await this.props.deleteFile(file.name, taskComment.id);
    }
    await this.setState((prevState) => ({
      taskCommentsToDisplay: newTaskComments,
      ToDisplay: newTaskComments,
    }));
  };

  handleDeleteTask = async (task) => {
    let newTasks = this.state.tasksToDisplay.filter(
      (oldTask) => oldTask.id !== task.id
    );
    await this.props.manageTasks(newTasks, this.props.id);
    for (let file of task.files) {
      await this.props.deleteFile(file.name, task.id);
    }

    let taskComments = this.state.taskCommentsToDisplay.filter(
      (comment) => comment.taskId === task.id
    );

    for (let comment of taskComments) {
      await this.handleDeleteTaskComment(comment);
    }

    await this.setState((prevState) => ({
      tasksToDisplay: newTasks,
      ToDisplay: newTasks,
    }));
  };

  handleEdit = (task) => {
    this.setState({
      editTask: task,
    });
  };

  handleEditSubmitTask = async (e) => {
    e.preventDefault();
    let newTask = this.state.editTask;
    let newTasks = this.state.tasksToDisplay.map((oldTask) =>
      oldTask.id === newTask.id ? newTask : oldTask
    );
    await this.setState((prevState) => ({
      tasksToDisplay: newTasks,
      editTask: { id: null },
    }));
    await this.props.manageTasks(newTasks, this.props.id);
  };

  handleEditSubmitTaskComment = async (e) => {
    e.preventDefault();
    let newTask = this.state.editTask;
    let newTaskComments = this.state.taskCommentsToDisplay.map((oldTask) =>
      oldTask.id === newTask.id ? newTask : oldTask
    );
    await this.props.manageTaskComments(newTaskComments, this.props.id);
    await this.setState((prevState) => ({
      taskCommentsToDisplay: newTaskComments,
      editTask: { id: null },
    }));
  };

  archiveCurrentSession = async (e) => {
    await this.props.archiveRevision(
      this.props.id,
      this.state.tasksToDisplay,
      this.state.taskCommentsToDisplay
    );
    await this.setState((prevState) => ({
      taskCommentsToDisplay: [],
      tasksToDisplay: [],
      modalConfirmOpened: false,
    }));
  };

  render() {
    if (!this.state.project) {
      return <Loading />;
    }

    if (this.props.client && !this.state.project.revisionSession) {
      return (
        <div id="main" style={{ height: "75%" }}>
          <div className="fof">
            <h1 style={{ color: "#8C8C8C" }}>
              We are currently working on it...
            </h1>
          </div>
        </div>
      );
    }

    return (
      <div>
        <ConfirmModal
          modalOpened={this.state.modalConfirmOpened}
          toggleModal={this.toggleConfirmModal}
          archiveCurrentSession={this.archiveCurrentSession}
          header={"Ending Current Session"}
          text={"Are you sure you want to end the current revision session?"}
        />
        <ConfirmModal
          modalOpened={this.state.modalConfirmOpenedRefer}
          toggleModal={this.toggleConfirmModalRefer}
          archiveCurrentSession={() => console.log("test")}
          header={"Refer to a senior builder"}
          text={"Are you sure you want to refer this task to a senior builder?"}
        />
        <FileModals
          files={this.state.modalFiles}
          modalOpened={this.state.modalOpened}
          toggleModal={this.toggleModal}
          deleteFile={
            this.state.modalType === "task"
              ? this.handleDeleteTaskFile
              : this.handleDeleteTaskCommentFile
          }
          downloadFile={this.downloadImage}
          detectImage={this.detectImage}
          modalId={this.state.modalId}
        />
        <div className="revision-main">
          <div className="revisions-tasks-main">
            <div className="revisions-tasks-header">
              {!this.props.client ? (
                <h1>Client Revisions & questions</h1>
              ) : (
                <>
                  <h1>
                    Gather your feedback & revisions in this todo list. Changes
                    to your website will only be made during a booked in
                    revision session.
                  </h1>
                  <p
                    style={{
                      color: "#c3c3c3",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    To ensure that your website is completed in an organised and
                    efficient manner, all feedback & questions will be kept to
                    clear, pre-booked sessions.
                  </p>
                  <p
                    style={{
                      color: "#c3c3c3",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Please refer to the Your Package & Costs section of the
                    project dashboard to see the number of feedback sessions
                    included. (Additional feedback sessions can be purchased if
                    required). Please gather your feedback & questions in the
                    “To-Do List” below for your next pre-booked session. Your
                    deadline for populating this list is{" "}
                    <b>Thursday the 4th of March, 2021, at 11am</b>
                  </p>
                  <p
                    style={{
                      color: "#c3c3c3",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    During the feedback session we will go through each of your
                    points ticking them off or making comments which you can
                    reply to via this tool. As part of each feedback session, a
                    phone call may be necessary to help the process. Our Studio
                    Manager will arrange a call with you if required.
                  </p>
                  <p
                    style={{
                      color: "#c3c3c3",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <b>
                      Please note that we only accept revisions through this
                      tool and during a revision session.
                    </b>
                  </p>
                </>
              )}

              {/* {!this.props.client && (
                <section
                  style={{ color: "white!important" }}
                  className="revision-quit"
                  onClick={this.toggleConfirmModal}
                >
                  End Current Session
                </section>
              )} */}
            </div>
            <div className="revision-tasks-tasks">
              <p
                style={{
                  margin: "0px",
                  color: "#969696",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                REVISION SESSION: {this.state.projectRevisions}
              </p>
              {this.state.tasksToDisplay.map((task) => (
                <div key={task.id} className="revision-tasks-task">
                  <i
                    className="close icon delete-task"
                    onClick={() => this.handleDeleteTask(task)}
                  />
                  <div className="revision-tasks-task-border">
                    <div className="revision-tasks-task-flex">
                      {task.finished ? (
                        <>
                          <div className={"revision-tasks-task-icon"}>
                            <img
                              onClick={(e) => this.handleToggle(e, task.id)}
                              className="revision-tasks-task-ticked"
                              src="/assets/icons/ticked.png"
                              alt=""
                            />
                          </div>
                          <div className="revision-tasks-task-header">
                            <div>
                              <h4
                                style={{
                                  color: " #d2d2d2",
                                  textDecoration: "line-through",
                                }}
                                onClick={(e) => this.handleEdit(task)}
                              >
                                {task.text}
                              </h4>
                            </div>
                            <div className="revision-tasks-task-header-func"></div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="revision-tasks-task-icon">
                            <span
                              onClick={(e) => this.handleToggle(e, task.id)}
                              className="revision-tasks-task-empty"
                            />
                          </div>
                          {this.state.editTask.id !== task.id ? (
                            <div className="revision-tasks-task-header">
                              <div>
                                <h4>{task.text}</h4>
                                {task.files && task.files.length > 0 && (
                                  <p>
                                    {task.files.length} file
                                    {task.files.length > 1 && "s"} attached
                                  </p>
                                )}
                              </div>
                              <div className="revision-tasks-task-header-func">
                                {task.id !== this.state.showId &&
                                  this.state.taskCommentsToDisplay.filter(
                                    (comment) => comment.taskId === task.id
                                  ).length === 0 && (
                                    <span
                                      onClick={(e) => {
                                        this.setState({
                                          showId: task.id,
                                          showReply: task.id,
                                          commentSubmitted: true,
                                        });
                                      }}
                                    >
                                      Comments on this task
                                    </span>
                                  )}
                                {task.id === this.state.showId &&
                                  this.state.taskCommentsToDisplay.filter(
                                    (comment) => comment.taskId === task.id
                                  ).length === 0 && (
                                    <span
                                      onClick={(e) => {
                                        this.setState({
                                          showId: "",
                                          showReply: "",
                                          commentSubmitted: false,
                                        });
                                      }}
                                    >
                                      Hide Comment Section
                                    </span>
                                  )}
                              </div>
                            </div>
                          ) : (
                            <form
                              style={{ width: "100%" }}
                              action=""
                              onSubmit={this.handleEditSubmitTask}
                            >
                              <input
                                className="revision-tasks-task-edit-form"
                                autoFocus="true"
                                type="text"
                                value={this.state.editTask.text}
                                onChange={(e) => {
                                  e.persist();
                                  this.setState((prevState) => ({
                                    editTask: {
                                      ...prevState.editTask,
                                      text: e.target.value,
                                    },
                                  }));
                                }}
                              />
                            </form>
                          )}
                        </>
                      )}
                    </div>
                    {(this.state.taskCommentsToDisplay.filter(
                      (comment) => comment.taskId === task.id
                    ).length > 0 ||
                      task.id === this.state.showId) &&
                      !task.finished && (
                        <div
                          className={`revision-tasks-task-comments ${
                            this.state.taskCommentsToDisplay.filter(
                              (comment) => comment.taskId === task.id
                            ).length > 0 && "revision-tasks-task-comments-pad"
                          }`}
                        >
                          {this.state.taskCommentsToDisplay.filter(
                            (comment) => comment.taskId === task.id
                          ).length > 0 &&
                            this.state.taskCommentsToDisplay
                              .filter((comment) => comment.taskId === task.id)
                              .map((taskComment) => (
                                <div
                                  key={taskComment.id}
                                  className="revision-tasks-task-comments-comment"
                                >
                                  <h4>{taskComment.author}</h4>
                                  {this.state.editTask.id !== taskComment.id ? (
                                    <div>
                                      <p>{taskComment.text}</p>
                                      {taskComment.files.length > 0 && (
                                        <p
                                          className="file-attached-comment"
                                          onClick={(e) =>
                                            this.toggleModal(
                                              e,
                                              taskComment.files,
                                              taskComment,
                                              "comment"
                                            )
                                          }
                                        >
                                          {taskComment.files.length} file
                                          {taskComment.files.length > 1 &&
                                            "s"}{" "}
                                          attached
                                        </p>
                                      )}
                                      <div>
                                        <span
                                          style={{
                                            padding: "0 10px 0 0",
                                            float: "left",
                                            fontWeight: "500",
                                          }}
                                          onClick={(e) =>
                                            this.handleEdit(taskComment)
                                          }
                                        >
                                          Edit
                                        </span>
                                        <span
                                          style={{
                                            padding: "0 10px 0 0",
                                            float: "left",
                                            fontWeight: "500",
                                          }}
                                          onClick={(e) =>
                                            this.handleDeleteTaskComment(
                                              taskComment
                                            )
                                          }
                                        >
                                          Remove
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <form
                                      style={{ width: "100%" }}
                                      action=""
                                      onSubmit={
                                        this.handleEditSubmitTaskComment
                                      }
                                    >
                                      <TextareaAutosize
                                        style={{ resize: "none" }}
                                        className="revision-tasks-comment-edit-form"
                                        autoFocus="true"
                                        type="text"
                                        value={this.state.editTask.text}
                                        onChange={(e) => {
                                          e.persist();
                                          this.setState((prevState) => ({
                                            editTask: {
                                              ...prevState.editTask,
                                              text: e.target.value,
                                            },
                                          }));
                                        }}
                                      />
                                      <button
                                        className="button ui tiny"
                                        type="submit"
                                      >
                                        Update
                                      </button>
                                    </form>
                                  )}
                                </div>
                              ))}

                          {task.id === this.state.showId &&
                            this.state.showReply === task.id && (
                              <div className="revision-tasks-task-comments-add">
                                <form
                                  action=""
                                  onSubmit={(e) =>
                                    this.handleAddTaskComment(e, task.id)
                                  }
                                >
                                  <TextareaAutosize
                                    onChange={(e) =>
                                      this.handleChangeAddComment(e, task.id)
                                    }
                                    placeholder="Write a reply"
                                    autoFocus="true"
                                    type="text"
                                    required={true}
                                    className="revision-tasks-task-comments-add-input"
                                    value={
                                      this.state.taskComment.taskId === task.id
                                        ? this.state.taskComment.value
                                        : ""
                                    }
                                    autoComplete="off"
                                  />
                                  <span
                                    onClick={(e) =>
                                      this.setState({
                                        showReply: "",
                                        showId: "",
                                      })
                                    }
                                    style={{ fontSize: "12px" }}
                                  >
                                    Cancel
                                  </span>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <button
                                      className="ui tiny button"
                                      type={"submit"}
                                    >
                                      Submit
                                    </button>
                                    <label htmlFor="filee">
                                      <img
                                        style={{
                                          height: "28px",
                                          position: "relative",
                                          top: "2px",
                                        }}
                                        src="/assets/icons/file.png"
                                        alt=""
                                      />
                                    </label>
                                    <input
                                      id="filee"
                                      onChange={(e) =>
                                        this.handleFileAddComment(e, task.id)
                                      }
                                      name="file"
                                      type="file"
                                    />
                                  </div>
                                </form>

                                {this.state.taskComment.files.length > 0 &&
                                  this.state.taskComment.taskId === task.id && (
                                    <div>
                                      <div className="revision-tasks-task-comments-add-files">
                                        <div
                                          className="revision-tasks-form-files"
                                          style={{
                                            padding: "10px 10px 10px 0px",
                                          }}
                                        >
                                          {this.state.taskComment.files.map(
                                            (file) => (
                                              <div
                                                key={file.id}
                                                className="branding-border"
                                              >
                                                {this.detectImage(file.name) ? (
                                                  <img
                                                    className="branding-border-image"
                                                    src={file.url}
                                                    alt={file.name}
                                                  />
                                                ) : (
                                                  <img
                                                    className="branding-border-image"
                                                    src="/assets/form/Document-placeholder.png"
                                                    alt={file.name}
                                                  />
                                                )}
                                                <div className="branding-border-image-control">
                                                  <span
                                                    onClick={(e) =>
                                                      this.handleCancelFileComment(
                                                        file.id,
                                                        file.name
                                                      )
                                                    }
                                                  >
                                                    REMOVE
                                                  </span>
                                                  <span
                                                    onClick={(e) =>
                                                      this.downloadImage(
                                                        file.url,
                                                        file.name
                                                      )
                                                    }
                                                  >
                                                    DOWNLOAD
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            )}
                          {this.state.showReply !== task.id && (
                            <div
                              className="reply-comment"
                              onClick={(e) =>
                                this.setState({
                                  showReply: task.id,
                                  showId: task.id,
                                })
                              }
                            >
                              Reply
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                  {!this.props.client && (
                    <span
                      className="refer-task"
                      onClick={this.toggleConfirmModalRefer}
                    >
                      Refer task to a senior builder
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div className="revision-tasks-form">
              <form action="" onSubmit={this.handleAddTask}>
                <textarea
                  onChange={this.handleChangeAddTask}
                  id="text"
                  type="text"
                  required={true}
                  value={this.state.task.text}
                  placeholder="Ask a question or request a change to your website. Add one item at a time..."
                  autoFocus={true}
                  autoComplete="off"
                />
                {this.state.task.files.length > 0 && (
                  <div>
                    <hr className="revision-tasks-form-files-hr" />
                    <div className="revision-tasks-form-files">
                      {this.state.task.files.map((file) => (
                        <div key={file.id} className="branding-border">
                          {this.detectImage(file.name) ? (
                            <img
                              className="branding-border-image"
                              src={file.url}
                              alt={file.name}
                            />
                          ) : (
                            <img
                              className="branding-border-image"
                              src="/assets/form/Document-placeholder.png"
                              alt={file.name}
                            />
                          )}
                          <div className="branding-border-image-control">
                            <span
                              onClick={(e) =>
                                this.handleCancelFile(file.id, file.name)
                              }
                            >
                              REMOVE
                            </span>
                            <span
                              onClick={(e) =>
                                this.downloadImage(file.url, file.name)
                              }
                            >
                              DOWNLOAD
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="revision-tasks-form-form">
                  <div className="revision-tasks-form-control">
                    <div className={"revision-tasks-form-control-func"}>
                      <img
                        height={"32px"}
                        src={"/assets/icons/add.png"}
                        alt="."
                        onClick={this.handleAddTask}
                      />
                      <button type="submit">ADD ITEM</button>
                    </div>
                    <div className="revision-tasks-form-control-func">
                      {/*<img src={"/assets/icons/pin.png"} height={"37px"} alt="."/>*/}
                      <label htmlFor="file">
                        <span style={{ fontSize: "12px" }}>ATTACH FILE</span>
                      </label>
                      <input
                        onChange={this.handleFileAddTask}
                        id="file"
                        name="file"
                        type="file"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <PreviousRevisions />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirestore,
  firestoreConnect([{ collection: "projects" }]),
  connect(mapStateToProps, actions)
)(Revision);
