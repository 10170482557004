import React, {Component} from 'react';
import "./admin-settings.css";
import {Link} from "react-router-dom";

class AdminSettingsTable extends Component {

    render() {
        const {filter, users, deleteUser} = this.props;
        const userList = users.filter(user => user.role === filter)
            .map(user => (
                <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                        <Link to={`/dashboard/users/edit/${user.id}`} className={"admin-settings-table-edit"}>Edit</Link>
                        <span onClick={() => deleteUser(user.id, user.role)} className={"admin-settings-table-del"}>Delete</span>
                    </td>
                </tr>
            ));
        return (
            <div>
                <table className={"admin-settings-table"}>
                    <tbody>
                        {userList}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AdminSettingsTable;