import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import firebase from "../../../firebase";
import cuid from "cuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./project-overview.css";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import { createPage, deletePage } from "../../../actions/pages-actions";
import {
  deleteComponents,
  updatePageList,
} from "../../../actions/wireframes-actions";
import Loading from "../../loading/loading";
import { object } from "firebase-functions/lib/providers/storage";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const projects = state.firestore.ordered.projects;

  const pages =
    projects && projects.filter((project) => project.id === id)[0].pages;
  return {
    pages: pages || [],
    id,
  };
};

const actions = {
  createPage,
  deletePage,
  deleteComponents,
};

class PageSketches extends Component {
  state = {
    pageTitle: "",
    showPage: null,
    pageList: [],
  };

  handleChange = async (event) => {
    await this.setState({ pageTitle: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const pageName = this.state.pageTitle;

    if (pageName.length === 0) {
      return;
    }

    await this.props.createPage(pageName, this.props.id);
    await this.setState({ pageTitle: "" });
  };

  handleDeletePage = async (e) => {
    await this.props.deletePage(
      this.state.showPage,
      this.props.match.params.id
    );
    await this.setState({ showPage: null });
  };

  handleDeleteComponents = async (e) => {
    await this.props.deleteComponents(
      this.props.match.params.id,
      this.state.showPage.id
    );
    await this.setState((prevState) => ({
      showPage: { ...prevState.showPage, components: [] },
    }));
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);
  }

  async componentWillUnmount() {
    const { firestore } = this.props;
    await firestore.unsetListener(`projects`);
  }

  onDragEnd = async (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const pageList = this.props.pages;

    const draggedPage = pageList.filter((page) => page.id === draggableId)[0];

    const newPageIds = Array.from(pageList);
    // console.log(newPageIds);
    newPageIds.splice(source.index, 1);
    newPageIds.splice(destination.index, 0, draggedPage);

    console.log(newPageIds);
    this.setState({ pageList: newPageIds });
    await updatePageList(newPageIds, this.props.id);
  };

  render() {
    const { pages, deletePage, clearPage } = this.props;
    const { pageTitle, showPage } = this.state;
    {
    }
    return (
      <div className="page-sketches-main">
        <div className="page-sketches-left">
          {/*{!this.state.showPage ?*/}
          <div className="page-sketches-left-default-text">
            <h2>Pages so far...</h2>
            <p>
              Always start with a premade page layout to save time
              <br />
              and give yourself a strong foundation to build on.
            </p>
          </div>
        </div>
        <div className="page-sketches-right">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={"2"}>
              {(provided) => (
                <div
                  className="page-sketches-right-main-list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {provided.placeholder}
                  {pages &&
                    pages.map((page, index) => (
                      <Draggable
                        draggableId={page.id}
                        index={index}
                        key={page.id}
                      >
                        {(provided) => (
                          <span
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            key={index}
                          >
                            <Link
                              style={{ textDecoration: "none", color: "white" }}
                              key={page.id}
                              to={`/dashboard/project-overview/${this.props.match.params.id}/page-sketches-editor/${page.id}`}
                            >
                              {page.pageName}
                            </Link>
                          </span>
                        )}
                      </Draggable>
                    ))}

                  <form action="" onSubmit={this.handleSubmit}>
                    <input
                      value={pageTitle}
                      autoFocus
                      type="text"
                      onChange={this.handleChange}
                      placeholder="Add Page..."
                    />
                  </form>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirestore,
  firestoreConnect([{ collection: "projects" }]),
  connect(mapStateToProps, actions)
)(PageSketches);
