import React, {Component} from 'react';

import "./prcicing-form.css";

import FormInput from "../../form-components/form-input/form-input";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import FormHeader from "../../layout-components/form-header/form-header";
import FormSelect from "../../form-components/form-select/form-select";
import {createPricing, updatePricing} from "../../../actions/pricing-actions";
import {combineValidators, isRequired} from "revalidate";
import {Field,reduxForm} from "redux-form";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import Loading from "../../loading/loading";
import {withRouter} from "react-router-dom";
import {Menu} from "semantic-ui-react";
import firebase from "../../../firebase";
import PricingFormModal from "./pricing-form-modal";
import FormTextarea from "../../form-components/form-textarea/form-textarea";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import Wysiwyg from "../../form-components/wysiwyg/wysiwyg";

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match && ownProps.match.params.id;
    let pricing = {};
    let header = "New";
    let button =  "Create";
    let type = "";

    if(id) {
        pricing = state.firestore.ordered.pricing && state.firestore.ordered.pricing.filter(pricing => pricing.id === id)[0];
        header = "Edit";
        button = "Update";
        type= state.firestore.ordered.pricing && state.firestore.ordered.pricing.filter(pricing => pricing.id === id)[0].type;
    }



    return {
        id: id,
        initialValues: pricing,
        header: header,
        button: button,
        type: type,
    }
};

const actions = {
    createPricing,
    updatePricing,
};



const validate = combineValidators({
    title: isRequired("Title"),
    price: isRequired("Price"),
});


class PricingForm extends Component {

    state = {
        active: "package",
        included: [],
        modal: false,
        editInc: null

    };

    async componentDidMount () {
        if(this.props.match.params.id) {
            const {firestore} = this.props;
            await firestore.setListener(`pricing`);
            const db = firebase.firestore();

            const projectQuery = await db.collection("pricing").doc(this.props.match.params.id);
            const projectSnap = await projectQuery.get();
            const pricing = projectSnap.data();
            if(pricing.type === "service") {
                return;
            } else {
                await this.setState({
                    included: pricing.included
                });
            }
        }
    }

    onFormSubmit = async values => {
        if(this.props.initialValues.id) {
            let updatedPricing = {
                ...values,
                type: this.props.type
            };

            if(this.props.type === "package") {
                updatedPricing = {
                    ...values,
                    type: this.props.type,
                    included: this.state.included,
                }
            }

           await this.props.updatePricing(updatedPricing);
        } else  {
            let newPricing = {
                ...values,
                type: this.state.active,
            };

            if(this.state.active === "package") {
                newPricing = {
                    ...values,
                    type: this.state.active,
                    included: this.state.included,
                }
            }
           await this.props.createPricing(newPricing);
        }

        this.props.history.push(`/dashboard/admin-settings/pricing-packages`);
    };

    handleAddIncluded = inc => {
        this.setState(prevState => ({included: [...prevState.included, inc], editInc: null}));

    };

    handleDeleteIncluded = inc => {
        const newInc = this.state.included.filter(oldInc => oldInc.id !== inc.id);
        this.setState(prevState => ({included: newInc, editInc: null}));
    };

    handleEditModal = async inc => {
        await this.setState({
            editInc: inc
        });
        await this.setState({
           modal: true
        });


    };

    handleUpdateIncluded = inc => {
        const newInc = this.state.included.map(oldInc => oldInc.id === inc.id ? inc : oldInc);
        this.setState(prevState => ({included: newInc, editInc: null}));
    };

    handleModalClose = () => {this.setState({modal: false, editInc: null})}

    render() {

        const {invalid, submitting, pristine, button, header,handleSubmit,initialValues,id, type} = this.props;

        if(id && !initialValues) {
            return <Loading/>
        }


        return (
            <div className="ui container form-container">
                <PricingFormModal handleSubmit={this.state.editInc ? this.handleUpdateIncluded : this.handleAddIncluded} inc={this.state.editInc ? this.state.editInc : null} open={this.state.modal} handleClose={this.handleModalClose} />
                <FormHeader url="/dashboard/admin-settings/pricing-packages" title={`${header} Pricing`}/>
                {!id &&
                <Menu fluid widths={2}>
                    <Menu.Item
                        name='pricing'
                        active={this.state.active === "package"}
                        onClick={(e) => this.setState({active: "package"})}
                    >
                        Package
                    </Menu.Item>

                    <Menu.Item
                        name='addon'
                        active={this.state.active === "service"}
                        onClick={(e) => this.setState({active: "service"})}
                    >
                        Addon
                    </Menu.Item>
                </Menu>
                }
                {!id && this.state.active==="service" &&
                <form action="" className="ui form" autoComplete="off" onSubmit={handleSubmit(this.onFormSubmit)}>
                    <Field
                        name="title"
                        label="Addon Title"
                        component={FormInput}
                        placeholder="Basic Website"
                    />
                    <Field
                        name="price"
                        label="Price"
                        component={FormInput}
                        placeholder="£999"
                    />
                    <Field
                        name="per"
                        label="Per (optional)"
                        component={FormInput}
                        placeholder="Image"
                    />
                    <Field
                        name="description"
                        label="Description"
                        component={FormTextarea}
                        placeholder="Write or paste text in here"
                    />
                    <FormSubmitButton

                        text={`${button} Pricing`}
                    />
                </form>
                }
                {!id && this.state.active==="package" &&
                <form action="" className="ui form" autoComplete="off" onSubmit={handleSubmit(this.onFormSubmit)}>
                    <Field
                        name="title"
                        label="Package Title"
                        component={FormInput}
                        placeholder="Basic Website"
                    />
                    <Field
                        name="price"
                        label="Price"
                        component={FormInput}
                        placeholder="£999"
                    />
                    <div className={"pricing-form-addons"}>
                        <div className="prcing-form-addons-header">What's included</div>
                        <div className="prcing-form-addons-addons">
                            {this.state.included.map(include => (
                                <div key={include.id} className="prcing-form-addons-addons-item">- {include.title}
                                    <span className="prcing-form-addons-addons-edit" onClick={e => this.handleEditModal(include)} >EDIT</span>
                                    <span className="prcing-form-addons-addons-remove" onClick={ e => this.handleDeleteIncluded(include)}>REMOVE</span></div>
                            ))}
                        </div>
                        <div className="prcing-form-addons-addons-add" onClick={e =>this.setState({modal: true})}>+ADD</div>
                    </div>
                    <FormSubmitButton

                        text={`${button} Pricing`}
                    />
                </form>
                }
                {id && type ==="service" &&
                <form action="" className="ui form" autoComplete="off" onSubmit={handleSubmit(this.onFormSubmit)}>
                    <Field
                        name="title"
                        label="Addon Title"
                        component={FormInput}
                        placeholder="Basic Website"
                    />
                    <Field
                        name="price"
                        label="Price"
                        component={FormInput}
                        placeholder="£999"
                    />
                    <Field
                        name="per"
                        label="Per (optional)"
                        component={FormInput}
                        placeholder="Image"
                    />
                    <Field
                        name="description"
                        label="Description"
                        component={FormTextarea}
                        placeholder="Write or paste text in here"
                    />
                    <FormSubmitButton

                        text={`${button} Pricing`}
                    />
                </form>
                }
                {id && type ==="package" &&
                <form action="" className="ui form" autoComplete="off" onSubmit={handleSubmit(this.onFormSubmit)}>
                    <Field
                        name="title"
                        label="Package Title"
                        component={FormInput}
                        placeholder="Basic Website"
                    />
                    <Field
                        name="price"
                        label="Price"
                        component={FormInput}
                        placeholder="£999"
                    />
                    <div className={"pricing-form-addons"}>
                        <div className="prcing-form-addons-header">What's included</div>
                        <div className="prcing-form-addons-addons">
                            {this.state.included.map(include => (
                                <div key={include.id} className="prcing-form-addons-addons-item">- {include.title}
                                <span className="prcing-form-addons-addons-edit" onClick={e => this.handleEditModal(include)} >EDIT</span>
                                    <span className="prcing-form-addons-addons-remove" onClick={ e => this.handleDeleteIncluded(include)}>REMOVE</span></div>
                            ))}
                        </div>
                        <div className="prcing-form-addons-addons-add" onClick={e =>this.setState({modal: true})}>+ADD</div>
                    </div>
                    <FormSubmitButton

                        text={`${button} Pricing`}
                    />
                </form>
                }


            </div>
        );
    }
}

export default compose(
    withRouter,
    firestoreConnect([{collection: 'pricing'}]),
    connect(mapStateToProps,actions),
    reduxForm({form: 'pricingForm', validate}),
)(PricingForm)
