import React from 'react'
import "./form-select.css"
import {Select, Form, Label} from "semantic-ui-react";

const FormSelect = ({input, label,placeholder,multiple, search, options, meta: {touched, error}}) => (
    <Form.Field error={touched && !!error}>
        <div className={"form-select-label"}>{label}</div>
        <Select
            {...input}
            placeholder={placeholder}
            options={options}
            value={input.value}
            onChange={(e, data) => input.onChange(data.value) }
            multiple={multiple}
            search={search}
        />
        {touched && error && <Label basic color='red'>{error}</Label>}
    </Form.Field>
);

export default FormSelect;

