import {applyMiddleware, createStore} from "redux";
import {reactReduxFirebase, getFirebase} from "react-redux-firebase";
import {reduxFirestore, getFirestore} from "redux-firestore";
import rootReducer from "../reducers/root-reducer";
import {composeWithDevTools, devToolsEnhancer} from "redux-devtools-extension";
import thunk from "redux-thunk";
import firebase from "../firebase";

const rrfConfig = {
    userProfile: 'users',
    attachAuthIsReady: true,
    useFirestoreForProfile: true,
    updateProfileOnLogin: false
};

export const configureStore = () => {

    const middleware =[thunk.withExtraArgument({getFirebase, getFirestore})];

    const composedEnhancer = composeWithDevTools(
        applyMiddleware(...middleware),
        reactReduxFirebase(firebase, rrfConfig),
        reduxFirestore(firebase, rrfConfig)
    );

    return createStore(rootReducer, composedEnhancer);
};