import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

// Components Imports
import LoginForm from "../../layout-components/login-form/login-form";
import { withRouter } from "react-router-dom";
import { withFirebase } from "react-redux-firebase";
// Styles
import "./login.css";
import firebase from "../../../firebase";

const Login = ({ authenticated, history, role, email }) => {
  const [changeImg, setChangeImg] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setChangeImg(true);
    }
  }, []);

  const clientRedirect = async (email) => {
    const db = firebase.firestore();
    const clientQuery = await db
      .collection("users")
      .where("email", "==", email);
    const clientSnap = await clientQuery.get();
    const client = clientSnap.docs[0].data();
    await history.push(
      `/dashboard/project-overview-client/${client.projects[0] || "no-project"}`
    );
  };

  if (authenticated) {
    if (role === "admin" || role === "employee") {
      history.push("/dashboard/recent");
    } else {
      clientRedirect(email);
    }
  }

  return (
    <div
      className="login"
      style={{ flexDirection: isMobile ? "column" : "row" }}
    >
      <div
        className="login-image-container"
        style={{
          width: isMobile ? "100%" : "50%",
          backgroundColor: isMobile ? "#f7a600" : "",
          justifyContent: isMobile ? "center" : "",
        }}
      >
        <img
          style={{
            width: isMobile ? "40%" : "100%",
          }}
          src={`/assets/logo/background${changeImg ? "-mob.png" : ".jpg"}`}
          alt="background"
          className="login-image"
        />
      </div>
      <div
        className="login-right-flex"
        style={{
          width: isMobile ? "100%" : "50%",
          marginTop: isMobile ? "100px" : "0px",
        }}
      >
        <LoginForm />
      </div>
    </div>
  );
};

export default withRouter(Login);
