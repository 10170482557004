import React, {Component} from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


import "./form-wysiwyg.css";

const editorConfiguration = {
    toolbar: [ 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
};

const FormWysiwyg = ({label,input, setValue,name,value}) => (
    <div className="field">
        <label>{label}</label>
        <CKEditor
            config={editorConfiguration}
            data={value}
            editor={ ClassicEditor }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setValue(name, data);
            } }
        />
    </div>
);

export default FormWysiwyg;