export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const CREATE_PRICING = 'CREATE_PRICING';
export const UPDATE_PRICING = 'UPDATE_PRICING';
export const DELETE_PRICING = 'DELETE_PRICING';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

