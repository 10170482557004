import React, {Component} from 'react';

import "./project-jourrney.css";
import {compose} from "redux";
import {withRouter} from "react-router";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";
import {manageJourney} from "../../../actions/journey-actions";
import {Link} from "react-router-dom";
import JourneyForm from "./journey-form";
import ClientJourney from "./client-journey";
import BuilderJourney from "./builder-journey";
import cuid from "cuid";
import JFormE from "./j-form-edit";
import firebase from "../../../firebase";

const actions = {
    manageJourney
};

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;

    return {
        id,
    }
};

class ProjectJourney extends Component {

    state = {
       clientComponents: [],
       devComponents: [],
        title: "",
        editC: null,
        edit: false,
    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`journey`);
        const db = firebase.firestore();
        const journeyQuery = await db.collection("journey").doc(this.props.id);
        const journeySnap = await journeyQuery.get();
        const journey = journeySnap.data();
        this.setState({
            clientComponents: journey.clientComponents || [],
            devComponents: journey.devComponents || [],
            title: journey.title,
        })
    }

    stepCreate = async (obj) => {
        let steps = [...this.state.stepsToDisplay, obj];
        await this.props.manageJourney(steps, this.props.projectId);
        await this.setState({
            stepsToDisplay: steps,
            stepOptions: steps.map(step => ({key: step.id, text: step.heading, value: step.id}))
        })
    };

    createComponent = async (component, type, id) => {
        const cid = cuid();
        const newComponents = [...this.state[type], {...component, id: cid}];
        console.log(newComponents);

        await this.setState({
            [type]: newComponents
        });

        console.log(type);
        await this.props.manageJourney(newComponents, type, id)
    };

    deleteComponent = async (component, type, id) => {
        const newComponents = this.state[type].filter(c => c.id !== component.id);

        await this.setState({
            [type]: newComponents,
            edit: false,
            editC: null
        });

        await this.props.manageJourney(newComponents, type, id)
    };

    editComponent = async (component, type, id) => {
        const newComponents = this.state[type].map(c => c.id === component.id ? component : c);

        await this.setState({
            [type]: newComponents,
            edit: false,
            editC: null
        });

        await this.props.manageJourney(newComponents, type, id)
    };

    handleDrop= async (result) => {
        const {destination, source, draggableId} = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;

        }

        const draggedComp = [...this.state.clientComponents, ...this.state.devComponents].find(c => c.id === draggableId);

        let oldComponents = draggedComp.for === "client" ? [...this.state.clientComponents] : [...this.state.devComponents];


        oldComponents.splice(source.index, 1);
        oldComponents.splice(destination.index, 0, draggedComp);


        await this.setState({[draggedComp.for + "Components"]: oldComponents});
        await this.props.manageJourney(oldComponents, [draggedComp.for + "Components"], this.props.id);
    };

    handleEdit = async (c) => {
        await this.setState({
            edit: false,
            editC: null
        });
        await this.setState({
            edit: true,
            editC: c
        })
    };

    toggleEdit = () => {
        this.setState({
            edit: false,
            editC: null
        })
    };



    render() {


        return (
            <>
                <div className="project-journey-header">
                    <Link to={"/dashboard/admin-settings/project-journeys"}>
                        <img src={"/assets/icons/arrow.svg"} alt="s"/>
                        <span className={""}>Return to settings</span>

                    </Link>
                </div>
                <div className="project-journey-main">
                    {this.state.edit ?
                        <JFormE
                            editComponent={this.editComponent}
                            components={[...this.state.devComponents,...this.state.clientComponents]}
                            id={this.props.id}
                            c={this.state.editC}
                            edit={this.state.edit}
                            toggleEdit={this.toggleEdit}
                        />
                        :
                        <JourneyForm
                            createComponent={this.createComponent}
                            components={[...this.state.devComponents,...this.state.clientComponents]}
                            id={this.props.id}
                            c={null}
                            edit={this.state.edit}
                            toggleEdit={this.toggleEdit}
                        />
                    }


                    <div className="project-journey-main-space">
                        <h1>{this.state.title}</h1>
                        <div className="project-journey-main-space-flex">
                            <ClientJourney  id={this.props.id} handleEdit={this.handleEdit} handleDelete={this.deleteComponent} components={this.state.clientComponents} handleDrop={this.handleDrop}/>
                            <BuilderJourney  id={this.props.id} handleEdit={this.handleEdit} handleDelete={this.deleteComponent}  components={this.state.devComponents} handleDrop={this.handleDrop}/>
                        </div>

                    </div>

                </div>
            </>

        );
    }
}

export default compose(
    withRouter,
    withFirestore,
    firestoreConnect([{collection: "projects"}]),
    connect(mapStateToProps, actions)

)(ProjectJourney);