import {CREATE_PRICING, DELETE_PRICING, UPDATE_PRICING} from "../reduxConstants";
import {toastr} from "react-redux-toastr";
import firebase from "../firebase";
import cuid from "cuid";

export const createPricing = (pricing) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const id=cuid();

        try {
            await firestore.set(`pricing/${id}`,  {...pricing, id});
            toastr.success('Success', "Pricing has been created");
        } catch (e) {
            toastr.error('Oops', 'Something went wrong');
            console.log(e);
        }

    }

};

export const updatePricing = (pricing) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();

        try {
            await firestore.update(`pricing/${pricing.id}`, pricing);
            toastr.success('Success', "Pricing has been updated");
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const deletePricing = (id, type) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const db = firebase.firestore();
        const firestore = getFirestore();
        if(type === "service") {
            const projectsQuery = await db.collection('projects').where("services", "array-contains", id);
            const projectsSnap = await projectsQuery.get();
            for(let i = 0; i < projectsSnap.docs.length; i++) {
                const projectId = projectsSnap.docs[i].data().id;
                await db.collection('projects').doc(projectId).update({
                    services: firebase.firestore.FieldValue.arrayRemove(id)
                })
            }
        } else {
            const projectsQuery = await db.collection('projects').where("package", "==", id);
            const projectsSnap = await projectsQuery.get();
            for(let i = 0; i < projectsSnap.docs.length; i++) {
                const projectId = projectsSnap.docs[i].data().id;
                await db.collection('projects').doc(projectId).update({
                    package: firebase.firestore.FieldValue.delete()
                })
            }
        }


        try {
            await firestore.delete(`pricing/${id}`);
            toastr.success('Success', "Pricing has been deleted");
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};