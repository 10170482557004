import React from "react";
import { Link } from "react-router-dom";
import { capitalize } from "../../../utils/helpers";
import cuid from "cuid";

// Custom Styles
import "./build-main-menu.css";

const handleOpenNewWindow = (projectId, pageId) => {
  const location = window.location;
  window.open(
    `${location.origin}/client/dashboard/preview/${projectId}/wireframes/build/page/${pageId}`
  );
};

const BuildMainMenu = ({
  handleModalOpen,
  project,
  projectId,
  pageId,
  categories,
  categoryClick,
  addWhiteSpace,
}) => {
  return (
    <div className="page-sketches-right-main-list">
      <div className="wireframes-build-menu-nav">
        <div className={"page-sketches-editor-navlink-back"}>
          <Link
            to={`/dashboard/project-overview/${projectId}/page-sketches`}
            style={{ color: "white" }}
          >
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ height: "24px" }}
              alt=""
            />{" "}
            Back
          </Link>
        </div>
      </div>
      {categories &&
        categories.map((category) => (
          <span onClick={() => categoryClick(category)} key={cuid()}>
            {capitalize(category)}
          </span>
        ))}
      <span onClick={() => categoryClick("add-detail")}>Insert Comment</span>
      <span onClick={addWhiteSpace}>Spacer bock</span>
      <span onClick={() => categoryClick("upload-custom")}>
        Upload Custom Component
      </span>
      <span onClick={() => handleModalOpen()}>Premade Page</span>
      <span onClick={() => handleOpenNewWindow(projectId, pageId)}>
        Client View
      </span>
    </div>
  );
};

export default BuildMainMenu;
