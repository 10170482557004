import React, {Component, useCallback} from 'react';

import "./upload-custom.css";
import {useDropzone} from "react-dropzone";


const CustomDropzone = ({uploadImage,text,type}) => {
    const onDrop = useCallback(acceptedFiles => {
        uploadImage(acceptedFiles.map(file => Object.assign(file,{
            preview: URL.createObjectURL(file)
        })),type);
    }, [uploadImage]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        multiple: false,
        accept: 'image/*'
    });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={"wireframes-build-menu-upload-custom-dnd"}>
                <p>{text}</p>
            </div>

        </div>
    )
};


class UploadCustom extends Component {

    state = {
        img: null,
        description: ''
    };


    onImageUpload = (files) => {
        this.setState({"img": files});
    };

    uploadCustomComponent = () => {
        this.props.uploadCustomComponent(this.state.img[0]);
        this.setState({img: null, description: ''})
    };

    render() {
        const {handleBack} = this.props;
        return (
            <div className="page-sketches-right-main-list">
                <div className="wireframes-build-menu-nav">
                    <div className={"page-sketches-editor-navlink-back"} onClick={handleBack}>
                        <img src={"/assets/icons/arrow.svg"} style={{height: "24px"}} alt=""/> Save
                    </div>
                </div>
                <div className="wireframes-build-menu-upload-custom">
                    {this.state.img == null ?
                        <>
                            <p className="page-sketches-upload-header">
                                Upload a custom component
                            </p>
                            <p className="wireframes-build-menu-upload-custom-text">
                                For best results upload your file as a PNG with a width of 1200px. You can download a template here
                            </p>
                        </>
                        :
                        <div className={"wireframes-build-menu-upload-custom-buttons"}>
                            <button className="ui inverted black button" onClick={this.uploadCustomComponent}>Submit</button>
                            <button className="ui inverted black button" onClick={() => this.setState({img: null})}>Cancel</button>
                        </div>
                    }
                    {this.state.img == null ?
                        <CustomDropzone uploadImage={this.onImageUpload} text={"Click or Drop"} type={"img"}/>
                        :
                        <div className={"wireframes-build-menu-upload-custom-file"}>{this.state.img[0].path}</div>
                    }

                </div>
            </div>
        );
    }
}

export default UploadCustom;