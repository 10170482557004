import React, {Fragment} from 'react';

import "./dashboard-project-card-menu.css";

import { Dropdown, Confirm } from 'semantic-ui-react';
import {Link} from "react-router-dom";


class DashboardProjectCardMenu extends React.Component {
    state = { open: false };

    show = () =>{
        this.setState({ open: true });
    };
    handleConfirm = () => {
        this.props.deleteProject(this.props.id);
        this.setState({ open: false });
    };
    handleCancel = () => this.setState({  open: false });

    handleArchive = () => {
        this.props.toggleArchiveProject(this.props.id, this.props.archiveType);
        this.setState({ open: false });
    };

    handleTemplate = () => {
        const type = this.props.filter === "templates" ? "template" : "newEnquiry";
        this.props.createTemplate(this.props.project, type);
        this.setState({open: false})
    };

    render() {
        return (
            <Dropdown text='' icon='ellipsis horizontal' className='dashboard-project-card-menu-icon'>
                <Dropdown.Menu>
                    {/*<Dropdown.Item icon='edit' text='Edit' as={Link} to={`projects/edit/${this.props.id}`} style={{color: "black"}} />*/}
                    {this.props.filter !== "templates" &&
                        <Fragment>
                            <Dropdown.Item icon='archive' text={this.props.archiveText} onClick={this.handleArchive} />
                            <Dropdown.Item icon='copy' text='Create template' onClick={this.handleTemplate}/>
                        </Fragment>
                    }
                    {this.props.filter === "templates" &&
                    <Dropdown.Item icon='copy' text='Use template' onClick={this.handleTemplate}/>
                    }
                    <Dropdown.Item icon='delete' text='Delete Project' onClick={() => this.show()} />
                    <Confirm
                        open={this.state.open}
                        onCancel={this.handleCancel}
                        onConfirm={this.handleConfirm}
                    />
                </Dropdown.Menu>
            </Dropdown>
        )
    }

}




export default DashboardProjectCardMenu;