import React from "react";
import "./login-form.css";
import { login } from "../../../actions/auth-actions";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import FormInput from "../../form-components/form-input/form-input";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { withFirebase } from "react-redux-firebase";
import { Form } from "semantic-ui-react";
import firebase from "../../../firebase";
import { isMobile } from "react-device-detect";

const actions = {
  login,
};

class LoginForm extends React.Component {
  state = {
    restoreFormOpen: false,
    restoreMessageOpen: false,
    positive: true,
    restoreMessage: "",
    restoreEmail: "",
  };

  handleLogin = async (creds) => {
    const role = await this.props.login(creds);
    if (role === "admin" || role === "employee") {
      await this.props.history.push("/dashboard/recent");
    } else if (role === "client") {
      const db = firebase.firestore();
      const clientQuery = await db
        .collection("users")
        .where("email", "==", creds.email);
      const clientSnap = await clientQuery.get();
      const client = clientSnap.docs[0].data();
      await this.props.history.push(
        `/dashboard/project-overview-client/${
          client.projects[0] || "no-project"
        }`
      );
    }
  };

  handleRestore = (e) => {
    e.preventDefault();
    const email = this.state.restoreEmail;
    this.props.firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((r) => {
        this.setState({
          restoreMessage: `Email has been sent to`,
          restoreMessageOpen: true,
          restoreFormOpen: false,
          positive: true,
        });
      })
      .catch((e) => {
        this.setState({
          restoreMessage: "We have not found email address",
          restoreMessageOpen: true,
          restoreFormOpen: false,
          positive: false,
        });
      });
  };

  render() {
    const { handleSubmit, error } = this.props;

    return (
      <div className="login-form" style={{ width: isMobile ? "50%" : "100%" }}>
        <form onSubmit={handleSubmit(this.handleLogin)}>
          {/* <img
            src="/assets/logo/login.png"
            className="login-form-image"
            alt=""
          /> */}
          <h1 className="login-heading">
            <span>Sign In</span>
          </h1>
          <h3 className="login-description">
            The David & George client management platform is where you upload
            your content, submit your feedback and track what's happending with
            your website build. You can also access your project proposal and
            page wireframes
          </h3>
          <Field
            name="email"
            component={FormInput}
            type="text"
            placeholder="Email Address"
          />
          <Field
            name="password"
            component={FormInput}
            type="password"
            placeholder="Password"
          />
          <button type="submit" className="login-form-button">
            Sign In
          </button>
        </form>
        <div
          className="login-form-restore"
          onClick={() => {
            this.setState({ restoreFormOpen: true, restoreMessageOpen: false });
          }}
        >
          Forgotten your details?
        </div>
        {error && !this.state.restoreFormOpen && (
          <p style={{ color: "red", marginTop: "10px" }}>
            Invalid email or password.
          </p>
        )}
        {this.state.restoreMessageOpen && (
          <p
            style={{
              color: this.state.positive ? "green" : "red",
              fontSize: "16px",
              marginTop: "15px",
            }}
          >
            {this.state.restoreMessage}
            <br />
            {this.state.restoreEmail}
          </p>
        )}
        {this.state.restoreFormOpen && (
          <Form onSubmit={this.handleRestore} style={{ marginTop: "15px" }}>
            <Form.Input
              type="email"
              placeholder="Enter your email"
              name="restoreEmail"
              onChange={(e) => {
                this.setState({ restoreEmail: e.target.value });
              }}
            />
            <Form.Button type="submit" fluid content="RESET" />
          </Form>
        )}
      </div>
    );
  }
}

export default withFirebase(
  connect(
    null,
    actions
  )(reduxForm({ form: "loginForm" })(withRouter(LoginForm)))
);
