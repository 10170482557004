import React, { Component } from "react";

import "./project-overview.css";

import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { reduxForm } from "redux-form";
import { Dropdown, Menu } from "semantic-ui-react";
import ContentManager from "../content-manager/content-manager";
import ProjectOverviewDashboard from "./project-overview-dashboard";
import WebsiteLink from "./website-link";
import Revision from "./revision";
import PageSketches from "./page-sketches";
import PageSketchesEditor from "./page-sketches-editor";
import ProjectOverviewProposal from "./project-overview-proposal";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;

  return {
    projectId: id,
  };
};

class ProjectOverview extends Component {
  state = {
    showForm: false,
    addText: "",
    editText: "",
    editId: "",
  };

  submitForm = (id, value, field) => {
    this.props.changeField(id, value, field);
  };

  handleAddChange = async (e) => {
    await this.setState({ addText: e.target.value });
  };

  handleEditChange = async (e) => {
    await this.setState({ editText: e.target.value });
  };

  handleSubmitAdd = async (e) => {
    e.preventDefault();
    await this.props.addProjectComment(
      this.props.project.id,
      this.state.addText
    );
    await this.setState({ addText: "" });
  };

  handleShowForm = async (text, id) => {
    await this.setState({ editText: text, showForm: true, showId: id });
  };

  handleSubmitEdit = async (e, commentId) => {
    e.preventDefault();
    await this.props.updateProjectComment(
      this.props.project,
      this.state.editText,
      commentId
    );
    await this.setState({ editText: "", showForm: false });
  };

  handleDelete = async (comment) => {
    await this.props.deleteProjectComment(this.props.project.id, comment);
  };

  handleLogout = async () => {
    await this.props.firebase.logout();
    this.props.history.push(`/`);
  };

  render() {
    return (
      <div className="project-overview">
        <div className="project-overview-header">
          <div className="project-overview-header-logo">
            <Link to={"/dashboard/recent"}>
              <img src={"/assets/logo/logo.png"} alt="logo" />
            </Link>
          </div>
          <div className="project-overview-header-account">
            {/* <Link to={"/dashboard/admin-settings/profile"}>Your Account</Link> */}
            <Menu className="account-dropdown">
              <Dropdown item text="Account">
                <Dropdown.Menu>
                  <Dropdown.Header
                    icon="user circle"
                    content={this.props.userEmail}
                  />
                  <Dropdown.Item>
                    <Link to="/dashboard/admin-settings/profile">Profile</Link>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.handleLogout}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </div>
        </div>
        <div className="project-overview-nav">
          <div className="project-overview-menu">
            <NavLink
              className="item"
              to={`/dashboard/project-overview/${this.props.projectId}/dashboard`}
              activeClassName="navbar-active"
            >
              Project Dashboard
            </NavLink>
            <NavLink
              className="item"
              to={`/dashboard/project-overview/${this.props.projectId}/proposal`}
              activeClassName="navbar-active"
            >
              Package & Costs
            </NavLink>
            <NavLink
              className="item"
              to={`/dashboard/project-overview/${this.props.projectId}/page-sketches`}
              activeClassName="navbar-active"
            >
              Page Wireframes
            </NavLink>
            <NavLink
              className="item"
              to={`/dashboard/project-overview/${this.props.projectId}/content`}
              activeClassName="navbar-active"
            >
              Client Content
            </NavLink>
            <NavLink
              className="item"
              to={`/dashboard/project-overview/${this.props.projectId}/website-link`}
              activeClassName="navbar-active"
            >
              New Website
            </NavLink>
            <NavLink
              className="item"
              to={`/dashboard/project-overview/${this.props.projectId}/revision`}
              activeClassName="navbar-active"
            >
              Feedback & Questions
            </NavLink>
          </div>
        </div>
        <Switch>
          <Route
            exact
            path={`/dashboard/project-overview/:id`}
            render={() => (
              <Redirect
                to={`/dashboard/project-overview/${this.props.projectId}/dashboard`}
              />
            )}
          />
          <Route
            path={`/dashboard/project-overview/:id/page-sketches`}
            component={PageSketches}
          />
          <Route
            path={`/dashboard/project-overview/:id/dashboard`}
            component={ProjectOverviewDashboard}
          />
          <Route
            path={"/dashboard/project-overview/:id/website-link"}
            component={() => <WebsiteLink client={false} />}
          />
          <Route
            path={"/dashboard/project-overview/:id/content"}
            component={ContentManager}
          />
          <Route
            path={`/dashboard/project-overview/:id/revision`}
            component={() => <Revision client={false} />}
          />
          <Route
            path={`/dashboard/project-overview/:id/page-sketches-editor/:idp`}
            component={PageSketchesEditor}
          />
          <Route
            path={`/dashboard/project-overview/:id/proposal`}
            component={ProjectOverviewProposal}
          />
        </Switch>
      </div>
    );
  }
}

export default compose(
  firestoreConnect([{ collection: "projects" }, { collection: "users" }]),
  withRouter,
  connect(mapStateToProps, null),
  reduxForm({ form: "componentForm" })
)(ProjectOverview);
