import React, { Component } from "react";
import firebase from "../../../firebase";
import { Modal, Dimmer, Loader } from "semantic-ui-react";
import cuid from "cuid";

const downloadImage = (imgUrl, imgName) => {
  var storage = firebase.storage();
  var storageRef = storage.refFromURL(imgUrl);
  storageRef
    .getDownloadURL()
    .then(function (url) {
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function (event) {
        var blob = xhr.response;
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        //Create a DOMString representing the blob
        //and point the link element towards it
        let urlNew = window.URL.createObjectURL(blob);
        a.href = urlNew;
        a.setAttribute("id", "tempDownloadFile");
        a.download = imgName;
        a.click();
        let element = document.getElementById("tempDownloadFile");
        element.parentNode.removeChild(element);
        window.URL.revokeObjectURL(url);
      };
      xhr.open("GET", url);
      xhr.send();
    })
    .catch(function (error) {
      console.log(error);
    });
};

const ModalImage = ({ open, modalURL, modalText, toggleModal }) => (
  <Modal size={"tiny"} open={open} closeIcon onClose={toggleModal}>
    <Modal.Content>
      <img style={{ width: "100%" }} src={modalURL} alt={modalText} />
    </Modal.Content>
  </Modal>
);

const detectImage = (name) => {
  let getExt = name.split(".");
  getExt = getExt[getExt.length - 1];
  return (
    getExt === "jpg" ||
    getExt === "jpeg" ||
    getExt === "JPG" ||
    getExt === "JPEG" ||
    getExt === "png" ||
    getExt === "PNG"
  );
};

class FormBrandingFile extends Component {
  state = {
    branding: [],
    brandingToUpload: null,
    modalOpened: false,
    imageUploading: false,
    modalURL: "",
    modalText: "",
  };

  componentDidMount() {
    const branding = this.props.project.brandingFiles || [];
    this.setState({
      branding,
    });
  }

  toggleModal = (modalURL = "", modalText = "") => {
    this.setState((prevState) => ({
      modalOpened: !prevState.modalOpened,
      modalText,
      modalURL,
    }));
  };

  handleFile = async (e) => {
    this.setState({ imageUploading: true });
    e.persist();
    const file = e.target.files[0];

    const fileURL = await this.props.uploadFile(file, this.props.project.id);

    const fileObj = {
      id: cuid(),
      name: file.name,
      url: fileURL,
    };

    const newFiles = [...this.state.branding, fileObj];

    await this.setState({
      branding: newFiles,
      imageUploading: false,
    });

    await this.props.changeField(
      this.props.project.id,
      newFiles,
      "brandingFiles"
    );
  };

  deleteFile = async (e, file) => {
    e.persist();

    await this.props.deleteFile(file.name, this.props.project.id);

    const newFiles = this.state.branding.filter(
      (brand) => brand.id !== file.id
    );

    await this.setState({
      branding: newFiles,
    });

    await this.props.changeField(
      this.props.project.id,
      newFiles,
      "brandingFiles"
    );
  };

  render() {
    return (
      <div className="proposal-branding-upload">
        <ModalImage
          open={this.state.modalOpened}
          modalText={this.state.modalText}
          modalURL={this.state.modalURL}
          toggleModal={this.toggleModal}
        />
        {!this.props.client && (
          <p className="proposal-branding-upload-header">Branding Files</p>
        )}

        <div className="branding-proposal-upload-files">
          {this.state.branding.map((file) => (
            <div key={file.id} className="branding-border">
              {detectImage(file.name) ? (
                <img
                  className="branding-border-image"
                  src={file.url}
                  alt={file.name}
                  onClick={() => this.toggleModal(file.url, file.name)}
                />
              ) : (
                <img
                  className="branding-border-image"
                  src="/assets/form/Document-placeholder.png"
                  alt={file.name}
                />
              )}
              <div className="branding-border-image-control">
                <span onClick={(e) => this.deleteFile(e, file)}>REMOVE</span>
                <span onClick={(e) => downloadImage(file.url, file.name)}>
                  DOWNLOAD
                </span>
              </div>
            </div>
          ))}
        </div>
        {this.state.imageUploading != false && (
          <Dimmer active page>
            <Loader />
          </Dimmer>
        )}
        <label htmlFor="file-input-branding">
          {!this.props.client ? (
            <img src="/assets/icons/brand-upload.png" alt="s" />
          ) : (
            <img
              style={{ width: "650px", height: "141px" }}
              src="/assets/icons/brand-upload.png"
              alt="s"
            />
          )}
        </label>
        <input
          onChange={this.handleFile}
          id="file-input-branding"
          style={{ display: "none" }}
          type="file"
        />
      </div>
    );
  }
}

export default FormBrandingFile;
