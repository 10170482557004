import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Select } from "semantic-ui-react";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import SelectHook from "./select-hook";
import { connect } from "react-redux";
import { updateProject } from "../../../actions/project-actions";

const projectStatusOptions = [
  { key: "1", value: "newEnquiry", text: "New enquiry" },
  {
    key: "2",
    value: "proposalSharedWithClient",
    text: "Proposal shared with client",
  },
  {
    key: "3",
    value: "readyForDepositInvoice",
    text: "Ready for deposit invoice",
  },
  { key: "4", value: "depositSent", text: "Deposit sent" },
  {
    key: "5",
    value: "readyForProductionHandover",
    text: "Ready for production handover",
  },
  {
    key: "6",
    value: "awaitingLayoutApprovalFromClient",
    text: "Awaiting layout approval from client",
  },
  {
    key: "7",
    value: "sketchesApproved",
    text: "Sketches approved - Content form required",
  },
  {
    key: "8",
    value: "contentSharedWithClient",
    text: "Content form shared with client",
  },
  {
    key: "11",
    value: "contentApprovedForBuild",
    text: "Content approved for build",
  },
  {
    key: "9",
    value: "websiteLinkSharedWithClient",
    text: "Website link shared with client",
  },
  {
    key: "10",
    value: "readyForSEO&InvoiceLater",
    text: "Ready for SEO & Invoice (to go live at later date)",
  },
  { key: "11", value: "readyForSEO&Invoice", text: "Ready for SEO & Invoice" },
];

const ProposalIntroForm = ({ project, updateProject, clients }) => {
  const { register, control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      title: project.title || "",
      name: project.name || "",
      client: project.client || "",
      primaryContact: project.primaryContact || {
        name: "",
        jobTitle: "",
        email: "",
        mobile: undefined,
        landline: undefined,
      },
      secondaryContact: project.secondaryContact || {
        name: "",
        jobTitle: "",
        email: "",
        mobile: undefined,
        landline: undefined,
      },
      domainDetails:
        project.domainDetails ||
        `<b>Domain name:</b> Required<br>
         <b>Name of the registrar:</b> Required<br>
          <b>Login details:</b> Required`,
      existingWebsite: project.existingWebsite || "",
      shareProposal: project.shareProposal || false,
      sharePageSketches: project.sharePageSketches || false,
      shareContentForm: project.shareContentForm || false,
      shareWebsiteLink: project.shareWebsiteLink || false,
      revisionSession: project.revisionSession || false,
      sharedLinks: project.sharedLinks || "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    const revisions = project.revisions !== undefined ? project.revisions : 0;
    const updatedProject = { ...project, ...data, revisions: revisions };
    updateProject(updatedProject);
  };

  const optionClients = clients.map((client) => ({
    value: client.id,
    key: client.id,
    text: client.name,
  }));

  return (
    <div className="proposal-form-border">
      <h4 className="project-proposal-section-header">Project Details</h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <label>Project title</label>
          <input type="text" ref={register} name="title" />
        </Form.Field>
        <br />
        <Form.Field>
          <label>Prepared by</label>
          <input type="text" ref={register} name="name" />
        </Form.Field>
        <br />
        <Form.Field>
          <label>Client account access</label>
          <Controller
            placeholder="Client"
            control={control}
            name="client"
            as={SelectHook}
            search={true}
            setValue={setValue}
            value={getValues("client")}
            options={optionClients}
          />
        </Form.Field>
        <br />
        <h4 className="project-proposal-section-header">Primary Details</h4>
        <Form.Group>
          <Controller
            setValue={setValue}
            label="Name"
            value={getValues("primaryContact.name")}
            name="primaryContact.name"
            as={Form.Input}
            control={control}
            width={6}
          />
          <Controller
            setValue={setValue}
            label="Job Title"
            value={getValues("primaryContact.jobTitle")}
            name="primaryContact.jobTitle"
            as={Form.Input}
            control={control}
            width={6}
          />
          <Controller
            setValue={setValue}
            label="Email"
            value={getValues("primaryContact.email")}
            name="primaryContact.email"
            as={Form.Input}
            control={control}
            width={6}
          />
        </Form.Group>

        <Form.Group>
          <Controller
            setValue={setValue}
            label="Mobile"
            value={getValues("primaryContact.mobile")}
            name="primaryContact.mobile"
            type="number"
            as={Form.Input}
            control={control}
            width={8}
          />
          <Controller
            setValue={setValue}
            label="Landline"
            value={getValues("primaryContact.landline")}
            name="primaryContact.landline"
            type="number"
            as={Form.Input}
            control={control}
            width={8}
          />
        </Form.Group>
        <br />

        <h4 className="project-proposal-section-header">Secondary Details</h4>
        <Form.Group>
          <Controller
            setValue={setValue}
            label="Name"
            value={getValues("secondaryContact.name")}
            name="secondaryContact.name"
            as={Form.Input}
            control={control}
            width={6}
          />
          <Controller
            setValue={setValue}
            label="Job Title"
            value={getValues("secondaryContact.jobTitle")}
            name="secondaryContact.jobTitle"
            as={Form.Input}
            control={control}
            width={6}
          />
          <Controller
            setValue={setValue}
            label="Email"
            value={getValues("secondaryContact.email")}
            name="secondaryContact.email"
            as={Form.Input}
            control={control}
            width={6}
          />
        </Form.Group>

        <Form.Group>
          <Controller
            setValue={setValue}
            label="Mobile"
            value={getValues("secondaryContact.mobile")}
            name="secondaryContact.mobile"
            type="number"
            as={Form.Input}
            control={control}
            width={8}
          />
          <Controller
            setValue={setValue}
            label="Landline"
            value={getValues("secondaryContact.landline")}
            name="secondaryContact.landline"
            type="number"
            as={Form.Input}
            control={control}
            width={8}
          />
        </Form.Group>
        <br />
        <Form.Field>
          <label>Existing Website</label>
          <input
            type="text"
            ref={register}
            name="existingWebsite"
            autoComplete="off"
          />
        </Form.Field>

        <Form.Field>
          <Controller
            setValue={setValue}
            label="Domain Details"
            value={getValues("domainDetails")}
            name="domainDetails"
            placeholder="Login and DNS details are needed"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>

        <Form.Field>
          <Controller
            setValue={setValue}
            label="Links visible to the client"
            value={getValues("sharedLinks")}
            name="sharedLinks"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        {/*<Form.Field>*/}
        {/*    <label>Project Status</label>*/}
        {/*    <Controller*/}
        {/*        placeholder="New Enquiry"*/}
        {/*        control={control}*/}
        {/*        name="status"*/}
        {/*        as={SelectHook}*/}
        {/*        setValue={setValue}*/}
        {/*        value={getValues("status")}*/}
        {/*        options={projectStatusOptions}/>*/}
        {/*</Form.Field>*/}
        <br />
        {/*<Form.Field>*/}
        {/*    <label>Client's Content Deadline</label>*/}
        {/*    <input type="date" ref={register} name="clientContentDeadLine"/>*/}
        {/*</Form.Field>*/}
        {/*<br/>*/}
        {/*<Form.Field>*/}
        {/*    <label>Build date</label>*/}
        {/*    <input type="date" ref={register} name="buildDate"/>*/}
        {/*</Form.Field>*/}
        {/*<br/>*/}
        {/*<Form.Field>*/}
        {/*    <label>Next Revision Session</label>*/}
        {/*    <input type="date" ref={register} name="nextRevisionSession"/>*/}
        {/*</Form.Field>*/}
        {/*<br/>*/}
        <Form.Field>
          <h5 className="project-proposal-section-header-small">
            Share with client
          </h5>
          <p>
            These elements are to be shared with the client at key points in the
            project journey
          </p>
        </Form.Field>
        <Form.Field>
          <div className="form-switch-flex">
            <label className="form-switch">
              <input type="checkbox" ref={register} name="shareProposal" />
              <div className="form-slider form-round"></div>
            </label>
            <div className="form-switch-label">Share Proposal</div>
          </div>
        </Form.Field>
        <Form.Field>
          <div className="form-switch-flex">
            <label className="form-switch">
              <input type="checkbox" ref={register} name="sharePageSketches" />
              <div className="form-slider form-round"></div>
            </label>
            <div className="form-switch-label">Share page sketches</div>
          </div>
        </Form.Field>
        <Form.Field>
          <div className="form-switch-flex">
            <label className="form-switch">
              <input type="checkbox" ref={register} name="shareContentForm" />
              <div className="form-slider form-round"></div>
            </label>
            <div className="form-switch-label">Share content form</div>
          </div>
        </Form.Field>
        <Form.Field>
          <div className="form-switch-flex">
            <label className="form-switch">
              <input type="checkbox" ref={register} name="shareWebsiteLink" />
              <div className="form-slider form-round"></div>
            </label>
            <div className="form-switch-label">
              Share website in development link
            </div>
          </div>
        </Form.Field>
        <br />
        <Form.Field>
          <h5 className="project-proposal-section-header-small">
            Revision session status
          </h5>
          <p>
            Client has used {project.revisions || 0} of their revision sessions
          </p>
        </Form.Field>
        <Form.Field>
          <div className="form-switch-flex">
            <label className="form-switch">
              <input type="checkbox" ref={register} name="revisionSession" />
              <div className="form-slider form-round"></div>
            </label>
            <div className="form-switch-label">Closed / Open</div>
          </div>
        </Form.Field>
        <br />
        <Button type="submit" className="fluid">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default connect(null, { updateProject })(ProposalIntroForm);
