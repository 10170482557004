import React, { Component, Fragment } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import firebase from "../../../firebase";
import cuid from "cuid";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import { createPage, deletePage } from "../../../actions/pages-actions";
import { deleteComponents } from "../../../actions/wireframes-actions";
import Loading from "../../loading/loading";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const projects = state.firestore.ordered.projects;

  const pages =
    projects && projects.filter((project) => project.id === id)[0].pages;

  return {
    pages,
    id,
  };
};

class PageSketchesClient extends Component {
  state = {
    showPage: null,
    pages: [],
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);
    const db = firebase.firestore();
    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const project = await projectQuery.get();
    const pages = project.data().pages;
    await this.setState({
      pages: pages || [],
      showPage: pages && pages[0],
      project: project.data(),
    });
  }

  async componentWillUnmount() {
    const { firestore } = this.props;
    await firestore.unsetListener(`projects`);
  }

  render() {
    const { pages } = this.props;
    const { showPage, project } = this.state;

    if (!project) {
      return <Loading />;
    }

    if (!project.sharePageSketches) {
      return (
        <div
          style={{ backgroundColor: "#252525", height: "calc(100% - 182px)" }}
        >
          <div className="">
            <h1
              style={{
                color: "#8C8C8C",
                fontSize: "26px",
                width: "50%",
                margin: "0 auto",
                paddingTop: "100px",
                textAlign: "center",
              }}
            >
              We will work with you to nail down your page layouts using our in
              house wireframing tool. Your sketches will show here once there is
              something to see.
            </h1>
          </div>
        </div>
      );
    }

    return (
      <div className="page-sketches-main">
        <div className="page-sketches-left" style={{ padding: "4vw" }}>
          <div className={"pages-sketches-left-page-images"}>
            {showPage &&
              showPage.components.map((component) => (
                <div className="pages-sketches-left-page-image">
                  {component.image === "none" ? (
                    <>
                      <section
                        className={"insert-component-page-sketches"}
                        style={{
                          padding: `${
                            (Number(component.padding) || 0) + 20
                          }px 0`,
                          background: `${component.background || "#FFFFFF"}`,
                        }}
                      >
                        <p>{component.text}</p>
                      </section>
                    </>
                  ) : (
                    <>
                      <img
                        src={component.image}
                        alt="wireframe"
                        style={{
                          padding: `${component.padding || 0}px 0`,
                          background: `${component.background || "#FFFFFF"}`,
                        }}
                      />
                      <Fragment>
                        <p
                          className={"dnd-zone-item-text"}
                          style={{ fontWeight: "300" }}
                        >
                          {component.note || ""}
                        </p>
                      </Fragment>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className="page-sketches-right">
          <div className="page-sketches-right-main-list">
            {pages &&
              pages.map((page) => (
                <>
                  <span
                    style={{
                      textDecoration: "none",
                      color: `${
                        showPage && showPage.id === page.id
                          ? "#F4A62B"
                          : "white"
                      }`,
                    }}
                    key={page.id}
                    onClick={() => this.setState({ showPage: page })}
                  >
                    {page.pageName}
                  </span>
                  {showPage && showPage.id === page.id && showPage.description && (
                    <p
                      className={"page-sketches-client-desc"}
                      style={{ fontWeight: "300", marginTop: "20px" }}
                    >
                      {showPage.description}
                    </p>
                  )}
                </>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirestore,
  firestoreConnect([{ collection: "projects" }]),
  connect(mapStateToProps, null)
)(PageSketchesClient);
