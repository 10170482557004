import React, { Component } from "react";
import firebase from "../../../firebase";
import Loading from "../../loading/loading";
import { compose } from "redux";
import { withRouter } from "react-router";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import "./proposal-client.css";
import moment, { min } from "moment";
import ScrollTrigger from "react-scroll-trigger";
import ReactHtmlParser from "react-html-parser";
import {
  deleteFileBranding,
  uploadFileBranding,
} from "../../../actions/tasks-actions";
import {
  addProjectCommentC,
  changeField,
  deleteProjectCommentC,
  updateProjectCommentC,
} from "../../../actions/project-actions";
import FormBrandingFile from "../project-overview/form-branding-file";
import { Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import cuid from "cuid";
import ScrollIntoView from "react-scroll-into-view";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const profile = state.firebase.profile;
  const firstName = profile.name && profile.name.split(" ")[0];

  return {
    id,
    profile,
  };
};

const urlPrepend = (s) => {
  if (!s.match(/^[a-zA-Z]+:\/\//)) {
    return "https://" + s;
  }
  return s;
};

class ProposalClient extends Component {
  state = {
    project: null,
    currentElement: "",
    showHint: "",
    showHintId: "",
    showHintType: "",
    addText: "",
    editText: "",
    editId: "",
  };

  inputRef = React.createRef();

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);
    const db = firebase.firestore();
    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const project = await projectQuery.get();

    const projectData = project.data();

    const pricingQuery = await db.collection("pricing");
    const pricing = await pricingQuery.get();

    const services = pricing.docs
      .filter((p) => p.data().type === "service")
      .map((p) => p.data());
    const packages = pricing.docs
      .filter((p) => p.data().type === "package")
      .map((p) => p.data());

    const pack = packages.find((p) => p.id === projectData.package);
    let addons =
      projectData.addons &&
      services.filter((s) => projectData.addons.includes(s.id));
    const uAddons =
      projectData.unconfirmedAddons &&
      services.filter((s) => projectData.unconfirmedAddons.includes(s.id));

    if (projectData.customAddons) {
      addons = [...addons, ...projectData.customAddons];
    }

    await this.setState({
      project: projectData,
      pack: pack || null,
      addons: addons || [],
      uAddons: uAddons || [],
      comments: projectData.commentsC || [],
    });
  }

  submitForm = (id, value, field) => {
    this.props.changeField(id, value, field);
  };
  handleAddChange = async (e) => {
    await this.setState({ addText: e.target.value });
  };
  handleEditChange = async (e) => {
    await this.setState({ editText: e.target.value });
  };
  handleSubmitAdd = async (e) => {
    e.preventDefault();
    const id = cuid();
    await this.props.addProjectCommentC(
      this.state.project.id,
      this.state.addText,
      id,
      this.props.profile.name
    );
    const newComments = [
      ...this.state.comments,
      { id: id, text: this.state.addText, author: this.props.profile.name },
    ];
    await this.setState({ addText: "", comments: newComments });
  };
  handleShowForm = async (text, id) => {
    await this.setState({ editText: text, showForm: true, showId: id });
  };
  handleSubmitEdit = async (e, commentId) => {
    e.preventDefault();
    await this.props.updateProjectCommentC(
      this.state.project,
      this.state.editText,
      commentId,
      this.props.profile.name
    );
    const newComments = this.state.comments.map((comment) =>
      comment.id === commentId
        ? {
            id: commentId,
            text: this.state.editText,
            author: this.props.profile.name,
          }
        : comment
    );
    await this.setState({
      editText: "",
      showForm: false,
      comments: newComments,
    });
  };
  handleDelete = async (comment) => {
    await this.props.deleteProjectCommentC(this.state.project.id, comment);
    const newComments = this.state.comments.filter(
      (oldComment) => oldComment.id !== comment.id
    );
    await this.setState({
      editText: "",
      showForm: false,
      comments: newComments,
    });
  };

  handlePackCalc = () => {
    return `£${
      Number(this.state.pack.price) +
      this.state.addons.reduce((acc, cv) => Number(cv.price) + acc, 0)
    }`;
  };

  customCalc = () => {
    const min =
      Number(this.state.project.prebuildMin || 0) +
      Number(this.state.project.buildMin || 0);
    const max =
      Number(this.state.project.prebuildMax || 0) +
      Number(this.state.project.buildMax || 0);
    const addons = this.state.addons.reduce(
      (acc, cv) => Number(cv.price) + acc,
      0
    );

    return `£${min + addons} - £${max + addons}`;
  };

  async onEnterViewport(t) {
    await this.setState({
      currentElement: t,
    });
  }

  async onExitViewport(t) {}

  render() {
    const { project, pack, addons } = this.state;

    const { uploadFileBranding, deleteFileBranding, changeField } = this.props;

    if (!project) {
      return <Loading />;
    }

    if (!project.shareProposal) {
      return (
        <div id="main" style={{ height: "75%" }}>
          <div className="fof">
            <h1 style={{ color: "#8C8C8C" }}>
              We are currently working on it...
            </h1>
          </div>
        </div>
      );
    }

    return (
      <div className="proposal-client">
        <div className="proposal-client-header">
          <p className="proposal-client-header-p">
            {project.buildDate &&
              moment(project.buildDate).format("DD | MM | YYYY")}
          </p>
          <h1 className="proposal-client-header-h">{project.title}</h1>
          <p className="proposal-client-header-p">
            PROPOSAL FOR NEW SQUARESPACE WEBSITE
          </p>
          <p className="proposal-client-header-p">
            Proposal prepared by - {project.name}
          </p>
        </div>
        <div className="proposal-client-content">
          <div className="proposal-client-content-nav">
            <ScrollIntoView
              selector={"#contacts"}
              onClick={() =>
                setTimeout(() => this.setState({ currentElement: 1 }), 1500)
              }
            >
              <p
                className={
                  this.state.currentElement === 1
                    ? "proposal-client-content-nav-o"
                    : "proposal-client-content-nav-p"
                }
              >
                YOUR CONTACTS{this.state.currentElement === 1 && " —"}
              </p>
            </ScrollIntoView>
            <ScrollIntoView
              selector={"#working"}
              onClick={() =>
                setTimeout(() => this.setState({ currentElement: 2 }), 1500)
              }
            >
              <p
                className={
                  this.state.currentElement === 2
                    ? "proposal-client-content-nav-o"
                    : "proposal-client-content-nav-p"
                }
              >
                WORKING WITH US{this.state.currentElement === 2 && " —"}
              </p>
            </ScrollIntoView>
            <ScrollIntoView
              selector={"#overview"}
              onClick={() =>
                setTimeout(() => this.setState({ currentElement: 3 }), 1500)
              }
            >
              <p
                className={
                  this.state.currentElement === 3
                    ? "proposal-client-content-nav-o"
                    : "proposal-client-content-nav-p"
                }
              >
                OVERVIEW OF YOUR PROJECT
                {this.state.currentElement === 3 && " —"}
              </p>
            </ScrollIntoView>
            <ScrollIntoView
              selector={"#beauty"}
              onClick={() =>
                setTimeout(() => this.setState({ currentElement: 4 }), 1500)
              }
            >
              <p
                className={
                  this.state.currentElement === 4
                    ? "proposal-client-content-nav-o"
                    : "proposal-client-content-nav-p"
                }
              >
                LET'S MAKE IT BEAUTIFUL{this.state.currentElement === 4 && " —"}
              </p>
            </ScrollIntoView>
            <ScrollIntoView
              selector={"#tech"}
              onClick={() =>
                setTimeout(() => this.setState({ currentElement: 9 }), 1500)
              }
            >
              <p
                className={
                  this.state.currentElement === 9
                    ? "proposal-client-content-nav-o"
                    : "proposal-client-content-nav-p"
                }
              >
                TECHNICAL REQUIREMENTS{this.state.currentElement === 9 && " —"}
              </p>
            </ScrollIntoView>
            <ScrollIntoView
              selector={"#cost"}
              onClick={() =>
                setTimeout(() => this.setState({ currentElement: 5 }), 1500)
              }
            >
              <p
                className={
                  this.state.currentElement === 5
                    ? "proposal-client-content-nav-o"
                    : "proposal-client-content-nav-p"
                }
              >
                {project.package ? "COST PACKAGE" : "YOUR CUSTOM PACKAGE"}
                {this.state.currentElement === 5 && " —"}
              </p>
            </ScrollIntoView>
            <ScrollIntoView
              selector={"#pages"}
              onClick={() =>
                setTimeout(() => this.setState({ currentElement: 7 }), 1500)
              }
            >
              <p
                className={
                  this.state.currentElement === 7
                    ? "proposal-client-content-nav-o"
                    : "proposal-client-content-nav-p"
                }
              >
                YOUR PAGES{this.state.currentElement === 7 && " —"}
              </p>
            </ScrollIntoView>
            <ScrollIntoView
              selector={"#comments"}
              onClick={() =>
                setTimeout(() => this.setState({ currentElement: 8 }), 1500)
              }
            >
              <p
                className={
                  this.state.currentElement === 8
                    ? "proposal-client-content-nav-o"
                    : "proposal-client-content-nav-p"
                }
              >
                YOUR COMMENTS{this.state.currentElement === 8 && " —"}
              </p>
            </ScrollIntoView>
          </div>
          <div className="proposal-client-content-main">
            <ScrollTrigger
              onProgress={() => this.onEnterViewport(1)}
              onExit={() => this.onExitViewport(1)}
            >
              <div id={"contacts"} className="proposal-client-contact">
                <h1 className={"hidden-contact-client"}>CONTACTS</h1>
                <h2>YOU</h2>
                <div className="proposal-client-contact-flex">
                  <div className="proposal-client-contact-flex-item">
                    <h4>PRIMARY CONTACT</h4>
                    {ReactHtmlParser(project.primaryContact || "")}
                  </div>
                  <div className="proposal-client-contact-flex-item">
                    <h4>INVOICING DETAILS</h4>
                    {ReactHtmlParser(
                      project.secondaryContact || "<p>Not provided</p>"
                    )}
                  </div>
                </div>
                <h2>US</h2>
                <div className="proposal-client-contact-flex">
                  <div className="proposal-client-contact-flex-item">
                    <h4>NEW BUSINESS</h4>
                    <p>xxxxxxxxxxxxxxx</p>
                    <p>000000000000</p>
                    <p>xxxxxxxx@xxxx</p>
                  </div>
                  <div className="proposal-client-contact-flex-item">
                    <h4>PRODUCTION</h4>
                    <p>xxxxxxxxxxxxxxx</p>
                    <p>000000000000</p>
                    <p>xxxxxxxx@xxxx</p>
                  </div>
                </div>
                <span
                  style={{ marginTop: "30px" }}
                  className="proposal-client-contact-website"
                >
                  EXISTING WEBSITE
                </span>{" "}
                <a
                  target={"_blank"}
                  href={urlPrepend(project.existingWebsite)}
                  className="proposal-client-contact-website-span"
                >
                  - {project.existingWebsite || "Not  provided"}
                </a>
                <br />
                <div
                  style={{ marginTop: "30px", marginBottom: "10px" }}
                  className="proposal-client-contact-website"
                >
                  OTHER LINKS
                </div>
                <br />
                {/*{project.links && project.links.map((l,idx) => (*/}
                {/*    <div key={idx}>*/}
                {/*         <a target={"_blank"} href={urlPrepend(l.url)} className="proposal-client-contact-website-span">- {l.title || "Not  provided"}</a>*/}
                {/*    </div>*/}
                {/*))}*/}
                <div className={"proposal-client-contact-website-span-div"}>
                  {ReactHtmlParser(
                    project.sharedLinks || "<p>Not provided</p>"
                  )}
                </div>
              </div>
            </ScrollTrigger>

            <ScrollTrigger
              onProgress={() => this.onEnterViewport(2)}
              onExit={() => this.onExitViewport(1)}
            >
              <div id={"working"} className="working-with-us proposal-templ">
                <h1 className={"hidden-contact-client"}>WORKING WITH US</h1>
                <h2>TIMESCALE</h2>
                {ReactHtmlParser(project.timescale || "<p>Not provided</p>")}
                <h2>NEXT STEPS</h2>
                {ReactHtmlParser(project.nextSteps || "<p>Not provided</p>")}
              </div>
            </ScrollTrigger>
            <ScrollTrigger
              onProgress={() => this.onEnterViewport(3)}
              onExit={() => this.onExitViewport(1)}
            >
              <div id={"overview"} className="proposal-templ">
                <h1 className={"hidden-contact-client"}>
                  OVERVIEW OF YOUR PROJECT
                </h1>
                <h2>SCOPE</h2>
                {ReactHtmlParser(project.scope || "<p>Not provided</p>")}
                <h2>USP's</h2>
                {ReactHtmlParser(project.usp || "<p>Not provided</p>")}
                <h2>HOW YOUR WEBSITE WILL WORK FOR YOU</h2>
                {ReactHtmlParser(project.hywwwfy || "<p>Not provided</p>")}
                <h2>YOUR TARGET AUDIENCE</h2>
                {ReactHtmlParser(
                  project.yourTargetAudience || "<p>Not provided</p>"
                )}
                <h2>YOUR COMPETITORS</h2>
                {ReactHtmlParser(
                  project.yourCompetitors || "<p>Not provided</p>"
                )}
              </div>
            </ScrollTrigger>
            <ScrollTrigger
              onProgress={() => this.onEnterViewport(4)}
              onExit={() => this.onExitViewport(1)}
            >
              <div id={"beauty"} className="proposal-templ">
                <h1 className={"hidden-contact-client"}>
                  LETS MAKE IT BEAUTIFUL
                </h1>
                <h2>YOUR KEY DESIGN REQUIREMENTS</h2>
                {ReactHtmlParser(project.ykdr || "<p>Not provided</p>")}
                <h2>BRANDING</h2>

                {ReactHtmlParser(
                  `<img src=${project.brandingFiles[0].url} width="200"/>` ||
                    "<p>Not provided</p>"
                )}
                <h2>BRANDING FILES</h2>
                <FormBrandingFile
                  project={project}
                  uploadFile={uploadFileBranding}
                  deleteFile={deleteFileBranding}
                  changeField={changeField}
                  client={true}
                />
                <h2>INSPIRATION FOR NEW SITE - PROVIDED BY YOU</h2>
                {ReactHtmlParser(
                  project.yourInspiration || "<p>Not provided</p>"
                )}
                <h2>INSPIRATION FOR NEW SITE - PROVIDED BY D&G</h2>
                {ReactHtmlParser(
                  project.ourInspiration || "<p>Not provided</p>"
                )}
              </div>
            </ScrollTrigger>

            <ScrollTrigger
              onProgress={() => this.onEnterViewport(9)}
              onExit={() => this.onExitViewport(1)}
            >
              <div id={"tech"} className="proposal-templ">
                <h1 className={"hidden-contact-client"}>
                  TECHNICAL/SPECIAL REQUIREMENTS
                </h1>
                <h2>TECHNICAL REQUIREMENTS</h2>
                {ReactHtmlParser(project.techReq || "<p>Not provided</p>")}
                <h2>SEO</h2>
                {ReactHtmlParser(project.SEO || "<p>Not provided</p>")}
                <h2>DOMAIN(S)</h2>
                {ReactHtmlParser(project.domains || "<p>Not provided</p>")}
                <h2>PLUGINS</h2>
                {ReactHtmlParser(project.plugins || "<p>Not provided</p>")}
                <h2>SPECIAL REQUIREMENTS</h2>
                {ReactHtmlParser(project.specReq || "<p>Not provided</p>")}
              </div>
            </ScrollTrigger>

            <ScrollTrigger
              onProgress={() => this.onEnterViewport(5)}
              onExit={() => this.onExitViewport(1)}
            >
              {project.package ? (
                <div id="cost" className="proposal-templ">
                  <h1 className={"hidden-contact-client"}>
                    {project.package ? "COST PACKAGE" : "CUSTOM COST"}
                  </h1>
                  <h2>COST OVERVIEW</h2>
                  {ReactHtmlParser(
                    project.costOverview || "<p>Not provided</p>"
                  )}
                  <br />
                  <br />
                  {pack && (
                    <div className="proposal-cost-flex">
                      <div className="proposal-cost-cont">
                        <h4>
                          {pack.title} - £{pack.price}
                        </h4>
                        <ul>
                          {pack.included &&
                            pack.included.map((p) => (
                              <li key={p.id}>
                                {p.title}{" "}
                                {p.description && (
                                  <img
                                    onClick={() =>
                                      this.setState({
                                        showHint: p.description,
                                        showHintId: pack.id,
                                      })
                                    }
                                    className="q-mark"
                                    src="/assets/icons/q-mark.png"
                                    alt=""
                                  />
                                )}
                              </li>
                            ))}
                        </ul>
                      </div>
                      {this.state.showHint !== "" &&
                        this.state.showHintId === pack.id && (
                          <div className={"proposal-hint"}>
                            <div>
                              <img
                                onClick={() =>
                                  this.setState({
                                    showHint: "",
                                    showHintId: "",
                                  })
                                }
                                className="q-mark"
                                src="/assets/icons/q-mark.png"
                                alt=""
                              />
                            </div>
                            <div className={"proposal-hint-text"}>
                              {this.state.showHint}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                  <div className="proposal-cost-flex">
                    <div className="proposal-cost-cont">
                      <h4>
                        ADDONS - £
                        {addons.reduce((acc, cv) => Number(cv.price) + acc, 0)}
                      </h4>
                      <ul>
                        {addons.map((p, idx) => (
                          <li key={p.id}>
                            {p.title} - £{p.price} {p.per && `per ${p.per}`}{" "}
                            {p.description && (
                              <img
                                onClick={() =>
                                  this.setState({
                                    showHint: p.description,
                                    showHintId: p.id,
                                    showHintType: "a",
                                  })
                                }
                                className="q-mark"
                                src="/assets/icons/q-mark.png"
                                alt=""
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {this.state.showHint !== "" &&
                      this.state.addons.find(
                        (a) => a.id === this.state.showHintId
                      ) &&
                      this.state.showHintType === "a" && (
                        <div className={"proposal-hint"}>
                          <div>
                            <img
                              onClick={() =>
                                this.setState({ showHint: "", showHintId: "" })
                              }
                              className="q-mark"
                              src="/assets/icons/q-mark.png"
                              alt=""
                            />
                          </div>
                          <div className={"proposal-hint-text"}>
                            {this.state.showHint}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="proposal-cost-flex">
                    <div className="proposal-cost-cont">
                      <h4>
                        OPTIONAL ADDONS - £
                        {this.state.uAddons.reduce(
                          (acc, cv) => Number(cv.price) + acc,
                          0
                        )}
                      </h4>
                      <ul>
                        {this.state.uAddons.map((p, idx) => (
                          <li key={p.id}>
                            {p.title} - £{p.price} {p.per && `per ${p.per}`}{" "}
                            {p.description && (
                              <img
                                onClick={() =>
                                  this.setState({
                                    showHint: p.description,
                                    showHintId: p.id,
                                    showHintType: "u",
                                  })
                                }
                                className="q-mark"
                                src="/assets/icons/q-mark.png"
                                alt=""
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {this.state.showHint !== "" &&
                      this.state.uAddons.find(
                        (a) => a.id === this.state.showHintId
                      ) &&
                      this.state.showHintType === "u" && (
                        <div className={"proposal-hint"}>
                          <div>
                            <img
                              onClick={() =>
                                this.setState({ showHint: "", showHintId: "" })
                              }
                              className="q-mark"
                              src="/assets/icons/q-mark.png"
                              alt=""
                            />
                          </div>
                          <div className={"proposal-hint-text"}>
                            {this.state.showHint}
                          </div>
                        </div>
                      )}
                  </div>
                  <h4 className="proposal-price-total">
                    TOTAL - {pack ? this.handlePackCalc() : this.customCalc()}
                  </h4>
                  <br />
                  <h2>Terms and Conditions</h2>
                  {ReactHtmlParser(
                    project.termsConditions || "<p>Not provided</p>"
                  )}
                  <h2>Squarespace Plan</h2>
                  {ReactHtmlParser(
                    project.squareSpacePlan || "<p>Not provided</p>"
                  )}
                </div>
              ) : (
                <div className="proposal-templ">
                  <h2>COST OVERVIEW</h2>
                  {ReactHtmlParser(
                    project.costOverview || "<p>Not provided</p>"
                  )}
                  <br />
                  <br />
                  {project.prebuildMin && project.prebuildMax && (
                    <div className="proposal-cost-flex">
                      <div className="proposal-cost-cont">
                        <h4>
                          PRE BUILD - £{project.prebuildMin} - £
                          {project.prebuildMax}
                        </h4>
                        <ul>
                          <li>Develop site structure</li>
                          <li>Create and agree page wireframes</li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {project.buildMin && project.buildMax && (
                    <div className="proposal-cost-flex">
                      <div className="proposal-cost-cont">
                        <h4>
                          BUILD - £{project.buildMin} - £{project.buildMax}
                        </h4>
                        <ul>
                          <li>Develop site structure</li>
                          <li>Create and agree page wireframes</li>
                        </ul>
                      </div>
                    </div>
                  )}
                  <div className="proposal-cost-flex">
                    <div className="proposal-cost-cont">
                      <h4>
                        ADDONS - £
                        {addons.reduce((acc, cv) => Number(cv.price) + acc, 0)}
                      </h4>
                      <ul>
                        {addons.map((p, idx) => (
                          <li key={p.id}>
                            {p.title} - £{p.price} {p.per && `per ${p.per}`}{" "}
                            {p.description && (
                              <img
                                onClick={() =>
                                  this.setState({
                                    showHint: p.description,
                                    showHintId: p.id,
                                    showHintType: "a",
                                  })
                                }
                                className="q-mark"
                                src="/assets/icons/q-mark.png"
                                alt=""
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {this.state.showHint !== "" &&
                      this.state.addons.find(
                        (a) => a.id === this.state.showHintId
                      ) &&
                      this.state.showHintType === "a" && (
                        <div className={"proposal-hint"}>
                          <div>
                            <img
                              onClick={() =>
                                this.setState({ showHint: "", showHintId: "" })
                              }
                              className="q-mark"
                              src="/assets/icons/q-mark.png"
                              alt=""
                            />
                          </div>
                          <div className={"proposal-hint-text"}>
                            {this.state.showHint}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="proposal-cost-flex">
                    <div className="proposal-cost-cont">
                      <h4>
                        OPTIONAL ADDONS - £
                        {this.state.uAddons.reduce(
                          (acc, cv) => Number(cv.price) + acc,
                          0
                        )}
                      </h4>
                      <ul>
                        {this.state.uAddons.map((p, idx) => (
                          <li key={p.id}>
                            {p.title} - £{p.price} {p.per && `per ${p.per}`}{" "}
                            {p.description && (
                              <img
                                onClick={() =>
                                  this.setState({
                                    showHint: p.description,
                                    showHintId: p.id,
                                    showHintType: "u",
                                  })
                                }
                                className="q-mark"
                                src="/assets/icons/q-mark.png"
                                alt=""
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {this.state.showHint !== "" &&
                      this.state.uAddons.find(
                        (a) => a.id === this.state.showHintId
                      ) &&
                      this.state.showHintType === "u" && (
                        <div className={"proposal-hint"}>
                          <div>
                            <img
                              onClick={() =>
                                this.setState({ showHint: "", showHintId: "" })
                              }
                              className="q-mark"
                              src="/assets/icons/q-mark.png"
                              alt=""
                            />
                          </div>
                          <div className={"proposal-hint-text"}>
                            {this.state.showHint}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="proposal-cost-flex">
                    <div className="proposal-cost-cont">
                      <h4>NEW ADDONS</h4>
                      <ul>
                        {project.newAddons &&
                          project.newAddons.map((p, idx) => (
                            <li key={p.id}>
                              {p.title} - £{p.price} {p.per && `per ${p.per}`}{" "}
                              {p.description && (
                                <img
                                  onClick={() =>
                                    this.setState({
                                      showHint: p.description,
                                      showHintId: p.id,
                                      showHintType: "n",
                                    })
                                  }
                                  className="q-mark"
                                  src="/assets/icons/q-mark.png"
                                  alt=""
                                />
                              )}
                            </li>
                          ))}
                      </ul>
                    </div>
                    {this.state.showHint !== "" &&
                      project.newAddons.find(
                        (a) => a.id === this.state.showHintId
                      ) &&
                      this.state.showHintType === "n" && (
                        <div className={"proposal-hint"}>
                          <div>
                            <img
                              onClick={() =>
                                this.setState({ showHint: "", showHintId: "" })
                              }
                              className="q-mark"
                              src="/assets/icons/q-mark.png"
                              alt=""
                            />
                          </div>
                          <div className={"proposal-hint-text"}>
                            {this.state.showHint}
                          </div>
                        </div>
                      )}
                  </div>
                  <h4 className="proposal-price-total">
                    TOTAL - {pack ? this.handlePackCalc() : this.customCalc()}
                  </h4>
                  <h2>Squarespace Fee</h2>
                  {ReactHtmlParser(
                    project.squareSpacePlan || "<p>Not provided</p>"
                  )}
                  <h2>Terms and Conditions</h2>
                  {ReactHtmlParser(
                    project.termsConditions || "<p>Not provided</p>"
                  )}
                </div>
              )}
            </ScrollTrigger>

            <ScrollTrigger
              onProgress={() => this.onEnterViewport(7)}
              onExit={() => this.onExitViewport(1)}
            >
              <div id={"pages"} className="proposal-templ">
                <h2>YOUR PAGES</h2>
                {project.pages &&
                  project.pages.map((p) => (
                    <div key={p.id} className="proposal-pages">
                      <h4>{p.pageName}</h4>
                      <p>{p.description}</p>
                    </div>
                  ))}

                {/*<Link onClick={() => console.log("Huray")} to={`/dashboard/project-overview-client/${project.id}/page-sketches`} className={"proposal-pages-link"}>View layout sketches</Link>*/}
              </div>
            </ScrollTrigger>
            <ScrollTrigger
              onProgress={() => this.onEnterViewport(8)}
              onExit={() => this.onExitViewport(1)}
            >
              <div id={"comments"} className="proposal-templ">
                <h2>YOUR COMMENTS</h2>
                <div className="project-overview-container-bottom">
                  {this.state.comments.map((comment) => (
                    <div
                      key={comment.id}
                      style={{ width: "100%" }}
                      className="proposal-comment-flex"
                    >
                      <div className="proposal-comment" key={comment.id}>
                        {this.state.showForm &&
                        this.state.showId === comment.id ? (
                          <div>
                            <p className={"comment-inside-h"}>
                              {comment.author}
                            </p>
                            <form
                              style={{}}
                              key={comment.id}
                              action=""
                              onSubmit={(e) => {
                                this.handleSubmitEdit(e, comment.id);
                              }}
                            >
                              <input
                                value={this.state.editText}
                                className="comments-add-input-po"
                                placeholder="Add Comment..."
                                onChange={this.handleEditChange}
                                autoFocus="true"
                              />
                            </form>
                            <div className={"comment-inside-f"}>
                              <span
                                onClick={() =>
                                  this.handleShowForm(comment.text, comment.id)
                                }
                              >
                                EDIT
                              </span>
                              <span onClick={(e) => this.handleDelete(comment)}>
                                DELETE
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className={"comment-inside"}>
                            <p className={"comment-inside-h"}>
                              {comment.author}
                            </p>
                            <p className={"comment-inside-t"}>{comment.text}</p>
                            <div className={"comment-inside-f"}>
                              <span
                                onClick={() =>
                                  this.handleShowForm(comment.text, comment.id)
                                }
                              >
                                EDIT
                              </span>
                              <span onClick={(e) => this.handleDelete(comment)}>
                                DELETE
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="revision-tasks-form proposal-ph"
                  style={{ width: "100%", marginTop: "0px" }}
                >
                  <form action="" onSubmit={(e) => this.handleSubmitAdd(e)}>
                    <textarea
                      onChange={this.handleAddChange}
                      id="text"
                      type="text"
                      required={true}
                      value={this.state.addText}
                      placeholder="Add Comment..."
                      autoComplete="off"
                      style={{ fontSize: "16px", height: "100px" }}
                    />
                    <div
                      className="revision-tasks-form-form"
                      style={{ padding: "5px" }}
                    >
                      <div className="revision-tasks-form-control">
                        <div className={"revision-tasks-form-control-func"}>
                          <img
                            height={"32px"}
                            src={"/assets/icons/add.png"}
                            alt="."
                          />
                          <button type="submit">ADD COMMENT</button>
                        </div>
                        <div className="revision-tasks-form-control-func"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </ScrollTrigger>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirestore,
  firestoreConnect([{ collection: "projects" }]),
  connect(mapStateToProps, {
    uploadFileBranding,
    deleteFileBranding,
    changeField,
    addProjectCommentC,
    updateProjectCommentC,
    deleteProjectCommentC,
  })
)(ProposalClient);
