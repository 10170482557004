import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Select } from "semantic-ui-react";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import SelectHook from "./select-hook";
import { connect } from "react-redux";
import { updateProject } from "../../../actions/project-actions";
import FormBrandingFile from "./form-branding-file";
import {
  uploadFileBranding,
  deleteFileBranding,
} from "../../../actions/tasks-actions";
import { changeField } from "../../../actions/project-actions";

const ProposalTechForm = ({
  project,
  updateProject,
  uploadFileBranding,
  deleteFileBranding,
  changeField,
}) => {
  const { register, control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      techReq: project.techReq || "",
      SEO: project.SEO || "",
      domains: project.domains || "",
      plugins: project.plugins || "",
      specReq: project.specReq || "",
    },
  });

  const onSubmit = (data) => {
    const updatedProject = { ...project, ...data };
    updateProject(updatedProject);
  };

  return (
    <div className="proposal-form-border">
      <h4 className="project-proposal-section-header">
        Technical/Special requirements
      </h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <Controller
            setValue={setValue}
            label="Domain(s)"
            value={getValues.domains}
            name="domains"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <Controller
            setValue={setValue}
            value={getValues.SEO}
            label="SEO"
            name="SEO"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />

        <Form.Field>
          <Controller
            setValue={setValue}
            value={getValues.plugins}
            label="Plug-ins & Integrations"
            name="plugins"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <Controller
            setValue={setValue}
            label="Special Requirements"
            value={getValues.specReq}
            name="specReq"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Button type="submit" className="fluid">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default connect(null, {
  updateProject,
  uploadFileBranding,
  deleteFileBranding,
  changeField,
})(ProposalTechForm);
