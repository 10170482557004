import React, {Component} from 'react';
import {Grid} from "semantic-ui-react";
import {addComment, deleteComment, updateComment} from "../../../actions/pages-actions";
import "../build/build.css"
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";
import Loading from "../../loading/loading";

import "./page-preview.css";


const mapStateToProps = (state,ownProps) => {
    const projectId = ownProps.match.params.id;
    const pageId = ownProps.match.params.idp;
    const projects = state.firestore.ordered.projects;
    const project = projects && projects.filter(project => project.id === projectId)[0];
    const pageNameDoc = projects && project && project.pages.filter(page => page.id === pageId)[0];
    const pageName = pageNameDoc && pageNameDoc.pageName;
    return  {
        projectId,
        pageId,
        project,
        pageName,
        pageNameDoc
    }
};

const actions = {
    addComment,
    deleteComment,
    updateComment,
};


class ClientPagePreview extends Component {

    state = {
        commentText: "",
        editComment: "",
        showEditForm: false,
        editId: ""
    };

    handleSubmit = async(e) => {
        if(this.state.commentText.length === 0) {
            return;
        }
        e.preventDefault();
         await this.props.addComment(this.props.pageNameDoc, this.state.commentText, this.props.projectId);
        this.setState({commentText: ""});
    };

    handleSubmitEdit = async(e, id) => {
        if(this.state.editComment.length === 0) {
            return;
        }
        e.preventDefault();
        await this.props.updateComment(this.props.pageNameDoc, this.state.editComment, id, this.props.projectId);
        this.setState({edit: "",showEditForm: false});
    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`projects`);
    }

    async componentWillUnmount() {
        const {firestore} = this.props;
        await firestore.unsetListener(`projects`);
    }

    handleClick = async (text,id) => {
       await this.setState({editComment: text, showEditForm: true, editId: id});
    };

    render() {


        const { defaultComponents, pageName, pageNameDoc, project, projectId} = this.props;



        return (
            <Grid className="wireframes-build-container">
                <Grid.Row columns={2}>
                    <Grid.Column width={5} className={"wireframes-build-container-grid"}>
                        <div className="wireframes-build-menu">
                            <div className="wireframes-build-menu-nav">
                                <Link to={`/client/dashboard/preview/${projectId}`} className="wireframes-build-menu-nav-link" style={{padding: "15px 30px"}}>
                                    <img src={"/assets/icons/arrow.svg"} style={{height: "24px"}} alt=""/>   Back to page list
                                </Link>
                            </div>
                            <div className="preview-section">
                                <p className="preview-header">{pageNameDoc && pageNameDoc.pageName}</p>
                                <p className="preview-desc">{pageNameDoc && pageNameDoc.description}</p>
                                <div>
                                    {pageNameDoc && pageNameDoc.comments.map((comment,idx) =>(
                                        <div style={{margin: "8px 0"}} key={idx}>
                                            {this.state.showEditForm && this.state.editId === comment.id ?
                                                <form action="" onSubmit={(e) => this.handleSubmitEdit(e,comment.id)}>
                                                  <textarea
                                                      value={this.state.editComment}
                                                      onChange={(e => this.setState({editComment: e.target.value}))}
                                                      autoFocus
                                                      className="wireframes-build-add-desc-text"
                                                      style={{margin: "0", height: "100px"}}
                                                  >
                                                  </textarea>
                                                    <button type="submit" className="wireframes-build-add-desc-button ui inverted black button">Update</button>
                                                </form>
                                            :
                                                <div>
                                                <div  className="page-preview-comment">
                                                    {comment.text}
                                                </div>
                                                <div className="preview-comment-functions">
                                                    <div className="preview-comment-functions-item"
                                                         onClick={() => this.handleClick(comment.text, comment.id)}
                                                    >
                                                        Edit
                                                    </div>
                                                    <div
                                                        className="preview-comment-functions-item"
                                                        onClick={() => this.props.deleteComment(pageNameDoc, comment.id, projectId)}
                                                    >
                                                        Delete
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                <form onSubmit={this.handleSubmit} style={{marginTop: "40px "}}>
                                       <textarea
                                           value={this.state.commentText}
                                           onChange={(e => this.setState({commentText: e.target.value}))}
                                           autoFocus
                                           placeholder="Add Comment..."
                                           className="wireframes-build-add-desc-text"
                                           style={{margin: "0", height: "100px"}}
                                       >
                                       </textarea>
                                    <button type="submit" className="wireframes-build-add-desc-button ui inverted black button">Submit</button>
                                </form>
                            </div>



                        </div>
                    </Grid.Column>
                    <Grid.Column width={11} className={"wireframes-build-container-grid"}>
                        <br/>
                        <br/>
                        <br/>
                        <h2 className={"dnd-zone-header"} style={{marginLeft: "7.5%"}}>{project && project.title}</h2>
                        <div className="wireframes-build-dnd">
                            <div className={"dnd-zone"} style={{width: "85%"}}>
                                {pageNameDoc && pageNameDoc.components.map((component,idx) => (
                                    <div  key={idx} className={"dnd-zone-item"}>
                                        {component.image === "https://firebasestorage.googleapis.com/v0/b/david-and-george-cms.appspot.com/o/whitespace%2Fwhite-stripe.png?alt=media&token=95f57a21-8bea-4456-8d26-9a0e5e0aca02" ?
                                            <img className={"dnd-zone-item-image-white"} src={component.image} alt="wireframe"/>
                                            :
                                            <img className={"dnd-zone-item-image"} src={component.image} alt="wireframe"/>
                                        }
                                        <p className={"dnd-zone-item-text"}>{component.note}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default compose(
    withRouter,
    withFirestore,
    firestoreConnect([{collection: "projects"},{collection: "components"}]),
    connect(mapStateToProps, actions)

)(ClientPagePreview);

