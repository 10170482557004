import React, {Component} from 'react';

import "./dashboard-project-new.css"

import {Link} from "react-router-dom";

class DashboardProjectNew extends Component {
    render() {
        return (
            <div className="column four wide">
                <Link to={"/dashboard/projects/create"}>
                    <div className="dashboard-project-card-new-padding">
                        <div className="ui card dashboard-project-card-new-shadow">
                            <div className="content dashboard-project-card-new-card">
                                <div className="dashboard-project-card-new-plus">+</div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default DashboardProjectNew;