import React, { Component } from "react";

import "./user-form.css";

import FormInput from "../../form-components/form-input/form-input";
import FormInputPassword from "../../form-components/form-input-password/form-input-password";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import { createUser, updateUser } from "../../../actions/user-actions";
import { connect } from "react-redux";
import FormHeader from "../../layout-components/form-header/form-header";
import FormSelect from "../../form-components/form-select/form-select";
import { reduxForm, Field } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import { Label } from "semantic-ui-react";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import Loading from "../../loading/loading";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match && ownProps.match.params.id;
  let user = {};
  let header = "New";
  let button = "CREATE";

  if (id) {
    user =
      state.firestore.ordered.users &&
      state.firestore.ordered.users.filter((user) => user.id === id)[0];
    header = "Edit";
    button = "Update";
  }

  return {
    id: id,
    initialValues: user,
    header: header,
    button: button,
  };
};

const actions = {
  createUser,
  updateUser,
};

const roles = [
  { key: "team-member", text: "Team Member", value: "team-member" },
  { key: "client", text: "Client", value: "client" },
  { key: "admin", text: "Admin", value: "admin" },
];

const validate = combineValidators({
  name: isRequired("The name/company"),
  // phone: isRequired("Phone"),
  email: isRequired("Email"),
  password: isRequired("Password"),
  role: isRequired("Role"),
});

class UserForm extends Component {
  onFormSubmit = async (values) => {
    if (this.props.initialValues.id) {
      await this.props.updateUser(values);
    } else {
      const newUser = {
        ...values,
      };
      await this.props.createUser(newUser);
    }

    if (values.role === "client") {
      this.props.history.push(`/dashboard/admin-settings/manage-clients`);
    } else if (values.role === "team-member") {
      this.props.history.push(`/dashboard/admin-settings/manage-team`);
    } else {
      this.props.history.push(`/dashboard/admin-settings`);
    }
  };

  render() {
    const {
      invalid,
      submitting,
      pristine,
      button,
      error,
      header,
      handleSubmit,
      initialValues,
      id,
    } = this.props;

    if (id && !initialValues) {
      return <Loading />;
    }

    return (
      <div className="ui container form-container">
        <FormHeader
          url="/dashboard/admin-settings/manage-clients"
          title={`${header} User`}
        />
        <form
          action=""
          className="ui form"
          autoComplete="off"
          onSubmit={handleSubmit(this.onFormSubmit)}
        >
          <Field
            name="name"
            label="Name"
            component={FormInput}
            placeholder="John Doe"
          />
          <Field
            label="Email"
            name="email"
            type="email"
            component={FormInput}
            placeholder="john.doe@domain.com"
          />
          {/* <Field
            label="Phone"
            name="phone"
            component={FormInput}
            placeholder="123456789"
          /> */}
          <Field
            label="Password"
            name="password"
            placeholder="Password"
            component={FormInputPassword}
          />

          <Field
            placeholder="Permissions"
            name="role"
            options={roles}
            props={{ className: "user-dropdown-field" }}
            component={FormSelect}
          />
          {error && (
            <Label basic color="red">
              {error}
            </Label>
          )}
          <FormSubmitButton
            disabled={invalid || submitting || pristine}
            text={`${button} USER`}
          />
        </form>
      </div>
    );
  }
}

export default compose(
  withRouter,
  firestoreConnect([{ collection: "users" }]),
  connect(mapStateToProps, actions),
  reduxForm({ form: "userForm", validate })
)(UserForm);
