import React, {Component} from 'react';
import {Grid} from "semantic-ui-react";
import "../wireframes/wireframes.css"
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";


const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const projects = state.firestore.ordered.projects;

    const pages = projects && projects.filter(project => project.id === id)[0].pages;


    return  {
        pages,
        id,
    }


};


class Wireframes extends Component {


    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`projects`);
    }

    async componentWillUnmount() {
        const {firestore} = this.props;
        await firestore.unsetListener(`projects`);
    }


    render() {

        return (
            <div className="wireframes-main-background">
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <div className={"wireframes-main-back"}>
                                <Link to={`/client/dashboard`}>
                                    <img src={"/assets/icons/arrow.svg"} style={{height: "34px"}} alt=""/>
                                    <span className={"wireframes-main-back-span"}>Back</span>
                                </Link>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={9}>
                            <div className={"wireframes-main-title"}>
                                <h2>Select page to view notes<br/>& suggested layout<i className="arrow right icon wireframes-main-arrow"></i></h2>
                                <p>
                                    During the wireframing phase, we will work with you to<br/>
                                    create page layouts you're happy with.
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <div className="wireframes-main-list">
                                {this.props.pages && this.props.pages.map((page) => (
                                    <Link
                                        key={page.id}
                                        className="wireframes-main-list-item wireframes-main-list-item-link"
                                        to={`/client/dashboard/preview/${this.props.id}/wireframes/build/page/${page.id}`}
                                    >{page.pageName}
                                    </Link>
                                ))}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withFirestore,
    firestoreConnect([{collection: "projects"}]),
    connect(mapStateToProps, null)

)(Wireframes);