import React, { Component } from "react";

import "./dashboard-profile.css";

import FormInput from "../../form-components/form-input/form-input";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import {
  firestoreConnect,
  withFirebase,
  withFirestore,
} from "react-redux-firebase";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import cuid from "cuid";
import DashboardHeader from "../../layout-components/dashboard-header/dashboard-header";
import { Button, Label } from "semantic-ui-react";
import NewPassword from "../../pages-forms/new-password/new-password";
import { changeProfileInfo } from "../../../actions/auth-actions";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Loading from "../../loading/loading";
import DashboardProjectNew from "../../layout-components/dashboard-project-card-new/dashboard-project-new";

const actions = {
  changeProfileInfo,
};

const mapStateToProps = (state, ownProps) => {
  const user = state.firebase.profile;
  const { name, phone } = user;

  return {
    initialValues: {
      name,
      phone,
    },
  };
};

const validate = combineValidators({
  name: isRequired("name"),
  phone: isRequired("phone"),
});

class DashboardProfile extends Component {
  handleLogout = async () => {
    await this.props.firebase.logout();
    this.props.history.push(`/`);
  };

  onFormSubmit = async (values) => {
    await this.props.changeProfileInfo(values);
    this.props.history.push(`/dashboard/recent`);
  };

  render() {
    const {
      invalid,
      submitting,
      pristine,
      handleSubmit,
      error,
      initialValues,
    } = this.props;

    if (!initialValues.name || !initialValues.phone) {
      return <Loading />;
    }

    console.log(initialValues);

    return (
      <div style={{ margin: "40px auto 20px", maxWidth: "800px" }}>
        {this.props.initialValues.name && (
          <form
            action=""
            className="ui form profile-form"
            autoComplete="off"
            onSubmit={handleSubmit(this.onFormSubmit)}
          >
            <Field
              name="name"
              label="New Name"
              component={FormInput}
              placeholder={this.props.initialValues.name}
            />
            <br />
            <Field
              label=" New Phone"
              name="phone"
              component={FormInput}
              placeholder={this.props.initialValues.phone}
            />
            {error && (
              <Label basic color="red" style={{ marginBottom: "10px" }}>
                {error}
              </Label>
            )}
            <br />
            <FormSubmitButton
              disabled={invalid || submitting || pristine}
              text={`Update Profile`}
            />
          </form>
        )}
        <br />
        <NewPassword />
        <br />
        <div className="dashboard-profile-logout">
          <button
            onClick={this.handleLogout}
            className="ui button fluid profile-button"
          >
            Log Out
          </button>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirebase,
  reduxForm({ form: "profileForm", validate }),
  connect(mapStateToProps, actions)
)(DashboardProfile);
