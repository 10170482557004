import React, {Component} from 'react';

import FormInput from "../../form-components/form-input/form-input";
import FormSelect from "../../form-components/form-select/form-select";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import DropdownExampleMultipleSelection from "../../form-components/form-select-multi/form-select-multi";
import DropdownExampleSearchSelection from "../../form-components/form-select/form-select";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import FormHeader from "../../layout-components/form-header/form-header";
import {createProject, updateProject} from "../../../actions/project-actions";
import {combineValidators, isRequired} from "revalidate";
import {Field,reduxForm} from "redux-form";
import cuid from "cuid";
import {connect} from "react-redux";
import {toCamelCase} from "../../../utils/helpers";
import {firestoreConnect} from "react-redux-firebase";
import {compose} from "redux";
import Loading from "../../loading/loading";
import {withRouter} from "react-router-dom"

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match && ownProps.match.params.id;
    let project = {};
    let header = "New";
    let button =  "Create";

    if(id) {
        project = state.firestore.ordered.projects && state.firestore.ordered.projects.filter(project => project.id === id)[0];
        header = "Edit";
        button = "Update";
    }



    return {
        id: id,
        initialValues: project,
        header: header,
        button: button,
    }
};

const actions = {
    createProject,
    updateProject,
};



const validate = combineValidators({
    title: isRequired("Title"),
    name: isRequired("Name"),

});

class ProjectForm extends Component {

    onFormSubmit = async values => {
        if(this.props.initialValues.id) {
            await this.props.updateProject(values);
        } else  {
            const newProject = {
                ...values,
            };
            await this.props.createProject(newProject);
        }

        this.props.history.push(`/dashboard/new-enquiries`);
    };


    render() {
        const {invalid, submitting, pristine, button, header, handleSubmit} = this.props;
        const {initialValues, employees, clients, services, packages, id} = this.props;


        if(id && !initialValues) {
            return <Loading/>
        }

        return (
            <div className="ui container form-container">
                <FormHeader url="/dashboard/recent" title={`${header} Project`}/>

                <form action="" className="ui form" autoComplete="off" onSubmit={handleSubmit(this.onFormSubmit)}>
                    <Field
                        label="Title"
                        name="title"
                        component={FormInput}
                        placeholder="Project's name"
                    />
                    <Field
                        label="Who is preparing the presentation?"
                        name="name"
                        component={FormInput}
                        placeholder="Name"
                    />
                    {/*<Field*/}
                    {/*    placeholder="Employees"*/}
                    {/*    multiple={true}*/}
                    {/*    name="employees"*/}
                    {/*    options={employees || []}*/}
                    {/*    label="Employees"*/}
                    {/*    component={FormSelect}*/}
                    {/*/>*/}
                    {/*<Field*/}
                    {/*    label="Select Client"*/}
                    {/*    placeholder="Clients"*/}
                    {/*    name="client"*/}
                    {/*    options={clients || []}*/}
                    {/*    component={FormSelect}*/}
                    {/*    multiple={false}*/}
                    {/*/>*/}
                    <FormSubmitButton
                        disabled={invalid || submitting || pristine}
                        text={`${button} Project`}
                    />
                </form>
            </div>
        );
    }
}


export default compose(
    withRouter,
    firestoreConnect([{collection: 'pricing'},{collection: 'projects'}, {collection: 'users'}]),
    connect(mapStateToProps,actions),
    reduxForm({form: 'projectForm', validate}),
)(ProjectForm)


