import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import {reducer as toastrReducer} from "react-redux-toastr";
import userReducer from "../reducers/user-reducer";
import pricingReducer from "../reducers/pricing-reducer";
import projectReducer from "../reducers/project-reducer";
import {firebaseReducer} from "react-redux-firebase";
import {firestoreReducer} from "redux-firestore";



const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    form: formReducer,
    toastr: toastrReducer,

});

export default rootReducer;
