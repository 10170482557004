import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import {
  addProjectComment,
  changeField,
  deleteProjectComment,
  updateProjectComment,
  manageLinks,
} from "../../../actions/project-actions";
import ProjectOverviewHeader from "./ProjectOverviewHeader";
import ProjectOverviewLinks from "./project-overview-links";
import Loading from "../../loading/loading";
import firebase from "../../../firebase";
import ProjectOverviewDate from "./project-overview-date";
// import ProjectOverviewJourneyBuilder from "./project-overview-journey-builder";
import ProjectOverviewPackages from "./project-overview-packages";
import ProjectOverviewComment from "../../layout-components/project-overview-comment/project-overview-comment";
import ProjectOverviewComments from "./project-overview-comments";
import ProjectOverviewResources from "./ProjectOverviewResources";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const project =
    state.firestore.ordered.projects &&
    state.firestore.ordered.projects.filter((project) => project.id === id)[0];
  const client =
    state.firestore.ordered.users &&
    state.firestore.ordered.users.filter(
      (user) =>
        user.role === "client" && user.projects && user.projects.includes(id)
    )[0];

  const services =
    state.firestore.ordered.pricing &&
    state.firestore.ordered.pricing.filter(
      (pricing) => pricing.type === "service"
    );

  const packages =
    state.firestore.ordered.pricing &&
    state.firestore.ordered.pricing.filter(
      (pricing) => pricing.type === "package"
    );

  return {
    services: services || [],
    packages: packages || [],
    project: project || null,
    projectId: id,
    client: client || "",
    auth: state.firebase.auth,
  };
};

const actions = {
  changeField,
  addProjectComment,
  updateProjectComment,
  deleteProjectComment,
  manageLinks,
};

class ProjectOverviewDashboard extends Component {
  state = {
    links: [],
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);

    const db = firebase.firestore();

    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const projectSnap = await projectQuery.get();
    const links = projectSnap.data().links;

    await this.setState({
      links: links,
    });
  }

  render() {
    if (
      !this.props.project ||
      this.props.services.length === 0 ||
      this.props.packages.length === 0
    ) {
      return <Loading />;
    }

    return (
      <div className={"p-o-content"}>
        <ProjectOverviewHeader
          project={this.props.project}
          client={this.props.client}
        />
        {/* <ProjectOverviewJourneyBuilder project={this.props.project} client={false}/> */}
        <ProjectOverviewLinks project={this.state.links} id={this.props.id} />
        <ProjectOverviewDate client={false} />
        <ProjectOverviewPackages
          client={false}
          project={this.props.project}
          services={this.props.services}
          packages={this.props.packages}
        />
        <ProjectOverviewResources
          project={this.state.links}
          id={this.props.id}
        />
        <ProjectOverviewComments project={this.props.project} />
      </div>
    );
  }
}

export default compose(
  firestoreConnect([
    { collection: "projects" },
    { collection: "users" },
    { collection: "pricing" },
  ]),
  withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "componentForm" })
)(ProjectOverviewDashboard);
