import React, {Component} from 'react';

class ProjectOverviewJourneyBuilder extends Component {




    render() {

        const {project, client} = this.props;

        let toDisplay = [];


            if(!client) {
                if(project.tJourney) {
                    if(project.tJourney[0].status === "active") {
                        toDisplay = project.tJourney.slice(0,3);
                    } else {
                        const idx = project.tJourney.map(c => c.status).indexOf("active");

                        toDisplay = project.tJourney.filter((c,i) => {
                            return i === idx || i === (idx - 1) || i === (idx + 1);

                        })
                    }
                }


            } else {
                if(project.cJourney) {
                    if(project.cJourney[0].status === "active") {
                        toDisplay = project.cJourney.slice(0,3);
                    } else {
                        const idx = project.cJourney.map(c => c.status).indexOf("active");

                        toDisplay = project.cJourney.filter((c,i) => {
                            return i === idx || i === (idx - 1) || i === (idx + 1);

                        })
                    }

                }
            }


        return (
            <div className="project-overview-section">
                <div className="project-overview-section-header">
                    <div style={{marginRight: "8vw"}}>
                        <h2>Project journey</h2>
                        <p>Where your project is and where it's going next. Keep checking back here to track our progress and stay updated on any action required from you.</p>
                    </div>

                </div>
                <div className="project-overview-section-content">
                    {!client ?
                        <div className="project-overview-section-content-list">
                            {project.tJourney && toDisplay.map(c => (
                                <div className={`project-overview-section-content-item ${c.status === "active" && "posci-active"}`}>
                                    <div>
                                        <h4>{c.heading}</h4>
                                        <p>{c.body}</p>
                                    </div>

                                </div>
                            ))}

                        </div>
                        :
                        <div className="project-overview-section-content-list">
                            {project.cJourney && toDisplay.map(c => (
                                <div className={`project-overview-section-content-item ${c.status === "active" && "posci-active"}`}>
                                    <div>
                                        <h4>{c.heading}</h4>
                                        <p>{c.body}</p>
                                    </div>

                                </div>
                            ))}

                        </div>

                    }

                </div>
            </div>
        );
    }
}

export default ProjectOverviewJourneyBuilder;