import React from 'react';
import {useForm, Controller} from "react-hook-form";
import {Button, Form, Select} from "semantic-ui-react";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import SelectHook from "./select-hook";
import {connect} from "react-redux";
import {updateProject, addProjectJourney} from "../../../actions/project-actions";


const ProposalJourneyForm = ({project, updateProject, journies, addProjectJourney}) => {
    const { register, control, handleSubmit, setValue, getValues } = useForm({
        defaultValues: {
            journeyId: project.journeyId || "",
        }
    });

    const onSubmit = async data => {
        if((project.journeyId || "") !== data.journeyId) {
            const updatedProject = {...project, ...data};
            await updateProject(updatedProject);
            addProjectJourney(updatedProject, journies.find(j => j.id === data.journeyId));
        }


    };


    const optionJournies = journies.map(j => ({value: j.id, key: j.id, text: j.title}));


    return (
        <div className="proposal-form-border">
            <h4 className="project-proposal-section-header">Project Journey</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Field>
                    <label>Project Journey</label>
                    <Controller
                        placeholder="Project Journey"
                        control={control}
                        name="journeyId"
                        as={SelectHook}
                        search={true}
                        setValue={setValue}
                        value={getValues("journeyId")}
                        options={optionJournies}/>
                </Form.Field>
                <br/>
                <Button type='submit' className="fluid">Submit</Button>
            </Form>
        </div>
    );
};

export default connect(null, {updateProject, addProjectJourney})(ProposalJourneyForm);