import React from "react";

const ProjectOverviewHeader = ({ project, client }) => {
  return (
    <div className="project-overview-dasboard-header">
      <div className="project-overview-dasboard-header-titles">
        <h2>{project.title}</h2>
        <h2 style={{ fontSize: "2.1em" }}>PROJECT DASHBOARD</h2>
      </div>
      <div className="project-overview-dasboard-header-contact">
        <p>{client.name}</p>
        <p>{client.email}</p>
        <p>{client.phone}</p>
      </div>
    </div>
  );
};

export default ProjectOverviewHeader;
