import React, { Component } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import {
  addProjectComment,
  changeField,
  deleteProjectComment,
  updateProjectComment,
  manageLinks,
} from "../../../actions/project-actions";
import ProjectOverviewHeader from "../project-overview/ProjectOverviewHeader";
import Loading from "../../loading/loading";
import firebase from "../../../firebase";
import ProjectOverviewDate from "../project-overview/project-overview-date";
import ProjectOverviewJourneyBuilder from "../project-overview/project-overview-journey-builder";
import ProjectOverviewPackages from "../project-overview/project-overview-packages";
import ProjectOverviewresources from "../project-overview/ProjectOverviewResources";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const project =
    state.firestore.ordered.projects &&
    state.firestore.ordered.projects.filter((project) => project.id === id)[0];
  const client =
    state.firestore.ordered.users &&
    state.firestore.ordered.users.filter(
      (user) =>
        user.role === "client" && user.projects && user.projects.includes(id)
    )[0];

  const services =
    state.firestore.ordered.pricing &&
    state.firestore.ordered.pricing.filter(
      (pricing) => pricing.type === "service"
    );

  const packages =
    state.firestore.ordered.pricing &&
    state.firestore.ordered.pricing.filter(
      (pricing) => pricing.type === "package"
    );

  return {
    services,
    packages,
    project: project || null,
    projectId: id,
    client: client || "",
    auth: state.firebase.auth,
  };
};

class ProjectOverviewDashboardClient extends Component {
  render() {
    if (
      !this.props.project ||
      !this.props.client ||
      !this.props.services ||
      !this.props.packages
    ) {
      return <Loading />;
    }

    return (
      <div className={"p-o-content"}>
        <ProjectOverviewHeader
          project={this.props.project}
          client={this.props.client}
        />
        {/* <ProjectOverviewJourneyBuilder client={true} project={this.props.project}/>  //This is the Project Journey*/}
        <ProjectOverviewDate client={true} />
        <ProjectOverviewPackages
          client={true}
          project={this.props.project}
          services={this.props.services}
          packages={this.props.packages}
        />
        <ProjectOverviewresources client={true} />
      </div>
    );
  }
}

export default compose(
  firestoreConnect([
    { collection: "projects" },
    { collection: "users" },
    { collection: "pricing" },
  ]),
  withRouter,
  connect(mapStateToProps, null),
  reduxForm({ form: "componentForm" })
)(ProjectOverviewDashboardClient);
