import React, {Component} from 'react';
import "./admin-settings.css";
import {Link} from "react-router-dom";

class AdminSettingsTablePricing extends Component {

    render() {
        const { pricing, deletePricing} = this.props;
        const pricingList = pricing.map(price => (
                <tr key={price.id}>
                    <td>{price.title}</td>
                    <td>£{price.price}</td>
                    <td>
                        <Link to={`/dashboard/pricing/edit/${price.id}`} className={"admin-settings-table-edit"}>Edit</Link>
                        <span onClick={() => deletePricing(price.id, price.type)} className={"admin-settings-table-del"}>Delete</span>
                    </td>
                </tr>
            ));
        return (
            <div>
                <table className={"admin-settings-table"}>
                    <tbody>
                    {pricingList}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AdminSettingsTablePricing;