import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import {
  updatePageName,
  updateComponentNote,
} from "../../../actions/pages-actions";
import "./project-overview.css";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import BuildMainMenu from "../../layout-components/build-main-menu/build-main-menu";
import BuildSecondMenu from "../../layout-components/build-second-menu/build-second-menu";
import firebase from "../../../firebase";
import cuid from "cuid";
import DragAndDropZone from "../../drag-and-drop-zone/dndzone";
import {
  updateComponents,
  deleteComponents,
} from "../../../actions/wireframes-actions";
import { deletePage } from "../../../actions/pages-actions";
import BuildAddDesc from "../../layout-components/build-add-description/build-add-desc";
import { insertComment } from "../../../actions/pages-actions";
import UploadCustom from "../../layout-components/upload-custom/upload-custom";
import { uploadCustom } from "../../../actions/wireframes-actions";
import AddPremade from "../../layout-components/add-premade/add-premade";
import UploadPremade from "../../layout-components/upload-premade/upload-premade";

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.id;
  const pageId = ownProps.match.params.idp;

  const projects = state.firestore.ordered.projects;
  const components = state.firestore.ordered.components;
  const premadePages = state.firestore.ordered.premade_pages;

  const defaultComponents =
    components &&
    components.filter((component) => component.type === "default");
  const project =
    projects && projects.filter((project) => project.id === projectId)[0];
  const pageNameDoc =
    projects &&
    project &&
    project.pages.filter((page) => page.id === pageId)[0];
  const pageName = pageNameDoc && pageNameDoc.pageName;
  let categories = [];
  const categoryOrder = [
    "Headings",
    "Text",
    "Lists & Menus",
    "Quotes",
    "Banners",
    "Images",
    "Videos",
    "Galleries",
    "Logos",
    "Clickthrough Images",
    "Buttons & CTAs",
    "Lines & Dividers",
    "Actions",
    "Social Media & Integrations",
    "Blog, Events & Podcast",
    "Ecommerce",
    "Navigation",
    "Footers",
    "Premium",
    "Misc",
  ];

  defaultComponents &&
    defaultComponents.map((component) => {
      categories.push(component.category);
    });
  categories = [...new Set(categories)];

  categories.sort((a, b) => {
    const aIndex = categoryOrder.indexOf(a);
    const bIndex = categoryOrder.indexOf(b);
    return aIndex === bIndex ? 0 : aIndex < bIndex ? -1 : 1;
  });

  return {
    premadePages,
    pageName,
    pageNameDoc,
    projectId,
    pageId,
    defaultComponents,
    project,
    categories,
  };
};

const actions = {
  updatePageName,
  updateComponents,
  deletePage,
  deleteComponents,
  insertComment,
  uploadCustom,
  updateComponentNote,
};

class PageSketechesEditor extends Component {
  state = {
    showForm: false,
    showFormDesc: false,
    formName: "",
    componentsToDisplay: [],
    componentsBackup: [],
    requestName: "",
    description: "",
    comment: "",
    modalOpen: false,
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);

    const db = firebase.firestore();

    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const projectSnap = await projectQuery.get();
    const pages = projectSnap.data().pages;
    const page = pages.filter(
      (page) => page.id === this.props.match.params.idp
    )[0];

    await this.setState({
      componentsToDisplay: page.components,
      description: page.description,
      formName: page.pageName,
    });
  }

  addWhiteSpace = async () => {
    const newComponent = {
      image:
        "https://firebasestorage.googleapis.com/v0/b/david-and-george-cms.appspot.com/o/whitespace%2Fwhite-stripe.png?alt=media&token=95f57a21-8bea-4456-8d26-9a0e5e0aca02",
      note: "White space",
      id: cuid(),
      orderId: cuid(),
    };

    await this.setState((prevState) => ({
      componentsToDisplay: [...prevState.componentsToDisplay, newComponent],
    }));

    this.props.updateComponents(
      this.props.match.params.id,
      this.props.match.params.idp,
      this.state.componentsToDisplay
    );
  };

  insertCommentSubmit = async () => {
    const newComponent = {
      image: "none",
      note: "none",
      text: this.state.comment,
      id: cuid(),
      orderId: cuid(),
    };

    await this.setState((prevState) => ({
      componentsToDisplay: [...prevState.componentsToDisplay, newComponent],
      comment: "",
    }));

    this.props.updateComponents(
      this.props.match.params.id,
      this.props.match.params.idp,
      this.state.componentsToDisplay
    );
  };

  handleChangeComment = async (e) => {
    await this.setState({ comment: e.target.value });
  };

  handleClick = async () => {
    await this.setState({ showForm: true });
    await this.setState({ formName: this.props.pageName });
  };

  handleChange = async (e) => {
    await this.setState({ formName: e.target.value });
  };

  reloadComponents = async (components) => {
    this.setState({ componentsToDisplay: components });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.formName.length === 0) {
      return;
    }
    await this.props.updatePageName(
      this.props.pageNameDoc,
      this.state.formName,
      this.props.projectId
    );
    await this.setState({ showForm: false });
  };

  handleSubmitDesc = async () => {
    this.props.insertComment(
      this.props.pageNameDoc,
      this.state.description,
      this.props.projectId
    );
    this.setState({
      showFormDesc: false,
    });
  };

  handleChangeDesc = async (e) => {
    await this.setState({ description: e.target.value });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  uploadCustomComponent = async (image) => {
    const imageLink = await this.props.uploadCustom(image);

    const newComponent = {
      image: imageLink,
      note: "Custom Component",
      url: "",
      id: cuid(),
      orderId: cuid(),
    };

    await this.setState((prevState) => ({
      componentsToDisplay: [...prevState.componentsToDisplay, newComponent],
    }));
    this.props.updateComponents(
      this.props.match.params.id,
      this.props.match.params.idp,
      this.state.componentsToDisplay
    );
  };

  async componentWillUnmount() {
    const { firestore } = this.props;
    await firestore.unsetListener(`projects`);
  }

  handleCategoryClick = async (category) => {
    await this.setState({ requestName: category });
  };

  addPremade = async (page) => {
    await this.setState((prevState) => ({
      componentsToDisplay: [...page.components],
      componentsBackup: [...prevState.componentsToDisplay],
      modalOpen: false,
      requestName: "pre-made",
    }));
    //this.props.updateComponents(this.props.match.params.id, this.props.match.params.idp, this.state.componentsToDisplay);
  };

  cancelPremade = async () => {
    await this.setState((prevState) => ({
      componentsToDisplay: [...prevState.componentsBackup],
      componentsBackup: [],
      requestName: "",
    }));
  };

  uploadPremade = async () => {
    await this.setState((prevState) => ({
      componentsBackup: [],
      requestName: "",
    }));
    this.props.updateComponents(
      this.props.match.params.id,
      this.props.match.params.idp,
      this.state.componentsToDisplay
    );
  };

  handleBack = async () => {
    await this.setState({ requestName: "" });
  };

  handleDrop = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const oldComponentsOrder = [...this.state.componentsToDisplay];
    const draggedComponent = oldComponentsOrder.filter(
      (component) => component.orderId === draggableId
    )[0];
    oldComponentsOrder.splice(source.index, 1);
    oldComponentsOrder.splice(destination.index, 0, draggedComponent);

    await this.setState({ componentsToDisplay: oldComponentsOrder });
    await this.props.updateComponents(
      this.props.match.params.id,
      this.props.match.params.idp,
      oldComponentsOrder
    );
  };

  deletePage = async () => {
    await this.props.deletePage(this.props.pageNameDoc, this.props.projectId);
    this.props.history.push(
      `/dashboard/project-overview/${this.props.projectId}/page-sketches`
    );
  };

  deleteComponents = async () => {
    await this.props.deleteComponents(this.props.projectId, this.props.pageId);
    await this.setState({ componentsToDisplay: [] });
  };

  handleDeleteComp = async (component) => {
    const index = this.state.componentsToDisplay.indexOf(component);
    const oldComponentsOrder = [...this.state.componentsToDisplay];
    oldComponentsOrder.splice(index, 1);
    await this.setState({ componentsToDisplay: oldComponentsOrder });
    await this.props.updateComponents(
      this.props.match.params.id,
      this.props.match.params.idp,
      oldComponentsOrder
    );
  };

  handlePadding = async (comp, padding) => {
    const newComponent = {
      ...comp,
      padding,
    };

    const newComponents = this.state.componentsToDisplay.map((c) =>
      c.orderId === newComponent.orderId ? newComponent : c
    );

    await this.setState((prevState) => ({
      componentsToDisplay: newComponents,
    }));
    this.props.updateComponents(
      this.props.match.params.id,
      this.props.match.params.idp,
      newComponents
    );
  };

  isComponentDarkMode = (component) => {
    const componentTitle = component.title;
    const darkBackground = "#5e605f";
    const darkComponent = componentTitle.endsWith("W");

    return darkComponent;
  };

  handleBackground = async (comp, background) => {
    const newComponent = {
      ...comp,
      background,
    };

    const newComponents = this.state.componentsToDisplay.map((c) =>
      c.orderId === newComponent.orderId ? newComponent : c
    );
    console.log(`HBG newcomponents : ${newComponents}`);

    await this.setState((prevState) => ({
      componentsToDisplay: newComponents,
    }));
    this.props.updateComponents(
      this.props.match.params.id,
      this.props.match.params.idp,
      newComponents
    );
  };

  handleComponent = async (component) => {
    const { image, note, id, url, title, background } = component;

    const newComponent = {
      background: background || "#ffffff",
      image,
      note: note || "",
      id,
      url: url || "",
      orderId: cuid(),
      title: title || "",
    };

    if (this.isComponentDarkMode(newComponent)) {
      newComponent.background = "#5e605f";
    }
    await this.setState((prevState) => ({
      componentsToDisplay: [...prevState.componentsToDisplay, newComponent],
    }));
    this.props.updateComponents(
      this.props.match.params.id,
      this.props.match.params.idp,
      this.state.componentsToDisplay
    );

    // await this.setState((prevState) => ({
    //   componentsToDisplay: [...prevState.componentsToDisplay, newComponent],
    // }));
    // this.props.updateComponents(
    //   this.props.match.params.id,
    //   this.props.match.params.idp,
    //   this.state.componentsToDisplay
    // );
  };

  render() {
    const {
      projectId,
      project,
      defaultComponents,
      categories,
      pageNameDoc,
      pageId,
    } = this.props;
    const { showForm, formText, requestName } = this.state;

    return (
      <div className="page-sketches-main">
        <div className="page-sketches-left">
          <div className="pages-sketches-left-page">
            <div className="pages-sketches-left-page-header">
              {this.state.showForm ? (
                <form
                  className={"page-sketches-editor-form"}
                  action=""
                  onSubmit={this.handleSubmit}
                >
                  <input
                    value={this.state.formName}
                    autoFocus
                    type="text"
                    onChange={this.handleChange}
                    placeholder="Page Name..."
                  />
                </form>
              ) : (
                <h2 onClick={this.handleClick}>{this.state.formName}</h2>
              )}
              {this.state.showFormDesc ? (
                <form
                  className={"pages-sketches-left-page-header-form"}
                  onSubmit={this.handleSubmitDesc}
                >
                  <textarea
                    autoFocus
                    value={this.state.description}
                    onChange={this.handleChangeDesc}
                    placeholder="Add description..."
                    className=""
                  ></textarea>
                  <button type="submit" className="ui inverted black button">
                    Submit
                  </button>
                </form>
              ) : (
                <p onClick={() => this.setState({ showFormDesc: true })}>
                  {(pageNameDoc && pageNameDoc.description) ||
                    "Write a description..."}
                </p>
              )}

              <div className="pages-sketches-left-page-header-func">
                <div onClick={this.deleteComponents}>Clear Page</div>
                <div onClick={this.deletePage}>Delete Page</div>
                {/*<div><Link to={`/dashboard/project-overview/${this.props.match.params.id}/page-sketches`}>All Pages</Link></div>*/}
              </div>
            </div>
            <div className="pages-sketches-left-page-images">
              <DragAndDropZone
                components={this.state.componentsToDisplay}
                projectId={projectId}
                handleDrop={this.handleDrop}
                page={project && pageNameDoc}
                updateComponentNote={this.props.updateComponentNote}
                reloadComponents={this.reloadComponents}
                handleDeleteComp={this.handleDeleteComp}
                handlePadding={this.handlePadding}
                disableForm={false}
                handleBackground={this.handleBackground}
              />
            </div>
          </div>
        </div>
        <div className="page-sketches-right">
          <AddPremade
            open={this.state.modalOpen}
            handleClose={this.handleModalClose}
            handleBack={this.handleBack}
            handlePremade={this.addPremade}
            premadePages={this.props.premadePages}
          />
          {requestName.length === 0 && (
            <BuildMainMenu
              project={project}
              categories={categories}
              categoryClick={this.handleCategoryClick}
              addWhiteSpace={this.addWhiteSpace}
              handleModalOpen={this.handleModalOpen}
              projectId={projectId}
              pageId={pageId}
            />
          )}
          {requestName === "add-detail" && (
            <BuildAddDesc
              handleBack={this.handleBack}
              handleSubmitDesc={this.insertCommentSubmit}
              description={this.state.comment}
              handleChangeDesc={this.handleChangeComment}
            />
          )}
          {requestName === "pre-made" && (
            <UploadPremade
              handleBack={this.handleBack}
              handleCancel={this.cancelPremade}
              handleUpload={this.uploadPremade}
            />
          )}
          {requestName === "upload-custom" && (
            <UploadCustom
              handleBack={this.handleBack}
              uploadCustomComponent={this.uploadCustomComponent}
            />
          )}
          {requestName.length !== 0 &&
            requestName !== "add-detail" &&
            requestName !== "upload-custom" &&
            requestName !== "pre-made" && (
              <BuildSecondMenu
                handleBack={this.handleBack}
                handleComponent={this.handleComponent}
                category={requestName}
                defaultComponents={defaultComponents}
              />
            )}
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirestore,
  firestoreConnect([
    { collection: "projects" },
    { collection: "components" },
    { collection: "premade_pages" },
  ]),
  connect(mapStateToProps, actions)
)(PageSketechesEditor);
