import React, {Component, Fragment} from 'react';

import "./client-dashboard.css"
import {connect} from "react-redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {withRouter} from "react-router-dom"
import {compose} from "redux";
import Loading from "../../loading/loading";
import ClientDashboardForm from "../../layout-components/client-dashboard-form/client-dashboard-form";
import {changeProfileInfo} from "../../../actions/auth-actions";
import {Link} from "react-router-dom";
import NoMatch from "../no-match/no-match";

const mapStateToProps = (state, ownProps) => {


    const profile = state.firebase.profile;
    const firstName = profile.name && profile.name.split(" ")[0];
    const name =  profile.name && profile.name;
    const phone = profile.phone && profile.phone;
    const project = state.firestore.ordered.projects && state.firestore.ordered.projects.filter( project =>{
        return project.client === ownProps.clientId;
    })[0];
    const initialValues = {
        phone,
        name
    };

    return {
        project,
        initialValues,
        profile,
        firstName,
    }
};

const actions = {
    changeProfileInfo,
};



class ClientDashboard extends Component {

    state = {
        home: true
    };

    handleLogout = async () => {
        await this.props.firebase.logout();
        this.props.history.push(`/`);
    };

    handleToggle = async () => {
        await this.setState(prevState => ({home: !prevState.home}));
    };

    render() {

        if (!this.props.profile.isLoaded) {
            return <Loading />
        }


        return (
            <div className="client-dashboard">
                <div className="client-dashboard-nav">
                    <div className="client-dashboard-nav-header">
                        David
                        <span>&</span>
                        George
                    </div>
                    <div className="client-dashboard-nav-header-menu">
                        <div className="client-dashboard-nav-header-menu-item"
                             onClick={this.handleToggle}
                        >
                            {this.state.home? "Manage Account" : "Back to Dashboard"}
                        </div>
                        <div className="client-dashboard-nav-header-menu-item" onClick={this.handleLogout}>Logout</div>
                    </div>
                </div>
                {this.props.project && this.props.project.url && this.props.project.url.length > 0 ?
                <div className="client-dashboard-line">
                    <a href={this.props.project.url} target='_blank'><div>View website work in progress <i className="arrow right icon"></i></div></a>
                </div>
                    :
                    <hr className="client-dashboard-line-hr"/>
                }
                {this.state.home ?
                    <Fragment>
                        <div className="client-dashboard-main">
                            <div className="client-dashboard-main-title">
                                {`Hi ${this.props.profile.isLoaded && this.props.firstName}, welcome to your project Dashboard`}
                            </div>
                            {this.props.project ?
                            <div className="client-dashboard-main-options">
                                <img src={"/assets/icons/ProposalClient.png"} className="client-dashboard-main-options-img" alt=""/>
                                <Link to={`/client/dashboard/preview/${this.props.project && this.props.project.id}`}>
                                    <img src={"/assets/icons/WireframesClient.png"} className="client-dashboard-main-options-img" alt=""/>
                                </Link>
                                <Link to={`/client/dashboard/content/${this.props.project && this.props.project.id}`}>
                                    <img src={"/assets/icons/ContentClient.png"} className="client-dashboard-main-options-img" alt=""/>
                                </Link>
                                <Link to={`/client/dashboard/tasks/${this.props.project && this.props.project.id}`}>
                                    <img src={"/assets/icons/ContentClient.png"} className="client-dashboard-main-options-img" alt=""/>
                                </Link>
                            </div>
                                :
                                <div style={{textAlign: "center", fontSize: "26px", opacity: "0.5"}}>
                                    Your project will be ready soon . . .
                                </div>
                            }
                        </div>
                        <div className="client-dashboard-data">
                            <div className="client-dashboard-data-column">
                                <div className="client-dashboard-data-column-header">New business enquires</div>
                                <div className="client-dashboard-data-column-item">David</div>
                                <div className="client-dashboard-data-column-item">07914244865</div>
                                <div className="client-dashboard-data-column-item">talk@davidandgeorge.co.uk</div>
                            </div>
                            <div className="client-dashboard-data-column">
                                <div className="client-dashboard-data-column-header">Production enquires</div>
                                <div className="client-dashboard-data-column-item">George & Team</div>
                                <div className="client-dashboard-data-column-item">07914244865</div>
                                <div className="client-dashboard-data-column-item">build@davidandgeorge.co.uk</div>
                            </div>
                            <div className="client-dashboard-data-column-crossroad">
                                <img src="/assets/icons/Crossroad.png" alt=""/>
                                <div className="client-dashboard-data-column-item">Check out our guides<br/>
                                    and tutorials <i className="arrow right icon"></i>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    :
                    <ClientDashboardForm
                        initialValues={this.props.initialValues}
                        changeProfileInfo={this.props.changeProfileInfo}
                        handleToggle={this.handleToggle}
                    />
                    }
            </div>
        );
    }
}

export default compose(
    withRouter,
    withFirestore,
    firestoreConnect([{collection: "projects"}]),
    connect(mapStateToProps, actions)
)(ClientDashboard);