import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import "./client-tasks.css";
import {compose} from "redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";
import cuid from "cuid";
import {manageTasks, uploadFile, deleteFile, manageTaskComments} from "../../../actions/tasks-actions";
import Loading from "../../loading/loading";
import firebase from "../../../firebase";


const actions = {
    manageTasks,
    uploadFile,
    deleteFile,
    manageTaskComments,
};

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const profile = state.firebase.profile;

    return {
        id,
        profile
    }
};

class ClientTasks extends Component {


    state = {
        tasksToDisplay : [],
        taskCommentsToDisplay: [],
        task: {
            text: "",
            files: [],
            finished: false,
            id: "",
            author: "",
        },
        taskComment: {
            text: "",
            files: [],
            id: "",
            author: "",
            taskId: "",
        },
        showId: "",
        editTask: {id: null},
    };

    downloadImage = (imgUrl, imgName) => {
        var storage = firebase.storage();
        var storageRef = storage.refFromURL(imgUrl);
        storageRef.getDownloadURL().then(function (url) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function (event) {
                var blob = xhr.response;
                let a = document.createElement("a");
                a.style = "display: none";
                document.body.appendChild(a);
                //Create a DOMString representing the blob
                //and point the link element towards it
                let urlNew = window.URL.createObjectURL(blob);
                a.href = urlNew;
                a.setAttribute("id", "tempDownloadFile")
                a.download = imgName;
                a.click();
                let element = document.getElementById("tempDownloadFile");
                element.parentNode.removeChild(element);
                window.URL.revokeObjectURL(url);
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch(function (error) {
            console.log(error)
        });
    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`projects`);
        const db = firebase.firestore();
        const projectQuery = await db.collection("projects").doc(this.props.match.params.id);
        const form = await projectQuery.get();
        const formComponents = form.data().tasks;
        await this.setState({
            tasksToDisplay: formComponents || []
        });
        const projectQuery2 = await db.collection("projects").doc(this.props.match.params.id);
        const tasks = await projectQuery2.get();
        const taskComments = tasks.data().taskComments;
        await this.setState({
            taskCommentsToDisplay: taskComments || []
        });

    }

    async componentWillUnmount() {
        const {firestore} = this.props;
        await firestore.unsetListener(`projects`);
    }


    handleChangeAddTask = (e) => {
        e.persist();
        this.setState(prevState => ({
            task: {
                ...prevState.task,
                text: e.target.value,
            }
        }))
    };

    handleChangeAddComment = async (e,id) => {
        e.persist();

        if(this.state.taskComment.taskId !== id) {
            await this.setState(prevState => ({
                taskComment: {
                    text: "",
                    files: [],
                    id: "",
                    author: "",
                    taskId: "",
                },
            }))
        }


        if (!this.state.taskComment.id) {
            await this.setState(prevState => ({
                taskComment: {
                    ...prevState.taskComment,
                    id: cuid(),
                }
            }))
        }

        if (!this.state.taskComment.taskId) {
            await this.setState(prevState => ({
                taskComment: {
                    ...prevState.taskComment,
                    taskId: id,
                }
            }))
        }
        await this.setState(prevState => ({
           taskComment : {
                ...prevState.taskComment,
                text: e.target.value,
            }
        }))
    };

    handleFileAddComment = async (e,id) => {
        e.persist();
        console.log(this.state.taskComment.id);
        const file = e.target.files[0];
        console.log(!this.state.taskComment.id);
        if (!this.state.taskComment.id) {
            await this.setState(prevState => ({
                taskComment: {
                    ...prevState.taskComment,
                    id: cuid(),
                }
            }))
        }
        console.log(this.state.taskComment.id);
        console.log(!this.state.taskComment.id);
        if (!this.state.taskComment.taskId) {
            await this.setState(prevState => ({
                taskComment: {
                    ...prevState.taskComment,
                    taskId: id,
                }
            }))
        }
        const fileURL = await this.props.uploadFile(file, this.state.taskComment.id);
        const fileObj = {
            id: cuid(),
            name: file.name,
            url: fileURL
        };
        await this.setState(prevState => ({
            taskComment: {
                ...prevState.taskComment,
                files: [...prevState.taskComment.files, fileObj],
            }
        }));
    };

    handleAddTaskComment = async (e,id) => {
        e.preventDefault();
        const newTaskComment = {
            text: this.state.taskComment.text,
            files: this.state.taskComment.files,
            id: this.state.taskComment.id || cuid(),
            author: this.props.profile.name,
            taskId: id
        };

        await this.props.manageTaskComments([...this.state.taskCommentsToDisplay, newTaskComment], this.props.id);
        await this.setState(prevState => ({
            taskCommentsToDisplay: [...prevState.taskCommentsToDisplay, newTaskComment],
            taskComment: {
                text: "",
                files: [],
                id: "",
                author: "",
                taskId: "",
            }
        }));

    };



    handleFileAddTask = async (e) => {
        e.persist();
        const file = e.target.files[0];
        if (!this.state.task.id) {
            await this.setState(prevState => ({
                task: {
                    ...prevState.task,
                    id: cuid(),
                }
            }))
        }
        const fileURL = await this.props.uploadFile(file, this.state.task.id);
        const fileObj = {
            id: cuid(),
            name: file.name,
            url: fileURL
        };
        await this.setState(prevState => ({
            task: {
                ...prevState.task,
                files: [...prevState.task.files, fileObj],
            }
        }))
    };

    handleAddTask = async (e) => {
        e.preventDefault();
        const newTask = {
            text: this.state.task.text,
            files: this.state.task.files,
            id: this.state.task.id || cuid(),
            author: this.props.profile.name,
            finished: false,
        };


        await this.props.manageTasks([...this.state.tasksToDisplay, newTask], this.props.id);

        await this.setState(prevState => ({
            tasksToDisplay: [...prevState.tasksToDisplay, newTask],
            task: {
                text: "",
                files: [],
                finished: false,
                id: "",
                author: "",
            },
        }));
    };

    handleToggle = async (e, id) => {
        let task = this.state.tasksToDisplay.find(t => t.id === id);
        console.log(task);
        task = {...task, finished: !task.finished};
        console.log(task);
        let tasks = this.state.tasksToDisplay.map(oldTask => oldTask.id === id ? task : oldTask );
        await this.props.manageTasks(tasks, this.props.id);

        await this.setState(prevState => ({
            tasksToDisplay: tasks,
        }));
    };

    handleCancelFile = async (id, name) => {
        await this.setState(prevState => ({
            task: {
                ...prevState.task,
                files: [...prevState.task.files.filter(file => file.id !== id)],
            }
        }));
        await this.props.deleteFile(name, this.state.task.id)
    };

    handleCancelFileComment = async (id, name) => {
        await this.setState(prevState => ({
            taskComment: {
                ...prevState.taskComment,
                files: [...prevState.taskComment.files.filter(file => file.id !== id)],
            }
        }));
        await this.props.deleteFile(name, this.state.task.id)
    };

    handleDeleteTaskFile = async (id, name, task) => {
        let newTask = {...task, files : task.files.filter(file => file.id !== id)};
        let newTasks = this.state.tasksToDisplay.map(oldTask => oldTask.id === task.id ? newTask : oldTask);
        await this.setState(prevState => ({
            tasksToDisplay: newTasks,
        }));
        console.log(newTasks);
        await this.props.deleteFile(name, task.id);
        await this.props.manageTasks(newTasks, this.props.id);
    };

    handleDeleteTaskCommentFile = async (id, name, taskComment) => {
        let newTaskComment = {...taskComment, files : taskComment.files.filter(file => file.id !== id)};
        let newTaskComments = this.state.taskCommentsToDisplay.map(oldTaskComment => oldTaskComment.id === taskComment.id ? newTaskComment : oldTaskComment);
        await this.setState(prevState => ({
            taskCommentsToDisplay: newTaskComments,
        }));
        await this.props.deleteFile(name, taskComment.id);
        await this.props.manageTaskComments(newTaskComments, this.props.id);
    };

    handleDeleteTaskComment = async (taskComment)  => {
        let newTaskComments = this.state.taskCommentsToDisplay.filter(oldTaskComment => oldTaskComment.id !== taskComment.id);
        await this.props.manageTaskComments(newTaskComments, this.props.id);
        for (let file of taskComment.files) {
            await this.props.deleteFile(file.name, taskComment.id);
        }
        await this.setState(prevState => ({
            taskCommentsToDisplay: newTaskComments,ToDisplay: newTaskComments,
        }));
    };

    handleDeleteTask = async (task) => {
        let newTasks = this.state.tasksToDisplay.filter(oldTask => oldTask.id !== task.id);
        await this.props.manageTasks(newTasks, this.props.id);
        for (let file of task.files) {
            await this.props.deleteFile(file.name, task.id);
        }

        let taskComments = this.state.taskCommentsToDisplay.filter(comment => comment.taskId === task.id);

        for(let comment of taskComments) {
            await this.handleDeleteTaskComment(comment);
        }

        await this.setState(prevState => ({
            tasksToDisplay: newTasks,ToDisplay: newTasks,
        }));

    };

    handleEdit = (task) => {
        this.setState({
            editTask: task
        })
    };

    handleEditSubmitTask = async (e) => {
        e.preventDefault();
        let newTask = this.state.editTask;
        let newTasks = this.state.tasksToDisplay.map(oldTask => oldTask.id === newTask.id ? newTask : oldTask);
        await this.setState(prevState => ({
            tasksToDisplay: newTasks,
            editTask: {id: null},
        }));
        await this.props.manageTasks(newTasks, this.props.id);

    };

    handleEditSubmitTaskComment = async (e) => {
        e.preventDefault();
        let newTask = this.state.editTask;
        let newTaskComments = this.state.taskCommentsToDisplay.map(oldTask => oldTask.id === newTask.id ? newTask : oldTask);
        await this.props.manageTaskComments(newTaskComments, this.props.id);
        await this.setState(prevState => ({
            taskCommentsToDisplay: newTaskComments,
            editTask: {id: null},
        }));
    };

    render() {


        console.log(this.state.tasksToDisplay);


        return (
            <div>
                <div style={{backgroundColor: "white", marginBottom: "32px"}}>
                    <div className={"wireframes-main-back"}>
                        <Link to={`/client/dashboard`}>
                            <img src={"/assets/icons/arrow.svg"} style={{height: "34px"}} alt=""/>
                            <span className={"wireframes-main-back-span"} style={{color: "black"}}>Back</span>
                        </Link>
                    </div>
                </div>
                <div className="client-dashboard-line">
                    <div>Finished adding items to the list? <strong>Click here to notify our team</strong><i className="arrow right icon"></i></div>
                </div>
                <div className="client-tasks-main">
                    <div className="client-tasks-header">
                        <h1>Your feedback & revisions</h1>
                        <p>Finished adding items to the list? <strong>Click here to notify our team</strong></p>
                    </div>
                    <div className="client-tasks-tasks">
                        {this.state.tasksToDisplay.map(task => (

                            <div key={task.id} className="client-tasks-task">
                                <i className="close icon delete-task" onClick={() => this.handleDeleteTask(task)}/>
                                <div className="client-tasks-task-border">
                                    <div className="client-tasks-task-flex">
                                        {!task.finished ?
                                            <span onClick={e => this.handleToggle(e, task.id)} className="client-tasks-task-empty"/>
                                            :
                                            <img onClick={e => this.handleToggle(e, task.id)} className="client-tasks-task-ticked" src="/assets/icons/ticked.png" alt=""/>
                                        }
                                        {this.state.editTask.id !== task.id ?
                                            <h4 onClick={e => this.handleEdit(task)}>{task.text}</h4>
                                            :
                                            <form style={{width: "100%"}} action="" onSubmit={this.handleEditSubmitTask}>
                                                <input className="client-tasks-task-edit-form" autoFocus="true" type="text" value={this.state.editTask.text} onChange={e => {
                                                    e.persist();
                                                    this.setState(prevState => ({
                                                    editTask: {
                                                        ...prevState.editTask,
                                                        text: e.target.value,
                                                    }
                                                }))}}/>
                                            </form>
                                        }


                                        <p>{ task.files && task.files.length > 0 ? `${task.files.length} ${task.files.length > 1 ? "files" : "file"} attached` : ""}</p>
                                    </div>
                                    {task.files && task.files.length > 0 &&
                                    <div className="client-tasks-task-comments-comment-files-flex" style={{padding: "0px 16px 8px 16px"}}>
                                        {task.files.map(file => (
                                                <div className="client-tasks-task-comments-add-files-f"
                                                     key={file.id}>
                                                    <div>{file.name}</div>
                                                    <i className="close icon"
                                                       onClick={e => this.handleDeleteTaskFile(file.id, file.name, task)}/>
                                                    <i className="icon download" onClick={e => this.downloadImage(file.url, file.name)}/>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    }
                                    {(this.state.taskCommentsToDisplay.filter(comment => comment.taskId === task.id).length > 0 || task.id === this.state.showId) &&
                                    <div className={`client-tasks-task-comments ${this.state.taskCommentsToDisplay.filter(comment => comment.taskId === task.id).length > 0 && "client-tasks-task-comments-pad"}`}>
                                    {this.state.taskCommentsToDisplay.filter(comment => comment.taskId === task.id).length > 0 &&
                                    this.state.taskCommentsToDisplay.filter(comment => comment.taskId === task.id).map( taskComment => (
                                        <div key={taskComment.id} className="client-tasks-task-comments-comment">
                                            <h4>{taskComment.author}<i className="close icon delete-task-comment" onClick={(e) => this.handleDeleteTaskComment(taskComment)}/></h4>
                                            {this.state.editTask.id !== taskComment.id ?
                                                <p onClick={e => this.handleEdit(taskComment)}>{taskComment.text}</p>
                                                :
                                                <form style={{width: "100%"}} action="" onSubmit={this.handleEditSubmitTaskComment}>
                                                    <input className="client-tasks-comment-edit-form" autoFocus="true" type="text" value={this.state.editTask.text} onChange={e => {
                                                        e.persist();
                                                        this.setState(prevState => ({
                                                            editTask: {
                                                                ...prevState.editTask,
                                                                text: e.target.value,
                                                            }
                                                        }))}}/>
                                                </form>
                                            }

                                            {taskComment.files &&
                                            <div className="client-tasks-task-comments-comment-files">
                                                <div className="client-tasks-task-comments-comment-files-flex">
                                                    {taskComment.files.map(file => (
                                                        <div className="client-tasks-task-comments-add-files-f"
                                                             key={file.id}>
                                                            <div>{file.name}</div>
                                                            <i className="close icon"
                                                               onClick={e => this.handleDeleteTaskCommentFile(file.id, file.name, taskComment)}/>
                                                            <i className="icon download" onClick={e => this.downloadImage(file.url, file.name)}/>
                                                        </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            }

                                        </div>
                                    ))}


                                    {(task.id === this.state.showId || this.state.taskCommentsToDisplay.filter(comment => comment.taskId === task.id).length > 0) &&
                                        <div className="client-tasks-task-comments-add">
                                            <form action="" onSubmit={ e => this.handleAddTaskComment(e, task.id)}>
                                                <input
                                                    onChange={e => this.handleChangeAddComment(e, task.id)}
                                                    placeholder="Write a reply"
                                                    type="text" required={true}
                                                    className="client-tasks-task-comments-add-input"
                                                    value={this.state.taskComment.taskId === task.id ? this.state.taskComment.value : ""}
                                                    autoComplete="off"
                                                />
                                                {this.state.taskComment.text.length > 0 && this.state.taskComment.taskId === task.id &&
                                                <label htmlFor="filee"><img src="/assets/icons/file.png" alt=""/></label>
                                                }
                                                <input id="filee" onChange={ e => this.handleFileAddComment(e, task.id)} name="file" type="file"/>
                                            </form>
                                            {this.state.taskComment.files.length > 0 && this.state.taskComment.taskId === task.id &&
                                            <div className="client-tasks-task-comments-add-files">
                                                {this.state.taskComment.files.map(file =>
                                                    <div className="client-tasks-task-comments-add-files-f" key={file.id}><div>{file.name}</div>
                                                        <i className="close icon" onClick={e => this.handleCancelFileComment(file.id,file.name)} />
                                                        <i className="icon download" onClick={e => this.downloadImage(file.url, file.name)}/>
                                                    </div>
                                                )}
                                            </div>
                                            }
                                        </div>
                                    }
                                    </div>
                                    }
                                </div>
                                {(task.id !== this.state.showId && this.state.taskCommentsToDisplay.filter(comment => comment.taskId === task.id).length === 0) &&
                                <span onClick={(e) => {
                                    this.setState({showId: task.id})
                                }}>Comment on this task</span>
                                }
                                {task.id === this.state.showId &&
                                <span onClick={(e) => {
                                    this.setState({showId: ""})
                                }}>Hide Comment Section</span>
                                }
                            </div>
                        ))}

                    </div>
                    <div className="client-tasks-form">
                        <form action="" onSubmit={this.handleAddTask}>
                            <input
                                onChange={this.handleChangeAddTask}
                                id="text"
                                type="text"
                                required={true}
                                value={this.state.task.text}
                                placeholder="Ask a question or request a change to your website..."
                                autoFocus={true}
                                autoComplete="off"
                            />
                            <div className="client-tasks-form-form">
                                {this.state.task.files &&
                                <div className="client-tasks-form-files">
                                    {this.state.task.files.map(file =>(
                                        <div key={file.id}><div>{file.name}</div>
                                            <i className="close icon" onClick={e => this.handleCancelFile(file.id,file.name)} />
                                            <i className="icon download" onClick={e => this.downloadImage(file.url, file.name)}/>
                                        </div>
                                    ))}
                                </div>
                                }
                                <div className="client-tasks-form-control">
                                    <label htmlFor="file">Attach a file</label>
                                    <input  onChange={this.handleFileAddTask} id="file" name="file" type="file"/>
                                    <button type="submit" >Add Task</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>

            </div>
        );
    }
}

export default compose(
    withRouter,
    withFirestore,
    firestoreConnect([{collection: 'projects'}]),
    connect(mapStateToProps,actions),
)(ClientTasks)
