import React from 'react';
import ReactDOM from 'react-dom';
import ScrollToTop from './utils/scroll-to-top';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {configureStore} from "./store/configure-store";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import App from './components/App';

const rootEl = document.getElementById('root');

const store = configureStore();

store.firebaseAuthIsReady.then(() => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop>
                    <ReduxToastr
                        position="bottom-right"
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"
                    />
                    <App />
                </ScrollToTop>
            </BrowserRouter>
        </Provider>,
        rootEl
    );
});

