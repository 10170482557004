import React, {Component} from 'react';
import DashboardHeader from "../dashboard-header/dashboard-header";
import {Field, reduxForm} from "redux-form";
import FormInput from "../../form-components/form-input/form-input";
import {Button, Label} from "semantic-ui-react";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import NewPassword from "../../pages-forms/new-password/new-password";
import {combineValidators, isRequired} from "revalidate";
import {withFirebase} from "react-redux-firebase";
import {connect} from "react-redux";

const validate = combineValidators({
    name: isRequired("The name/company"),
    phone: isRequired("Phone"),
});

class ClientDashboardForm extends Component {

    onFormSubmit = async values => {
        await this.props.changeProfileInfo(values);
        await this.props.handleToggle();
    };


    render() {

        const {invalid, submitting, pristine,handleSubmit,error, initialValues} = this.props;


        return (
            <div className="ui dashboard container">
                <br/>
                <br/>
                <form action="" className="ui form" autoComplete="off" onSubmit={handleSubmit(this.onFormSubmit)}>
                    <Field
                        name="name"
                        label="Name/Company"
                        component={FormInput}
                        placeholder="John Doe"
                    />
                    <Field
                        label="Phone"
                        name="phone"
                        component={FormInput}
                        placeholder="123456789"
                    />
                    {error && (
                        <Label basic color="red" style={{marginBottom: "10px"}}>
                            {error}
                        </Label>
                    )}
                    <FormSubmitButton
                        disabled={invalid || submitting || pristine}
                        text={`Update Profile`}
                    />

                </form>
                <NewPassword/>
            </div>
        );
    }
}

export default withFirebase(connect(null,null)(reduxForm({form: 'clientForm', validate})(ClientDashboardForm)));