import React, {Component} from 'react';
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import {changeUrl, changeShowUrl} from "../../../actions/project-actions";
import Loading from "../../loading/loading";
import firebase from "../../../firebase";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toastr} from "react-redux-toastr";


const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const project = state.firestore.ordered.projects && state.firestore.ordered.projects
        .filter(project => project.id === id)[0];

    return {
        project: project,
        projectId: id,
    }

};

const actions = {
    changeUrl,
    changeShowUrl,
};

const urlPrepend = s => {
    if (!s.match(/^[a-zA-Z]+:\/\//))
    {
        return 'https://' + s;
    }
    return s;
};

class WebsiteLink extends Component {

    state = {
        showUrl: false,
        urlEdit: false,
        url: "",
    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`projects`);

        const db = firebase.firestore();

        const projectQuery = await db.collection("projects").doc(this.props.match.params.id);
        const projectSnap = await projectQuery.get();
        const data = projectSnap.data();
        console.log(data.showUrl);
        await this.setState({
            showUrl: data.shareWebsiteLink || false,
            url: data.url || "",
            project: data

        });
    }

    submitUrlChange = async (e) => {
        e.preventDefault();
        await this.props.changeUrl(this.props.match.params.id, this.state.url.length === 0 ? "" : urlPrepend(this.state.url));
        await this.setState({
            urlEdit: false,
        })
    };

    removeURL = async (e) => {
        await this.props.changeUrl(this.props.match.params.id, "");
        await this.setState({
            url: ""
        })
    };

    showUrlChange = async (e, val) => {
        await this.props.changeShowUrl(this.props.match.params.id, val);
        await this.setState({
            showUrl: val,
        })
    };

    render() {

        if(!this.state.project) {
            return <Loading/>
        }

        if(this.props.client && !this.state.project.shareWebsiteLink) {
            return (
                <div id="main" style={{height: "75%"}}>
                    <div className="fof">
                        <h1 style={{color: "#8C8C8C"}}>We are currently working on it...</h1>
                    </div>
                </div>
            );
        }


        return (
            <div className={"project-overview-web-link"}>
                <div className={"project-overview-web-link-content"}>
                    {!this.props.client ?
                        <h2>Squarespace website link</h2>
                        :
                        <h2>Your new website</h2>
                    }
                    {!this.props.client ?
                        <p style={{color: "#898989"}}>
                            This should take the client exactly where they need to go to view
                            the work in progress. Be sure to add <strong>?password=password</strong> to the end
                            of the link if it is a trial site.
                        </p>
                        :
                        <>
                            <p style={{color: "#898989"}}>
                                Your website is now available to view. Click the 'Visit Site' button to view the work in progress.
                                If asked for a password, it will be <strong>password</strong>
                            </p>
                            <p style={{color: "#898989"}}>
                                Your website may look a bit odd at the moment but when you're ready to go live we'll
                                get the correct domain connected E.g. yourbusiness.com
                            </p>
                        </>
                    }


                    {(!this.state.urlEdit) &&
                    <div className={"project-overview-web-link-content-link"} style={{color: "#c3c3c3"}}>
                    </div>
                    }
                    {this.state.urlEdit &&
                    <form action="" className={"project-overview-web-link-content-form"} onSubmit={this.submitUrlChange}>
                        <input onChange={(e) => this.setState({url: e.target.value})} autoFocus={true} placeholder={"https://"} value={this.state.url} type="text"/>
                    </form>
                    }
                    {!this.props.client ?
                    <>
                        <div className={"project-overview-web-link-content-link-func-but"}>
                            <a href={this.state.url} target="_blank"><span>VISIT WEBSITE</span></a>
                            <CopyToClipboard text={this.state.url} onCopy={(e) => toastr.success("Success", "URL was copied")}>
                                <span style={{backgroundColor: "#c3c3c3"}}>COPY WEBSITE LINK</span>
                            </CopyToClipboard>

                        </div>
                        <div className={"project-overview-web-link-content-link-func"}>
                            <>
                                <span onClick={() => this.setState({urlEdit: true})}>EDIT LINK |</span>
                                <span onClick={this.removeURL}>REMOVE LINK |</span>
                                {!this.state.showUrl ?
                                    <span onClick={(e) => this.showUrlChange(e, true)}>SHARE WITH CLIENT</span>
                                    :
                                    <span onClick={(e) => this.showUrlChange(e, false)}>UNSHARE WITH CLIENT</span>
                                }
                            </>
                        </div>
                    </>
                        :
                        <div className={"project-overview-web-link-content-link-func-but"}>
                            <a href={this.state.url} target="_blank"><span>VISIT WEBSITE</span></a>
                            <CopyToClipboard text={this.state.url} onCopy={(e) => toastr.success("Success", "URL was copied")}>
                                <span style={{backgroundColor: "#c3c3c3"}}>COPY WEBSITE LINK</span>
                            </CopyToClipboard>

                        </div>

                    }

                </div>
            </div>
        );
    }
}

export default compose(
    firestoreConnect([{collection: 'projects'}]),
    withRouter,
    connect(mapStateToProps, actions),
    reduxForm({form: 'componentForm'}),
)(WebsiteLink);;