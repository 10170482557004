import React from 'react';
import {Link} from "react-router-dom";
import {capitalize} from "../../../utils/helpers";
import cuid from "cuid";

const PremadePagesMenu = ({ categories,categoryClick, addWhiteSpace}) => {

    return (
        <div className="page-sketches-right-main-list">
            <div className="wireframes-build-menu-nav">
                <div className={"page-sketches-editor-navlink-back"}>
                    <Link to={`/dashboard/components/premade-pages`} style={{color: "white"}}>
                        <img src={"/assets/icons/arrow.svg"} style={{height: "24px"}} alt=""/> Back
                    </Link>
                </div>
            </div>
            {categories && categories.map((category) => (
                <span
                    onClick={()=> categoryClick(category)}
                    key={cuid()}
                >
                        {capitalize(category)}
                    </span>
            ))}
            <span  onClick={addWhiteSpace}>Spacer Block</span>
            <span onClick={()=> categoryClick("upload-custom")}>Upload Custom Component</span>
        </div>
    );
};

export default PremadePagesMenu;