import {toastr} from "react-redux-toastr";
import firebase from "../firebase"
import cuid from "cuid";


export const createComponent = (component, file, fileName) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const firebase = getFirebase();
        const id= cuid();
        const options = {
            name: fileName,

        };


        try {
            const uploadedFile = await firebase.uploadFile(id, file, null, options);
            let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
            await firestore.set(`components/${id}`, {...component, id:id, type: "default", fileName:fileName, image: downloadURL});
            toastr.success('Success', "Component has been created");
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }

};

export const updateComponent = (component, file, fileName) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const firebase = getFirebase();


        try {

            if(file === false && fileName === false) {
                await firestore.update(`components/${component.id}`, {...component});
            } else {
                const prevImageDoc = await firestore.get(`components/${component.id}`);
                const prevImageName = prevImageDoc.data().fileName;
                await firebase.deleteFile(`${component.id}/${prevImageName}`);
                const options = {
                    name: fileName,

                };
                const uploadedFile = await firebase.uploadFile(component.id, file, null, options);
                let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
                await firestore.update(`components/${component.id}`, {...component, fileName:fileName, image: downloadURL});
            }

            toastr.success('Success', "Component has been updated");
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }

};

export const deleteComponent = (id, fileName) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const firebase = getFirebase();

        try {
            await firebase.deleteFile(`${id}/${fileName}`);
            await firestore.delete(`components/${id}`);
            toastr.success('Success', "Component has been deleted");
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};


