import React, {Component} from 'react';

import "./dashboard-project-card.css"
import {format} from "date-fns";
import {Link} from "react-router-dom";
import DashboardProjectCardMenu from "../dashboard-project-card-menu/dashboard-project-card-menu";

class DashboardProjectCard extends Component {

    state = { open: false };


    render() {

        const {title, createdAt, id} = this.props.project;
        const {deleteProject,toggleArchiveProject,archiveType,archiveText, createTemplate,filter} = this.props;

        return (
            <div className="column four wide">
                <div className="dashboard-project-card-padding">
                    <div className="ui card dashboard-project-card-shadow">
                        <Link to={`/dashboard/project-overview/${id}`}>
                            <div className="content dashboard-project-card">
                                <div className="dashboard-project-card-title">{title}</div>
                            </div>
                        </Link>
                        <div className="extra content dashboard-project-new-card-bottom" ref={(el) => {
                            if (el) {
                                el.style.setProperty('border', 'none', 'important');
                            }
                        }}>
                            <span style={{paddingRight: "5px"}}>{createdAt && format(createdAt.toDate(), "MM/dd/yyyy")}</span>
                            <DashboardProjectCardMenu
                                project={this.props.project}
                                id={id}
                                deleteProject={deleteProject}
                                toggleArchiveProject={toggleArchiveProject}
                                archiveType={archiveType}
                                archiveText={archiveText}
                                createTemplate={createTemplate}
                                filter={filter}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardProjectCard;