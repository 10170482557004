import React from 'react';
import {Field, reduxForm} from "redux-form";
import FormInputPassword from "../../form-components/form-input-password/form-input-password";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import {connect} from "react-redux";
import {changePassword} from "../../../actions/auth-actions";
import {Label} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
const required = value => value ? undefined : 'Required';
const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;
const minLength6 = minLength(6);


const actions = {
    changePassword
};


const NewPassword = ({invalid, submitting, pristine,handleSubmit, error, changePassword, history}) => {

    const onChangePassword = async creds => {
        await changePassword(creds);
    };

    return (
        <form action="" className="ui form profile-form"  autoComplete="off" onSubmit={handleSubmit(onChangePassword)}>
            <Field
                label="Current Password"
                name="currentPassword"
                placeholder="Current Password"
                component={FormInputPassword}
                validate={[required]}
            />
            <br/>
            <Field
                label="New Password"
                name="newPassword"
                placeholder="New Password"
                component={FormInputPassword}
                validate={[required, minLength6]}
            />
            {error && (
                <Label basic color="red" style={{marginBottom: "10px"}}>
                    {error}
                </Label>
            )}
            <br/>
            <FormSubmitButton
                disabled={invalid || submitting || pristine}
                text={`Change Password`}
            />
        </form>
    );
};

export default connect(null, actions)(reduxForm({form: 'newPassword'})(withRouter(NewPassword)));