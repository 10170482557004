import React, { Component } from "react";

import "./dashboard-recent.css";

import DashboardHeader from "../../layout-components/dashboard-header/dashboard-header";
import DashboardProjectGrid from "../../layout-components/dashboard-project-grid/dashboard-project-grid";
import {
  deleteProject,
  toggleArchiveProject,
  createTemplate,
} from "../../../actions/project-actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loading from "../../loading/loading";
import { firestoreConnect } from "react-redux-firebase";

const mapStateToProps = (state) => ({
  projects: state.firestore.ordered.projects,
});

const actions = {
  deleteProject,
  toggleArchiveProject,
  createTemplate,
};

class DashboardRecent extends Component {
  state = {
    search: "",
  };

  onDeleteProject = (id) => {
    this.props.deleteProject(id);
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);
  }

  async componentWillUnmount() {
    const { firestore } = this.props;
    await firestore.unsetListener(`projects`);
  }

  changeSearch = (e) => {
    this.setState({ search: e.target.value.toLowerCase() });
  };

  getStatus() {
    const url = document.URL.split("/")[4];
    let status = "";
    switch (url) {
      case "recent":
        status = "recent";
        break;
      case "archived":
        status = "archived";
        break;
      case "templates":
        status = "templates";
        break;
      case "new-enquiries":
        status = "newEnquiry";
        break;
    }
    return status;
  }

  handleLogout = async () => {
    await this.props.firebase.logout();
    this.props.history.push(`/`);
  };

  render() {
    return (
      <div className="dashboard">
        <DashboardHeader
          changeSearch={this.changeSearch}
          logout={this.handleLogout}
          history={this.props.history}
        />
        <DashboardProjectGrid
          filter={this.getStatus()}
          deleteProject={this.onDeleteProject}
          projects={this.props.projects}
          toggleArchiveProject={this.props.toggleArchiveProject}
          createTemplate={this.props.createTemplate}
          search={this.state.search}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect([{ collection: "projects" }])(DashboardRecent));
