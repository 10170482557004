import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Select } from "semantic-ui-react";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import SelectHook from "./select-hook";
import { connect } from "react-redux";
import { updateProject } from "../../../actions/project-actions";

const ProposalWorkingForm = ({ project, updateProject }) => {
  const { register, control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      timescale: project.timescale || "",
      nextSteps: project.nextSteps || "",
    },
  });

  const onSubmit = (data) => {
    const updatedProject = { ...project, ...data };
    updateProject(updatedProject);
  };

  return (
    <div className="proposal-form-border">
      <h4 className="project-proposal-section-header">Working with us</h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <Controller
            setValue={setValue}
            value={getValues("nextSteps")}
            label="Next steps"
            name="nextSteps"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <Controller
            setValue={setValue}
            label="Timescale"
            value={getValues("timescale")}
            name="timescale"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />

        <Button type="submit" className="fluid">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default connect(null, { updateProject })(ProposalWorkingForm);
