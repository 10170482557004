import React, { Component, Fragment } from "react";

import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import "./app.css";

import Login from "./pages/login/login";
import DashboardRecent from "./pages/dashboard-recent/dashboard-recent";
import DashboardNotifications from "./pages/notifications-dashboard/dashboard-notifications";
import ProjectOverview from "./pages/project-overview/project-overview";
import ProjectForm from "./pages-forms/project-form/project-form";
import UserForm from "./pages-forms/user-form/user-form";
import PricingForm from "./pages-forms/pricing-form/pricing-form";
import ComponentForm from "./pages-forms/component-form/component-form";
import DashboardProfile from "./pages/dashboard-profile/dashboard-profile";
import { connect } from "react-redux";
import PrivateRoute from "./routing/private-route";
import Wireframes from "./pages/wireframes/wireframes";
import NoMatch from "./pages/no-match/no-match";
import Build from "./pages/build/build";
import ClientDashboard from "./pages/client-dashboard/client-dashboard";
import ClientPreview from "./pages/client-preview/client-preview";
import ClientPagePreview from "./pages/client-page-preview/page-preview";
import PremadePages from "./pages/premade-pages-main/premade-pages";
import PremadePagesB from "./pages/premade-pages-build/premade-pages-b";
import ContentManager from "./pages/content-manager/content-manager";
import ClientContent from "./pages/client-content/client-content";
import ProjectJourney from "./pages/project-journey/project-journey";
import ClientTasks from "./pages/client-tasks/c-tasks";
import AdminSettings from "./pages/admin-settings/admin-settings";
import ProjectOverviewClient from "./pages/project-overview-client/project-overview-client";
import NoProject from "./pages/no-project/no-project";
const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

class App extends Component {
  render() {
    const { auth } = this.props;
    const authenticated = auth.isLoaded && !auth.isEmpty;
    const role = auth.displayName;
    const id = auth.uid;
    const email = auth.email;

    return (
      <Switch>
        <Route
          path="/"
          exact
          render={() => (
            <Login email={email} role={role} authenticated={authenticated} />
          )}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path="/dashboard/recent/"
          component={DashboardRecent}
          history={this.props.history}
        />
        <PrivateRoute
          authenticated={authenticated && role === "admin"}
          path="/dashboard/admin-settings/"
          component={AdminSettings}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path="/dashboard/components/wireframes/build/:id"
          component={PremadePagesB}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path="/dashboard/components/premade-pages"
          component={PremadePages}
        />
        {/*<PrivateRoute*/}
        {/*    authenticated={authenticated}*/}
        {/*    exact*/}
        {/*    path="/client/dashboard/preview/:id"*/}
        {/*    component={ClientPreview}*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*    authenticated={authenticated}*/}
        {/*    exact*/}
        {/*    path="/client/dashboard/tasks/:id"*/}
        {/*    component={ClientTasks}*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*    authenticated={authenticated}*/}
        {/*    exact*/}
        {/*    path="/client/dashboard/content/:id"*/}
        {/*    component={ClientContent}*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*    authenticated={authenticated}*/}
        {/*    exact*/}
        {/*    path="/client/dashboard/preview/:id/wireframes/build/page/:idp"*/}
        {/*    component={ClientPagePreview}*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*    authenticated={authenticated && (role === "admin" || role === "employee")}*/}
        {/*    exact*/}
        {/*    path="/dashboard/project-overview/:id/wireframes/build/:idp"*/}
        {/*    component={Build}*/}
        {/*/>*/}
        {/*<PrivateRoute*/}
        {/*    authenticated={authenticated && (role === "admin" || role === "employee")}*/}
        {/*    exact*/}
        {/*    path="/dashboard/project-overview/content-manager/:id"*/}
        {/*    component={ContentManager}*/}
        {/*/>*/}
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path={["/dashboard/project-journey/:id"]}
          component={ProjectJourney}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path={["/dashboard/projects/create", "/dashboard/projects/edit/:id"]}
          component={ProjectForm}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path="/dashboard/archived"
          component={DashboardRecent}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path="/dashboard/templates/"
          component={DashboardRecent}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path="/dashboard/new-enquiries/"
          component={DashboardRecent}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path="/dashboard/notifications/"
          component={DashboardNotifications}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          path="/dashboard/project-overview/:id"
          component={ProjectOverview}
          userEmail={email}
        />
        <PrivateRoute
          authenticated={authenticated}
          path="/dashboard/project-overview-client/:id"
          component={ProjectOverviewClient}
          userEmail={email}
        />
        <PrivateRoute
          authenticated={authenticated}
          path="/dashboard/no-project"
          component={NoProject}
        />
        {/*<PrivateRoute*/}
        {/*    authenticated={authenticated && (role === "admin" || role === "employee")}*/}
        {/*    exact*/}
        {/*    path="/dashboard/project-overview/wireframes/:id"*/}
        {/*    component={Wireframes}*/}
        {/*/>*/}
        <PrivateRoute
          authenticated={authenticated && role === "admin"}
          exact
          path={["/dashboard/users/create", "/dashboard/users/edit/:id"]}
          component={UserForm}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path={[
            "/dashboard/components/create",
            "/dashboard/components/edit/:id",
          ]}
          component={ComponentForm}
        />
        <PrivateRoute
          authenticated={authenticated && role === "admin"}
          exact
          path={["/dashboard/pricing/create", "/dashboard/pricing/edit/:id"]}
          component={PricingForm}
        />
        <PrivateRoute
          authenticated={
            authenticated && (role === "admin" || role === "employee")
          }
          exact
          path="/dashboard/profile"
          component={DashboardProfile}
        />
        <Route>
          <NoMatch />
        </Route>
      </Switch>
    );
  }
}

export default connect(mapStateToProps, null)(withRouter(App));
