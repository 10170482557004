import React, {Component} from 'react';
import "./admin-settings.css";
import {Link} from "react-router-dom";

class AdminSettingsTablePJ extends Component {

    render() {
        const {journeys, deleteJourney} = this.props;
            const journeyList =journeys.map(journey => (
                <tr key={journey.id}>
                    <td>{journey.title}</td>
                    <td></td>
                    <td>
                        <Link to={`/dashboard/project-journey/${journey.id}`} className={"admin-settings-table-edit"}>Edit</Link>
                        <span onClick={() => deleteJourney(journey.id)} className={"admin-settings-table-del"}>Delete</span>
                    </td>
                </tr>
            ));
        return (
            <div>
                <table className={"admin-settings-table"}>
                    <tbody>
                    {journeyList}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AdminSettingsTablePJ;