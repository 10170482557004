import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./form-dropzone.css";

const FormDropzone = ({ uploadImage, text, type }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadImage(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
        [type]
      );
    },
    [uploadImage]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: "image/*",
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className={
          "form-dropzone-upload " +
          (isDragActive && "form-dropzone-upload--active")
        }
      >
        <p>{text}</p>
      </div>
    </div>
  );
};

export default FormDropzone;
