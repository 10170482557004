import React, { Component } from "react";

import "./dashboard-navbar.css";

import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

class DashboardNavbar extends Component {
  render() {
    if (
      this.props.auth.displayName === "admin" ||
      this.props.auth.displayName === "employee"
    ) {
      return (
        <div
          style={{ marginBottom: "30px" }}
          className="ui seven item menu dashboard-navbar"
        >
          <NavLink
            className="item"
            to="/dashboard/recent"
            activeClassName="active"
          >
            IN PRODUCTION
          </NavLink>
          {this.props.auth.displayName === "admin" && (
            <NavLink
              className="item"
              to="/dashboard/new-enquiries"
              activeClassName="active"
            >
              NEW BUSINESS
            </NavLink>
          )}
          <NavLink
            className="item"
            to="/dashboard/archived"
            activeClassName="active"
          >
            ARCHIVED
          </NavLink>
          <NavLink
            className="item"
            to="/dashboard/templates"
            activeClassName="active"
          >
            TEMPLATES
          </NavLink>
          <NavLink
            className="item"
            to="/dashboard/notifications"
            activeClassName="active"
          >
            NOTIFICATIONS
          </NavLink>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, null)(DashboardNavbar);
