import React, { Component } from "react";
import { Form, Label } from "semantic-ui-react";

import "./form-input-password.css";

class FormInputPassword extends Component {
  state = {
    hidden: true,
    password: this.props.input.value || "",
  };

  onShowClick = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  render() {
    const {
      label,
      name,
      placeholder,
      input,
      meta: { touched, error },
    } = this.props;
    const { password, hidden } = this.state;

    const inputType = hidden ? "password" : "text";

    return (
      <Form.Field error={touched && !!error}>
        <label>{label}</label>
        <input
          {...input}
          type={inputType}
          name={name}
          placeholder={placeholder}
          onChange={this.handlePasswordChange}
          value={password}
        />
        <i
          className="eye icon form-input-password-icon"
          onClick={this.onShowClick}
        />
        {touched && error && (
          <Label basic color="red">
            {error}
          </Label>
        )}
      </Form.Field>
    );
  }
}

export default FormInputPassword;
