import React, {Component} from 'react';

class UploadPremade extends Component {
    render() {
        const {handleBack, handleCancel, handleUpload} = this.props;
        return (
            <div>
                <div className="wireframes-build-menu-nav">
                    <div className={"wireframes-build-menu-nav-link"} onClick={handleCancel}>
                        <img src={"/assets/icons/arrow.svg"} style={{height: "24px"}} alt=""/> Back
                    </div>
                </div>
                <div className="wireframes-build-menu-upload-custom">
                    <p className="wireframes-build-menu-upload-custom-header">
                        Use premade Page
                    </p>

                        <p className="wireframes-build-menu-upload-custom-text">
                            Are you sure you want to upload this premade page?
                        </p>
                        :
                        <div className={"wireframes-build-menu-upload-custom-buttons"}>
                            <button className="ui inverted black button" onClick={handleUpload}>Submit</button>
                            <button className="ui inverted black button" onClick={handleCancel}>Cancel</button>
                        </div>
                </div>
            </div>
        );
    }
}

export default UploadPremade;