import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const PrivateRoute = ({component: Component, authenticated, ...rest}) => {
    console.log(authenticated);
    return (
        <Route {...rest}
            render={(props) => authenticated ? <Component {...rest} /> : <Redirect to="/error"/>}
        />
    );
};

export default PrivateRoute;
