import React, { Component } from "react";
import { Dropdown, Menu } from "semantic-ui-react";

import "./dashboard-search-bar.css";

import SearchBarTitle from "../search-bar-title/search-bar-title";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

// const options = [{ text: "Profile"  }, { text: "Logout", value: 2 }];
class DashboardSearchBar extends Component {
  render() {
    let url = window.location.href;
    url = url.split("/");
    url = url[url.length - 1];
    url = url === "recent" || url === "archived" || url === "templates";
    return (
      <div className="ui grid search-bar-flex">
        <SearchBarTitle />
        <div className="column" style={{ width: "60%" }}>
          <div className="ui search">
            <div className="ui icon input search-bar-container ">
              <input
                disabled={!url}
                className="prompt search-bar"
                type="text"
                placeholder="Search"
                onChange={(e) => this.props.changeSearch(e)}
              />
              <i className="search icon" />
            </div>
          </div>
        </div>
        <div className="column" style={{ width: "20%" }}>
          <div className="search-bar-nav">
            <div>
              <Link
                to="/dashboard/admin-settings/profile"
                style={{
                  visibility:
                    this.props.auth.displayName === "employee"
                      ? "hidden"
                      : "visible",
                }}
              >
                Admin Settings
              </Link>
            </div>
            <div>
              {/* <Link to="/dashboard/admin-settings/profile">Account</Link> */}
              <Menu className="account-dropdown">
                <Dropdown item text="Account">
                  <Dropdown.Menu>
                    <Dropdown.Header
                      icon="user circle"
                      content={this.props.auth.email}
                    />
                    <Dropdown.Item>
                      <Link to="/dashboard/admin-settings/profile">
                        Profile
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.props.logout}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(DashboardSearchBar);
