import React, { Component } from "react";
import { changeField } from "../../../actions/project-actions";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import firebase from "../../../firebase";
import moment from "moment";

const actions = {
  changeField,
};

class ProjectOverviewDate extends Component {
  state = {
    dates: [],
    editTitle: "",
    editDate: "",
    sessionsUsed: "",
    rescheduled: 0,
    revisions: 0,
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);

    const db = firebase.firestore();

    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const projectSnap = await projectQuery.get();
    const data = projectSnap.data();

    const buildDate = data.buildDate
      ? moment(data.buildDate).format("Do MMMM, YYYY")
      : "";
    const nextRevisionSession = data.nextRevisionSession
      ? moment(data.nextRevisionSession).format("Do MMMM, YYYY")
      : "";
    const clientContentDeadLine = data.clientContentDeadLine
      ? moment(data.clientContentDeadLine).format("Do MMMM, YYYY")
      : "";

    const dates = [
      {
        title: "Content Deadline",
        date: clientContentDeadLine,
        desc: "clientContentDeadLine",
        formatDate: data.clientContentDeadLine,
      },
      {
        title: "Next Revision Session",
        date: nextRevisionSession,
        desc: "nextRevisionSession",
        formatDate: data.nextRevisionSession,
      },
      {
        title: "Site to be set live",
        date: buildDate,
        desc: "buildDate",
        formatDate: data.buildDate,
      },
    ];

    await this.setState({
      dates,
    });
  }

  updateDate = async (e) => {
    e.preventDefault();
    console.log(this.state.editTitle, this.state.editDate);
    await this.props.changeField(
      this.props.match.params.id,
      this.state.editDate,
      this.state.editTitle
    );
    const newDates = this.state.dates.map((date) =>
      this.state.editTitle === date.desc
        ? {
            ...date,
            formatDate: this.state.editDate,
            date: moment(this.state.editDate).format("Do MMMM, YYYY"),
          }
        : date
    );
    await this.setState({
      editTitle: "",
      editDate: "",
      dates: newDates,
    });
  };

  render() {
    return (
      <div className="project-overview-section">
        <div className="project-overview-section-header">
          <div style={{ marginRight: "8vw" }}>
            <h2>Key dates & deadlines</h2>
            <p>
              These dates can be rescheduled if required but please note that
              any dates changed within within 10 working days may insure a fee -
              refer to the terms in your proposal.
            </p>
            <div></div>
          </div>
        </div>
        <div className="project-overview-section-content">
          {this.state.dates.map((date) => {
            if (this.state.editTitle === date.desc) {
              return (
                <div
                  style={{
                    height: "60px",
                    margin: "15px 0 15px 0",
                    cursor: "pointer",
                  }}
                  key={date.desc}
                  className="project-overview-section-content-edit-form"
                >
                  <form action="" onSubmit={(e) => this.updateDate(e)}>
                    <input
                      type="date"
                      value={this.state.editDate}
                      onChange={(e) =>
                        this.setState({ editDate: e.target.value })
                      }
                    />
                    <button type="submit" style={{ display: "none" }}>
                      Submit
                    </button>
                  </form>
                  <span
                    onClick={() =>
                      this.setState({ editTitle: "", editDate: "" })
                    }
                  >
                    CANCEL
                  </span>
                </div>
              );
            } else {
              if (date.date !== "") {
                return (
                  <div
                    style={{ height: "60px" }}
                    key={date.desc}
                    className="project-overview-section-content-link"
                  >
                    {this.state.client ? (
                      <span>
                        - {date.date} - {date.title}
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          this.setState({
                            editTitle: date.desc,
                            editDate: date.formatDate,
                          })
                        }
                      >
                        - {date.date} - {date.title}
                      </span>
                    )}
                  </div>
                );
              }
            }
          })}
        </div>
      </div>
    );
  }
}
export default compose(
  firestoreConnect([{ collection: "projects" }]),
  withRouter,
  connect(null, actions)
)(ProjectOverviewDate);
