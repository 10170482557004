import React, {Component} from 'react';
import {Grid} from "semantic-ui-react";
import {updatePageName, updateComponentNote} from "../../../actions/pages-actions";
import "./build.css"
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";
import BuildMainMenu from "../../layout-components/build-main-menu/build-main-menu";
import BuildSecondMenu from "../../layout-components/build-second-menu/build-second-menu";
import firebase from "../../../firebase";
import cuid from "cuid";
import DragAndDropZone from "../../drag-and-drop-zone/dndzone";
import {updateComponents,deleteComponents} from "../../../actions/wireframes-actions";
import {deletePage} from "../../../actions/pages-actions";
import BuildAddDesc from "../../layout-components/build-add-description/build-add-desc";
import {insertComment} from "../../../actions/pages-actions";
import UploadCustom from "../../layout-components/upload-custom/upload-custom"
import {uploadCustom} from "../../../actions/wireframes-actions";
import AddPremade from "../../layout-components/add-premade/add-premade";
import UploadPremade from "../../layout-components/upload-premade/upload-premade";

const mapStateToProps = (state,ownProps) => {
    const projectId = ownProps.match.params.id;
    const pageId = ownProps.match.params.idp;

    const projects = state.firestore.ordered.projects;
    const components = state.firestore.ordered.components;
    const premadePages = state.firestore.ordered.premade_pages;

    const defaultComponents = components && components.filter(component => component.type === "default");
    const project = projects && projects.filter(project => project.id === projectId)[0];
    const pageNameDoc = projects && project && project.pages.filter(page => page.id === pageId)[0];
    const pageName = pageNameDoc && pageNameDoc.pageName;
    let categories = [];
    defaultComponents && defaultComponents.forEach(component => {
        categories.push(component.category);
    });
    categories = [...new Set(categories)];
    return  {
        premadePages,
        pageName,
        pageNameDoc,
        projectId,
        pageId,
        defaultComponents,
        project,
        categories,

    }
};

const actions = {
    updatePageName,
    updateComponents,
    deletePage,
    deleteComponents,
    insertComment,
    uploadCustom,
    updateComponentNote,
};



class Build extends Component {

    state = {
        showForm: false,
        formName: '',
        componentsToDisplay: [],
        componentsBackup: [],
        requestName: '',
        description: '',
        modalOpen: false,
    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`projects`);

        const db = firebase.firestore();

        const projectQuery = await db.collection("projects").doc(this.props.match.params.id);
        const projectSnap = await projectQuery.get();
        const pages = projectSnap.data().pages;
        const page = pages.filter(page => page.id === this.props.match.params.idp)[0];

        await this.setState({
            componentsToDisplay: page.components,
            description: page.description,
            formName: page.pageName,
        });



    };

    addWhiteSpace = async () => {
        const newComponent = {
            image: "https://firebasestorage.googleapis.com/v0/b/david-and-george-cms.appspot.com/o/whitespace%2Fwhite-stripe.png?alt=media&token=95f57a21-8bea-4456-8d26-9a0e5e0aca02",
            note: "",
            id: cuid(),
            orderId: cuid(),
        };

        await this.setState(prevState => ({
            componentsToDisplay: [...prevState.componentsToDisplay, newComponent]
        }));


        this.props.updateComponents(this.props.match.params.id, this.props.match.params.idp, this.state.componentsToDisplay);
    };

    handleClick = async () => {
        await this.setState({showForm: true});
        await this.setState({formName: this.props.pageName});
    };

    handleChange = async (e) => {
        await this.setState({formName: e.target.value})
    };

    reloadComponents = async (components) => {
        this.setState({componentsToDisplay: components});
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.state.formName.length === 0) {
            return;
        }
        await this.props.updatePageName(this.props.pageNameDoc, this.state.formName,this.props.projectId);
        await this.setState({showForm: false});
    };

    handleSubmitDesc = async () => {
        this.props.insertComment(this.props.pageNameDoc, this.state.description, this.props.projectId)

    };

    handleChangeDesc = async (e) => {
        await this.setState({description: e.target.value})
    };

    handleModalClose = () => {
        this.setState({modalOpen: false})
    };

    handleModalOpen = () => {
        this.setState({modalOpen: true})
    };

    uploadCustomComponent = async (image) => {

        const imageLink = await this.props.uploadCustom(image);

        const newComponent = {
            image: imageLink,
            note: "Custom Component",
            url: "",
            id: cuid(),
            orderId: cuid()
        };

        await this.setState(prevState => ({
            componentsToDisplay: [...prevState.componentsToDisplay, newComponent]
        }));
        this.props.updateComponents(this.props.match.params.id, this.props.match.params.idp, this.state.componentsToDisplay);
    };

    async componentWillUnmount() {
        const {firestore} = this.props;
        await firestore.unsetListener(`projects`);
    };


    handleCategoryClick = async category => {
       await this.setState({requestName: category})
    };

    addPremade = async page => {
        await this.setState( prevState => ({
            componentsToDisplay: [ ...page.components],
            componentsBackup: [...prevState.componentsToDisplay],
            modalOpen: false,
            requestName: "pre-made",
        }));
        //this.props.updateComponents(this.props.match.params.id, this.props.match.params.idp, this.state.componentsToDisplay);
    };

    cancelPremade = async () => {
        await this.setState( prevState => ({
            componentsToDisplay: [ ...prevState.componentsBackup],
            componentsBackup: [],
            requestName: "",
        }));

    };

    uploadPremade = async () => {
        await this.setState( prevState => ({
            componentsBackup: [],
            requestName: "",
        }));
        this.props.updateComponents(this.props.match.params.id, this.props.match.params.idp, this.state.componentsToDisplay);
    };

    handleBack = async () => {
        await this.setState({requestName: ""})
    };

    handleDrop= async (result) => {
        const {destination, source, draggableId} = result;

        if(!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;

        }

        const oldComponentsOrder = [...this.state.componentsToDisplay];
        const draggedComponent = oldComponentsOrder.filter(component => component.orderId === draggableId)[0];
        oldComponentsOrder.splice(source.index,1);
        oldComponentsOrder.splice(destination.index, 0,draggedComponent );

        await this.setState({componentsToDisplay: oldComponentsOrder});
        await this.props.updateComponents(this.props.match.params.id, this.props.match.params.idp, oldComponentsOrder);
    };

    deletePage = async () => {
        await this.props.deletePage(this.props.pageNameDoc, this.props.projectId);
        this.props.history.push(`/dashboard/project-overview/wireframes/${this.props.projectId}`)

    };


    deleteComponents = async () => {
       await this.props.deleteComponents(this.props.projectId, this.props.pageId)
        await this.setState({componentsToDisplay: []})
    };

    handleDeleteComp = async component  => {
        const index = this.state.componentsToDisplay.indexOf(component);
        const oldComponentsOrder = [...this.state.componentsToDisplay];
        oldComponentsOrder.splice(index,1);
        await this.setState({componentsToDisplay: oldComponentsOrder});
        await this.props.updateComponents(this.props.match.params.id, this.props.match.params.idp, oldComponentsOrder);
    };

    handleComponent = async component => {
        const {image, note, id, url} = component;

        const newComponent = {
            image,
            note: note || "",
            id,
            url: url || "",
            orderId: cuid()
        };

        await this.setState(prevState => ({
            componentsToDisplay: [...prevState.componentsToDisplay, newComponent]
        }));
        this.props.updateComponents(this.props.match.params.id, this.props.match.params.idp, this.state.componentsToDisplay);

    };


    render() {

        console.log(this.state.modalOpen);

        const {projectId, project, defaultComponents, categories,pageNameDoc, pageId} = this.props;
        const {showForm, formText,requestName} = this.state;

        return (
            <Grid className="wireframes-build-container">
                <Grid.Row columns={2}>
                    <Grid.Column width={12} className={"wireframes-build-container-grid"}>
                        <div className="wireframes-build-functions">
                            <span className="wireframes-build-functions-item" onClick={this.deletePage}>Delete Page</span>
                            <span className="wireframes-build-functions-item" onClick={this.deleteComponents}>Clear Page</span>
                        </div>
                        {this.state.showForm ?
                            <form action="" onSubmit={this.handleSubmit}>
                                <input
                                    value={this.state.formName}
                                    autoFocus
                                    type="text"
                                    onChange={this.handleChange}
                                    placeholder="Page Name..."
                                    className={"dnd-zone-form"}
                                />
                            </form>
                            :
                        <h2 className={"dnd-zone-header"} onClick={this.handleClick}>{this.state.formName}</h2>
                        }
                        <div className="wireframes-build-dnd">

                            <DragAndDropZone
                                components={this.state.componentsToDisplay}
                                projectId={projectId}
                                handleDrop={this.handleDrop}
                                page={project && pageNameDoc}
                                updateComponentNote={this.props.updateComponentNote}
                                reloadComponents={this.reloadComponents}
                                handleDeleteComp={this.handleDeleteComp}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4} className={"wireframes-build-container-grid"}>
                        <div className="wireframes-build-menu">
                            <AddPremade
                                open={this.state.modalOpen}
                                handleClose={this.handleModalClose}
                                handleBack={this.handleBack}
                                handlePremade={this.addPremade}
                                premadePages={this.props.premadePages}
                            />
                            {requestName.length === 0 &&
                            <BuildMainMenu project={project} categories={categories}
                                           categoryClick={this.handleCategoryClick}
                                           addWhiteSpace={this.addWhiteSpace}
                                           handleModalOpen={this.handleModalOpen}
                                           projectId={projectId}
                                           pageId={pageId}
                            />
                            }
                            {requestName === "add-detail" &&
                                <BuildAddDesc
                                    handleBack={this.handleBack}
                                    handleSubmitDesc={this.handleSubmitDesc}
                                    description={this.state.description}
                                    handleChangeDesc={this.handleChangeDesc}
                                />
                            }
                            {requestName === "pre-made" &&
                            <UploadPremade
                                handleBack={this.handleBack}
                                handleCancel={this.cancelPremade}
                                handleUpload={this.uploadPremade}
                            />
                            }
                            {requestName === "add-detail" &&
                            <BuildAddDesc
                                handleBack={this.handleBack}
                                handleSubmitDesc={this.handleSubmitDesc}
                                description={this.state.description}
                                handleChangeDesc={this.handleChangeDesc}
                            />
                            }
                            {requestName === "upload-custom" &&
                            <UploadCustom
                                handleBack={this.handleBack}
                                uploadCustomComponent={this.uploadCustomComponent}
                            />}
                            {requestName.length !== 0 && requestName !== "add-detail" && requestName !== "upload-custom" && requestName !== "pre-made" &&
                            <BuildSecondMenu
                                handleBack={this.handleBack}
                                handleComponent={this.handleComponent}
                                category={requestName}
                                defaultComponents={defaultComponents}
                            />
                            }
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
    );
    }
}

export default compose(
    withRouter,
    withFirestore,
    firestoreConnect([{collection: "projects"},{collection: "components"},{collection: "premade_pages"}]),
    connect(mapStateToProps, actions)

)(Build);

