import React, {Component} from 'react';
import "./project-jourrney.css";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const Field = ({c, idx, handleDelete, handleEdit,id}) => (
    <Draggable key={c.id} draggableId={c.id} index={idx}>
        {(provided,snapshot) => (
            <div className={"journey-cont-item-cont"}>
                <i className={"ui icon close journey-cont-del"} onClick={e => handleDelete(c, "clientComponents", id)}/>
            <div
                key={c.id}
                className="journey-cont-item"
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                style={getStyle(provided.draggableProps.style, snapshot)}
                onClick={e => handleEdit(c)}
            >
            <div className="journey-cont-item-top">
                <h4>{c.heading}</h4>
                <p>{c.body}</p>
            </div>

            <div className={c.actionRequired && 'journey-cont-item-bottom'}>
                {c.actionRequired ?
                    <p>{c.actionText} <img src="/assets/icons/next.svg" alt=""/></p>
                    :
                    <p style={{height: "21px"}}></p>
                }
            </div>


            </div>
            </div>
        )}
    </Draggable>
);

function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
        return style;
    }
    return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.0001s!important`,
    };
}


class ClientJourney extends Component {
    render() {

        const {components,handleDrop, handleDelete, handleEdit ,id} = this.props;
        return (
            <div className="journey-cont">
                <p className="journey-cont-h">Client steps</p>
                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId={"3"}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                <div className="">
                                    {components && components.map((c,idx) => (
                                        <Field id={id} handleEdit={handleEdit} handleDelete={handleDelete} idx={idx} key={c.id} c={c} />
                                    ))}
                                </div>

                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}

export default ClientJourney;