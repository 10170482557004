import React, {Component} from 'react';

import "./build-add-desc.css"

class BuildAddDesc extends Component {
    render() {

        const {handleBack, handleChangeDesc, handleSubmitDesc, description} = this.props;

        const onHandleBack = () => {
            handleBack();
            handleSubmitDesc();
        };


        return (
            <div className={"page-sketches-right-main-list"}>
                <div className="wireframes-build-menu-nav">
                    <div className={"page-sketches-editor-navlink-back"} onClick={onHandleBack}>
                        <img src={"/assets/icons/arrow.svg"} style={{height: "24px"}} alt=""/> Back
                    </div>
                </div>
               <div className="wireframes-build-add-desc">
                   <form action="">
                        <textarea autoFocus value={description} onChange={handleChangeDesc} placeholder="Add comment..." className="wireframes-build-add-desc-text" >
                        </textarea>
                       {/*<button type="submit" className="wireframes-build-add-desc-button ui inverted black button">Submit</button>*/}
                   </form>
               </div>
            </div>
        );
    }
}


export default BuildAddDesc;