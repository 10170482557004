import { CREATE_USER, UPDATE_USER, DELETE_USER } from "../reduxConstants";
import { toastr } from "react-redux-toastr";
import { SubmissionError } from "redux-form";
import firebase from "../firebase";

export const createUser = (user) => {
  return async (dispatch, getState) => {
    const functions = firebase.functions();

    const createUser = await functions.httpsCallable("createUser");
    const result = await createUser(user);
    if (result.data === false) {
      console.log(result);
      throw new SubmissionError({
        _error: `${Object.entries(result)}`,
      });
    } else {
      toastr.success("Success", "New user was created.");
    }
  };
};

export const updateUser = (user) => {
  return async (dispatch, getState) => {
    const functions = firebase.functions();

    const updateUser = await functions.httpsCallable("updateUser");
    const result = await updateUser(user);
    if (result.data === false) {
      throw new SubmissionError({
        _error: "Email is already used or password is too short",
      });
    } else {
      toastr.success("Success", "User was updated.");
    }
  };
};

export const deleteUser = (id, role) => {
  return async (dispatch, getState) => {
    const functions = firebase.functions();
    const db = firebase.firestore();

    if (role === "employee") {
      const projectsQuery = await db
        .collection("projects")
        .where("employees", "array-contains", id);
      const projectsSnap = await projectsQuery.get();
      for (let i = 0; i < projectsSnap.docs.length; i++) {
        const projectId = projectsSnap.docs[i].data().id;
        await db
          .collection("projects")
          .doc(projectId)
          .update({
            employees: firebase.firestore.FieldValue.arrayRemove(id),
          });
      }
    } else {
      const projectsQuery = await db
        .collection("projects")
        .where("client", "==", id);
      const projectsSnap = await projectsQuery.get();
      for (let i = 0; i < projectsSnap.docs.length; i++) {
        const projectId = projectsSnap.docs[i].data().id;
        await db.collection("projects").doc(projectId).update({
          client: firebase.firestore.FieldValue.delete(),
        });
      }
    }

    const deleteUser = await functions.httpsCallable("deleteUser");
    const result = await deleteUser(id);
    if (result.data === false) {
      toastr.error("Ooops", "Something went wrong");
    } else {
      toastr.success("Success", "User was deleted");
    }
  };
};
