import React, { Component } from "react";
import LazyLoad from "react-lazyload";
import Masonry from "react-masonry-css";
import { Modal } from "semantic-ui-react";

// Styles

import "./build-second-menu.css";

class BuildSecondMenu extends Component {
  render() {
    const {
      handleBack,
      handleComponent,
      defaultComponents,
      category,
    } = this.props;

    const components =
      defaultComponents &&
      defaultComponents.filter((component) => component.category === category);

    return (
      <div className="page-sketches-right-main-list">
        <div className="wireframes-build-menu-nav">
          <div
            className={"page-sketches-editor-navlink-back"}
            onClick={handleBack}
          >
            <img
              src={"/assets/icons/arrow.svg"}
              style={{ height: "24px" }}
              alt=""
            />{" "}
            Back
          </div>
        </div>
        <Masonry
          breakpointCols={1}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column-second"
        >
          {components &&
            components.map((component) => (
              <div
                key={component.id}
                onClick={() => handleComponent(component)}
                className="mgc-component"
              >
                <img src={component.image} alt="icon" />
                <p>{component.title}</p>
              </div>
            ))}
        </Masonry>
      </div>
    );
  }
}

export default BuildSecondMenu;
