import React, {useState, useEffect} from 'react';
import "./project-jourrney.css";
import {Button, Form, Checkbox} from "semantic-ui-react";
import {Controller, useForm} from "react-hook-form";
import SelectHook from "../project-overview/select-hook";
import cuid from "cuid";



const JFormE = (props) => {

    const [actionRequired, setActionRequired] = useState(false);
    const [usePremadeStep, setUsePremadeStep] = useState(false);

    const typeOptions = [
        {key: "1", value: "client", text: "Client"},
        {key: "2", value: "dev", text: "Team"},
    ];


    const steps = props.components.map(c => (
        {key: c.id, value: c.id, text: c.heading}
    ));


    const shareWithClient = [
        {key: "1", value: "shareProposal", text: "Share Proposal"},
        {key: "2", value: "shareSketches", text: "Share Page Sketches"},
        {key: "3", value: "shareContent", text: "Share Content Form"},
        {key: "3", value: "shareLinks", text: "Share Website Link"},
    ];


    const notificationOptions = [
        {key: "1", value: "proposalRequired", text: "Proposal required"},
        {key: "2", value: "depositRequests", text: "Deposit request"},
        {key: "3", value: "productionHandovers", text: "Production Handovers"},
        {key: "4", value: "clientBookingSession", text: "Client booking session"},
        {key: "5", value: "prepContentForm", text: "Prep content form"},
        {key: "6", value: "contentDeadline", text: "Content Deadline"},
        {key: "7", value: "bookBuild", text: "Book Build"},
        {key: "8", value: "buildToday", text: "Build today"},
        {key: "9", value: "seniorBuilder", text: "Senior Builder"},
        {key: "10", value: "testingRequired", text: "Testing Required"},
        {key: "11", value: "SEOInvoice", text: "SEO & invoice"},
    ];

    const projectStatusOptions = [
        {key: "1", value: "newEnquiry", text: "New enquiry"},
        {key: "2", value: "proposalSharedWithClient", text: "Proposal shared with client"},
        {key: "3", value: "readyForDepositInvoice", text: "Ready for deposit invoice"},
        {key: "4", value: "depositSent", text: "Deposit sent"},
        {key: "5", value: "readyForProductionHandover", text: "Ready for production handover"},
        {key: "6", value: "awaitingLayoutApprovalFromClient", text: "Awaiting layout approval from client"},
        {key: "7", value: "sketchesApproved", text: "Sketches approved - Content form required"},
        {key: "8", value: "contentSharedWithClient", text: "Content form shared with client"},
        {key: "11", value: "contentApprovedForBuild", text: "Content approved for build"},
        {key: "9", value: "websiteLinkSharedWithClient", text: "Website link shared with client"},
        {key: "10", value: "readyForSEO&InvoiceLater", text: "Ready for SEO & Invoice (to go live at later date)"},
        {key: "11", value: "readyForSEO&Invoice", text: "Ready for SEO & Invoice"},
    ];

    const deadlineOptions = [
        {key: "1", value: "contentDeadline", text: "Client's Content Deadline"},
        {key: "2", value: "revisionDeadline", text: "Next Revision Session "},
        {key: "3", value: "buildDeadline", text: "Build Date"},
    ];


    const presetSteps = [
        {key: "1", value: "presetClientRevSession", text: "Client revision session"},
        {key: "2", value: "presetClientRevSessionC", text: "Revision session completed"},
        {key: "3", value: "presetHandBackToNbTeam", text: "Hand back to NB team"},

    ];

    const emails = [
        {key: "1", value: "Book a call to confirm layout", text: "Book a call to confirm layout"},
        {key: "2", value: "Revision session complete", text: "Revision session complete"},
        {key: "3", value: "Revision session", text: "Revision session"},
        {key: "4", value: "Your project is complete", text: "Your project is complete"},
        {key: "5", value: "Time for handover call", text: "Time for handover call"},
        {key: "6", value: "Website ready for review, revision session", text: "Website ready for review, revision session"},
        {key: "7", value: "Website built, senior designer reviewing", text: "Website built, senior designer reviewing"},
        {key: "8", value: "Content approved - build booked for...", text: "Content approved - build booked for..."},
        {key: "9", value: "We're reviewing your content", text: "We're reviewing your content"},
        {key: "10", value: "Your content form is now ready", text: "Your content form is now ready"},
        {key: "11", value: "We're putting content form together", text: "We're putting content form together"},
        {key: "12", value: "Deposit received, project handed over to build", text: "Deposit received, project handed over to build"},
        {key: "13", value: "Deposit invoice sent", text: "Deposit invoice sent"},
        {key: "14", value: "Proposal ready to view", text: "Proposal ready to view"},
        {key: "15", value: "Proposal in progress", text: "Proposal in progress"},
    ];

    console.log(props.c);
    const { register, control, handleSubmit, setValue, getValues, reset } = useForm({
        defaultValues: {
            premade: props.c && props.c.premade || false,
            premadeStep: props.c && props.c.premadeStep || "",
            for: props.c && props.c.for || "",
            heading: props.c && props.c.heading || "",
            body: props.c && props.c.body || "",
            actionRequired: props.c && props.c.actionRequired || false,
            actionText: props.c && props.c.actionText || "",
            changeTo: props.c && props.c.changeTo || "",
            actionLink: props.c && props.c.actionLink || "",
            markAsInProgressStep: props.c && props.c.markAsInProgressStep || "",
            markAsInProgressDeadline: props.c && props.c.markAsInProgressDeadline|| "",
            markAsCompleteAction: props.c && props.c.markAsCompleteAction || "",
            markAsCompleteStep: props.c && props.c.markAsCompleteStep || "",
            skip: props.c && props.c.skip || false,
            notificationTag: props.c && props.c.notificationTag || "",
            notificationHeading: props.c && props.c.notificationHeading || "",
            notificationBody: props.c && props.c.notificationBody || "",
            email: props.c && props.c.email || "",
            endRevSession: props.c && props.c.endRevSession || false,
            shareWithClient: props.c && props.c.shareWithClient || "",
        }
    });


    console.log(props.c && props.c.heading)
    console.log(getValues("heading"));

    const onSubmit = (data,e) => {
        if(data.premadeStep) {

        } else {
            const newComponent = {
                id: props.c.id,
                ...data,
            };
            for(let val in newComponent) {
                if(newComponent[val] === undefined) {
                    newComponent[val] = "";
                }
            }
            props.editComponent(newComponent, newComponent.for === "client" ? "clientComponents": "devComponents", props.id)
        }
        reset();


    };

    const onToggle = () => {
        reset();
        props.toggleEdit();
    }


    return (
        <div className="journey-form">
            <h4>{props.edit ? "EDIT" : "ADD NEW"} STEP</h4>
            <form action="" onSubmit={handleSubmit(onSubmit)} className={"ui form"}>
                <Form.Field>
                    <div className="">
                        <div className="form-switch-label-journey">Premade step</div>
                        <label className="form-switch" >
                            <input type="checkbox" ref={register} name="premade" onClick={()=>setUsePremadeStep(!usePremadeStep)}/>
                            <div className="form-slider form-round"></div>
                        </label>
                    </div>
                </Form.Field>
                {usePremadeStep ?
                    <Form.Field>
                        <label>Pick premade step: </label>
                        <Controller
                            placeholder="Pick a premade step"
                            control={control}
                            name="premadeStep"
                            as={SelectHook}
                            setValue={setValue}
                            value={getValues("premadeStep")}
                            options={presetSteps}
                        />
                    </Form.Field>
                    :
                    <>
                        <Form.Field>
                            <label>For</label>
                            <Controller
                                placeholder="Client or team..."
                                control={control}
                                name="for"
                                as={SelectHook}
                                setValue={setValue}
                                value={getValues("for")}
                                options={typeOptions}
                            />
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <label>Heading</label>
                            <input type="text"
                                   placeholder={"Write or paste text in here"}
                                   ref={register}
                                   name="heading"
                                   autoComplete={"off"}
                                   required={true}
                            />
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <label>Body</label>
                            <textarea
                                ref={register}
                                placeholder={"Write or paste text in here"}
                                name="body"
                                rows="6"
                                required={true}
                            />
                        </Form.Field>
                        <div style={{padding: "20px 5px 30px 5px"}}>
                            <hr/>
                        </div>
                        <Form.Field>
                            <div className="">
                                <div className="form-switch-label-journey">Action required?</div>
                                <label className="form-switch" >
                                    <input type="checkbox" ref={register} name="actionRequired" onClick={()=>setActionRequired(!actionRequired)}/>
                                    <div className="form-slider form-round"></div>
                                </label>
                            </div>
                        </Form.Field>

                        {actionRequired &&
                        <>
                            <Form.Field>
                                <label>Action text</label>
                                <input type="text"
                                       placeholder={"Write or paste text in here"}
                                       ref={register}
                                       name="actionText"
                                       autoComplete={"off"}
                                       required={true}
                                />
                            </Form.Field>
                            <br/>
                            <Form.Field>
                                <label>Change project status to...</label>
                                <Controller
                                    placeholder="Pick project status"
                                    control={control}
                                    name="changeTo"
                                    as={SelectHook}
                                    setValue={setValue}
                                    value={getValues("changeTo")}
                                    options={projectStatusOptions}
                                />
                            </Form.Field>
                            <br/>
                            <Form.Field>
                                <label>Share with client</label>
                                <Controller
                                    placeholder="Pick section to share"
                                    control={control}
                                    name="shareWithClient"
                                    as={SelectHook}
                                    setValue={setValue}
                                    value={getValues("shareWithClient")}
                                    options={shareWithClient}
                                />
                            </Form.Field>
                            <br/>
                            <Form.Field>
                                <div className="">
                                    <div className="form-switch-label-journey">End current revission session?</div>
                                    <label className="form-switch" >
                                        <input type="checkbox" ref={register} name="endRevSession" />
                                        <div className="form-slider form-round"></div>
                                    </label>
                                </div>
                            </Form.Field>
                            <br/>
                            <Form.Field>
                                <label>Action link</label>
                                <input type="text"
                                       placeholder={"https://"}
                                       ref={register}
                                       name="actionLink"/>
                            </Form.Field>
                            <br/>
                        </>
                        }
                        <div style={{padding: "0px 5px 30px 5px"}}>
                            <hr/>
                        </div>
                        <Form.Field>
                            <label>Mark as in progress when another step is complete</label>
                            <Controller
                                placeholder="Pick a step"
                                control={control}
                                name="markAsInProgressStep"
                                as={SelectHook}
                                setValue={setValue}
                                value={getValues("markAsInProgressStep")}
                                options={steps}
                            />
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <label>Mark as in progress on the day of deadline</label>
                            <Controller
                                placeholder="Pick a deadline"
                                control={control}
                                name="markAsInProgressDeadline"
                                as={SelectHook}
                                setValue={setValue}
                                value={getValues("markAsInProgressDeadline")}
                                options={deadlineOptions}
                            />
                        </Form.Field>
                        <br/>
                        <div style={{padding: "0px 5px 30px 5px"}}>
                            <hr/>
                        </div>
                        <Form.Field>
                            <div className="">
                                <div className="form-switch-label-journey">Mark step as complete when action is taken</div>
                                <label className="form-switch" >
                                    <input type="checkbox" ref={register} name="markAsCompleteAction"/>
                                    <div className="form-slider form-round"></div>
                                </label>
                            </div>
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <label>Mark as complete when another step is complete</label>
                            <Controller
                                placeholder="Pick a step"
                                control={control}
                                name="markAsCompleteStep"
                                as={SelectHook}
                                setValue={setValue}
                                value={getValues("markAsCompleteStep")}
                                options={steps}
                            />
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <div className="">
                                <div className="form-switch-label-journey">Allow user to skip this step</div>
                                <label className="form-switch" >
                                    <input type="checkbox" ref={register} name="skip"/>
                                    <div className="form-slider form-round"></div>
                                </label>
                            </div>
                        </Form.Field>
                        <div style={{padding: "0px 5px 30px 5px"}}>
                            <hr/>
                        </div>
                        <Form.Field>
                            <label>Notification tag</label>
                            <Controller
                                placeholder="Pick a notification"
                                control={control}
                                name="notificationTag"
                                as={SelectHook}
                                setValue={setValue}
                                value={getValues("notificationTag")}
                                options={notificationOptions}
                            />
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <label>Notification heading</label>
                            <input type="text"
                                   placeholder={"Write or paste text in here"}
                                   ref={register}
                                   name="notificationHeading"
                                   autoComplete={"off"}
                            />
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <label>Notification body</label>
                            <textarea
                                ref={register}
                                name="notificationBody"
                                rows="6"
                                placeholder={"Write or paste text in here"}
                            />
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <label>Client auto email</label>
                            <Controller
                                placeholder="Pick a notification"
                                control={control}
                                name="email"
                                as={SelectHook}
                                setValue={setValue}
                                value={getValues("email")}
                                options={emails}
                            />
                        </Form.Field>
                        <br/>
                    </>
                }
                {props.edit ?
                    <>
                        <Button type='submit' className="fluid">Save Step</Button>
                        <br/>
                        <Button onClick={onToggle}  className="fluid">Reset</Button>
                    </>
                    :
                    <Button type='submit' className="fluid">Create Step</Button>
                }
            </form>
            <br/>
            <br/>
        </div>
    );
};

export default JFormE;
