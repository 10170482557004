import React, {Component} from 'react';
import "./admin-settings.css";
import {Link} from "react-router-dom";
import {capitalize} from "../../../utils/helpers";

class AdminSettingsTableC extends Component {

    render() {
        const { components, deleteComponent} = this.props;
        const componentList = components.map(component => (
            <tr key={component.id}>
                <td>{component.title}</td>
                <td>{capitalize(component.category)}</td>
                <td>
                    <Link to={`/dashboard/components/edit/${component.id}`} className={"admin-settings-table-edit"}>Edit</Link>
                    <span onClick={() => deleteComponent(component.id, component.fileName, component.iconName)} className={"admin-settings-table-del"}>Delete</span>
                </td>
            </tr>
        ));
        return (
            <div>
                <table className={"admin-settings-table"}>
                    <tbody>
                    {componentList}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AdminSettingsTableC;