import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withFirebase } from "react-redux-firebase";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

class NoProject extends Component {
  handleLogout = async () => {
    await this.props.firebase.logout();
    this.props.history.push(`/`);
  };

  render() {
    return (
      <div id="main">
        <div className="fof">
          <h1 style={{ color: "#F4A62B" }}>We are working on it.</h1>
          <h1>Your project is currently not ready for you to view it.</h1>
          <br />
          <Button
            content={"Logout"}
            size={"huge"}
            onClick={() => this.handleLogout()}
          />
        </div>
      </div>
    );
  }
}

export default compose(withRouter, withFirebase)(NoProject);
