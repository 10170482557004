import React, {Component} from 'react';

import "./dashboard-users-table.css"

import FormNewButton from "../../form-components/form-new-button/form-new-button";
import FormEditButton from "../../form-components/form-edit-button/form-edit-button";
import FormDeleteButton from "../../form-components/form-delete-button/form-delete-button";

class DashboardUsersTable extends Component {
    render() {

        const {header, filter, users, deleteUser} = this.props;

        const userList = users.filter(user => user.role === filter)
            .map(user => (
                <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                        <FormEditButton link={`users/edit/${user.id}`} title="Edit"/>
                        <FormDeleteButton click={() => deleteUser(user.id, user.role)} title="Delete"/>
                    </td>
                </tr>
            ));



        return (
            <div className="column eight wide">
                <h3>{header}</h3>
                <table className="ui striped table dashboard-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>E-mail</th>
                            <th>Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {userList}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default DashboardUsersTable;