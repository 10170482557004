import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyA_wy7mK-3jNG8Hn_WdWSKYWXhDHMlH8qg",
  authDomain: "david-and-george-cms.firebaseapp.com",
  databaseURL: "https://david-and-george-cms.firebaseio.com",
  projectId: "david-and-george-cms",
  storageBucket: "david-and-george-cms.appspot.com",
  messagingSenderId: "832354486911",
  appId: "1:832354486911:web:c001eee0a8671d0bd9cd4a",
  measurementId: "G-FTPNWBF603",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.functions();

export default firebase;
