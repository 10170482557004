import React, { Component } from "react";

import "./component-form.css";

import FormInput from "../../form-components/form-input/form-input";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import FormHeader from "../../layout-components/form-header/form-header";
import FormSelect from "../../form-components/form-select/form-select";
import { createPricing, updatePricing } from "../../../actions/pricing-actions";
import { combineValidators, isRequired } from "revalidate";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loading from "../../loading/loading";
import FormDropzone from "../../form-components/form-dropzone/form-dropzone";
import {
  createComponent,
  updateComponent,
} from "../../../actions/component-actions";
import { Image, Label } from "semantic-ui-react";
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match && ownProps.match.params.id;
  let component = {};
  let header = "New";
  let button = "Create";

  if (id) {
    component =
      state.firestore.ordered.components &&
      state.firestore.ordered.components.filter(
        (component) => component.id === id
      )[0];
    header = "Edit";
    button = "Update";
  }

  return {
    id: id,
    initialValues: component,
    header: header,
    button: button,
  };
};

const actions = {
  createComponent,
  updateComponent,
};

const categories = [
  { key: "1", text: "Headings", value: "Headings" },
  { key: "2", text: "Text", value: "Text" },
  { key: "3", text: "Lists & Menus", value: "Lists & Menus" },
  { key: "4", text: "Quotes", value: "Quotes" },
  { key: "5", text: "Banners", value: "Banners" },
  { key: "6", text: "Images", value: "Images" },
  {
    key: "7",
    text: "Videos",
    value: "Videos",
  },
  { key: "8", text: "Galleries", value: "Galleries" },
  {
    key: "9",
    text: "Logos",
    value: "Logos",
  },
  { key: "10", text: "Clickthrough Images", value: "Clickthrough Images" },
  { key: "11", text: "Buttons & CTAs", value: "Buttons & CTAs" },
  { key: "12", text: "Lines & Dividers", value: "Lines & Dividers" },
  { key: "13", text: "Actions", value: "Actions" },
  {
    key: "14",
    text: "Social Media & Integrations",
    value: "Social Media & Integrations",
  },
  {
    key: "15",
    text: "Blog, Events & Podcast",
    value: "Blog, Events & Podcast",
  },
  { key: "16", text: "Ecommerce", value: "Ecommerce" },
  { key: "17", text: "Navigation", value: "Navigation" },
  { key: "18", text: "Footers", value: "Footers" },
  { key: "19", text: "Premium", value: "Premium" },
  { key: "20", text: "Misc", value: "Misc" },
];

const validate = combineValidators({
  title: isRequired("Title"),
  type: isRequired("Type"),
  category: isRequired("Category"),
});

class ComponentForm extends Component {
  state = {
    files: this.props.id || null,
  };

  onImageUpload = (files, type) => {
    this.setState({ [type]: files });
  };

  componentWillUnmount() {
    if (this.state.files && this.state.files !== this.props.id) {
      this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  }

  onFormSubmit = async (values) => {
    if (this.state.files === null) {
      throw new SubmissionError({
        _error: "Some image is missing",
      });
    }
    if (this.props.id) {
      if (this.state.files === this.props.id) {
        await this.props.updateComponent(values, false, false);
      } else {
        await this.props.updateComponent(
          values,
          this.state.files[0],
          this.state.files[0].name
        );
      }
    } else {
      await this.props.createComponent(
        values,
        this.state.files[0],
        this.state.files[0].name
      );
    }

    this.props.history.push(`/dashboard/admin-settings/sketch-components`);
  };

  render() {
    const {
      invalid,
      submitting,
      pristine,
      error,
      button,
      header,
      handleSubmit,
      initialValues,
      id,
    } = this.props;

    if (id && !initialValues) {
      return <Loading />;
    }

    return (
      <div className="ui container form-container">
        <FormHeader
          url="/dashboard/admin-settings/sketch-components"
          title={`${header} Component`}
        />
        <FormDropzone
          uploadImage={this.onImageUpload}
          text={"Upload Component Image"}
          type={"files"}
        />
        {this.state.files !== null && (
          <Image
            src={this.state.files[0].preview}
            style={{ maxHeight: "250px", margin: "auto", marginBottom: "15px" }}
          />
        )}
        {this.state.files === id && id && (
          <Image
            src={initialValues.image}
            style={{ maxHeight: "250px", margin: "auto", marginBottom: "15px" }}
          />
        )}
        <form
          action=""
          className="ui form"
          autoComplete="off"
          onSubmit={handleSubmit(this.onFormSubmit)}
        >
          <Field
            name="title"
            label="Title"
            component={FormInput}
            placeholder="Navbar"
          />
          <Field
            name="url"
            label="Build Instructions"
            component={FormInput}
            placeholder="www.example.co.uk"
          />
          <Field
            name="note"
            label="Note"
            component={FormInput}
            placeholder="Lorem ipsum..."
          />
          <Field
            placeholder="Choose category"
            label="Category"
            name="category"
            options={categories}
            component={FormSelect}
          />
          {error && (
            <Label basic color="red">
              {error}
            </Label>
          )}
          <FormSubmitButton
            disabled={invalid || submitting}
            text={`${button} Component`}
          />
        </form>
      </div>
    );
  }
}

export default compose(
  withRouter,
  firestoreConnect([{ collection: "components" }]),
  connect(mapStateToProps, actions),
  reduxForm({ form: "componentForm", validate })
)(ComponentForm);
