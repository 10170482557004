import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Select } from "semantic-ui-react";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import SelectHook from "./select-hook";
import { connect } from "react-redux";
import { updateProject } from "../../../actions/project-actions";
import FormBrandingFile from "./form-branding-file";
import {
  uploadFileBranding,
  deleteFileBranding,
} from "../../../actions/tasks-actions";
import { changeField } from "../../../actions/project-actions";

const ProposalBeautifulForm = ({
  project,
  updateProject,
  uploadFileBranding,
  deleteFileBranding,
  changeField,
}) => {
  const { register, control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      ykdr: project.ykdr || "",
      branding: project.branding || "",
      yourInspiration: project.yourInspiration || "",
      ourInspiration: project.ourInspiration || "",
      content: project.content || "",
    },
  });

  const onSubmit = (data) => {
    const updatedProject = { ...project, ...data };
    updateProject(updatedProject);
  };

  return (
    <div className="proposal-form-border">
      <h4 className="project-proposal-section-header">
        Let's make it beautiful
      </h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <Controller
            setValue={setValue}
            label="Your key design requirements"
            value={getValues.ykdr}
            name="ykdr"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <Controller
            setValue={setValue}
            value={getValues.branding}
            label="Branding"
            name="branding"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <br />
        <FormBrandingFile
          project={project}
          uploadFile={uploadFileBranding}
          deleteFile={deleteFileBranding}
          changeField={changeField}
          client={false}
        />
        <br />
        <br />
        <Form.Field>
          <Controller
            setValue={setValue}
            label="Inspiration for new site - provided by you"
            value={getValues.yourInspiration}
            name="yourInspiration"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />

        <Form.Field>
          <Controller
            setValue={setValue}
            value={getValues.ourInspiration}
            label="Inspiration for new site - provided by us"
            name="ourInspiration"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <Controller
            setValue={setValue}
            label="Content"
            value={getValues.content}
            name="content"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Button type="submit" className="fluid">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default connect(null, {
  updateProject,
  uploadFileBranding,
  deleteFileBranding,
  changeField,
})(ProposalBeautifulForm);
