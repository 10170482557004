import React, { Component } from "react";
import firebase from "../../../firebase";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { manageLinks, changeGoogle } from "../../../actions/project-actions";
import cuid from "cuid";

const actions = {
  manageLinks,
  changeGoogle,
};

const urlPrepend = (s) => {
  if (!s.match(/^[a-zA-Z]+:\/\//)) {
    return "https://" + s;
  }
  return s;
};

class ProjectOverviewLinks extends Component {
  state = {
    links: [],
    clientGoogleDrive: "",
    newLinkName: "",
    newLinkUrl: "",
    editLinkUrl: "",
    editId: "",
    changeGoogle: false,
  };

  addLink = async (e) => {
    e.preventDefault();

    const newLink = {
      title: this.state.newLinkName,
      url: this.state.newLinkUrl,
      id: cuid(),
    };

    const newLinks = [...this.state.links, newLink];

    await this.props.manageLinks(this.props.match.params.id, newLinks);
    await this.setState({
      links: newLinks,
      newLinkName: "",
      newLinkUrl: "",
    });
  };

  removeLink = async (id) => {
    const newLinks = this.state.links.filter((link) => link.id !== id);

    await this.props.manageLinks(this.props.match.params.id, newLinks);
    await this.setState({
      links: newLinks,
    });
  };

  updateLink = async (e, id) => {
    e.preventDefault();

    let updatedLink = this.state.links.find((link) => link.id === id);
    updatedLink = {
      ...updatedLink,
      url: this.state.editLinkUrl,
    };

    console.log(updatedLink);

    const newLinks = this.state.links.map((link) =>
      link.id === id ? updatedLink : link
    );

    await this.props.manageLinks(this.props.match.params.id, newLinks);
    await this.setState({
      links: newLinks,
      editId: "",
    });
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);

    const db = firebase.firestore();

    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const projectSnap = await projectQuery.get();
    const data = projectSnap.data();

    await this.setState({
      links: data.links || [],
    });
  }

  render() {
    return (
      <div className="project-overview-section">
        <div className="project-overview-section-header">
          <h2>Links</h2>
          <div className="project-overview-section-content-link-form">
            <p>Add new link</p>
            <form action="" onSubmit={this.addLink}>
              <input
                onChange={(e) => this.setState({ newLinkName: e.target.value })}
                value={this.state.newLinkName}
                placeholder="Link title"
                type="text"
              />
              <input
                onChange={(e) => this.setState({ newLinkUrl: e.target.value })}
                value={this.state.newLinkUrl}
                placeholder={"Link URL"}
                type="text"
              />
              <button type={"submit"} onClick={this.addLink}>
                SAVE
              </button>
            </form>
          </div>
        </div>
        <div className="project-overview-section-content">
          {/*{this.state.clientGoogleDrive.length !== 0 && !this.state.changeGoogle &&*/}
          {/*<div className="project-overview-section-content-link">*/}
          {/*    <a href={urlPrepend(this.state.clientGoogleDrive)} target="_blank">*/}
          {/*        Google Drive*/}
          {/*    </a>*/}
          {/*    <div className="project-overview-section-content-link-func">*/}
          {/*        <span onClick={() => this.setState({changeGoogle: true})}>EDIT</span>*/}
          {/*        <span onClick={this.deleteGoogle}>DELETE</span>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*}*/}
          {/*{this.state.changeGoogle &&*/}
          {/*<div className="project-overview-section-content-edit-form">*/}
          {/*    <form action="" onSubmit={this.updateGoogle}>*/}
          {/*        <input value={this.state.clientGoogleDrive} onChange={(e) => this.setState({clientGoogleDrive: e.target.value})} type="text"/>*/}
          {/*    </form>*/}
          {/*    <span onClick={() => this.setState({changeState: false, googleLink: ""})}>CANCEL</span>*/}
          {/*</div>*/}

          {this.state.links.map((link) => {
            if (this.state.editId === link.id) {
              return (
                <div
                  key={link.id}
                  className="project-overview-section-content-edit-form"
                >
                  <form action="" onSubmit={(e) => this.updateLink(e, link.id)}>
                    <input
                      value={this.state.editLinkUrl}
                      onChange={(e) =>
                        this.setState({ editLinkUrl: e.target.value })
                      }
                      type="text"
                    />
                  </form>
                  <span
                    onClick={() =>
                      this.setState({ editId: "", editLinkUrl: "" })
                    }
                  >
                    CANCEL
                  </span>
                </div>
              );
            } else {
              return (
                <div
                  key={link.id}
                  className="project-overview-section-content-link"
                >
                  <a href={urlPrepend(link.url)} target="_blank">
                    {link.title}
                  </a>
                  <div className="project-overview-section-content-link-func">
                    <span
                      onClick={() =>
                        this.setState({
                          editId: link.id,
                          editLinkUrl: link.url,
                        })
                      }
                    >
                      EDIT
                    </span>
                    <span onClick={() => this.removeLink(link.id)}>DELETE</span>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect([{ collection: "projects" }]),
  withRouter,
  connect(null, actions)
)(ProjectOverviewLinks);
