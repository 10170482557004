import {createReducer} from "../utils/reducer-utils";
import {CREATE_PROJECT, DELETE_PROJECT, UPDATE_PROJECT} from "../reduxConstants";


const initialState = [
    {
        id:  "1",
        title: "Whiskey in the Jar",
        created: "21/09/19",
        creator: "George",
        employees: "John",
        client: "Pedro",
        services: "SEO",
        package: "Basic Website",
        gDrive: "www.google.com",
        otherLinks: "www.facebook.com",
        notes: "Blah Blah Blah",
        type: "archived",

    },
    {
        id:  "2",
        title: "Chocolate Factory",
        created: "15/11/18",
        creator: "George",
        employees: "John",
        client: "Pedro",
        services: "SEO",
        package: "Basic Website",
        gDrive: "www.google.com",
        otherLinks: "www.facebook.com",
        notes: "Blah Blah Blah",
        type: "recent",

    }
];

const createProject = (state, payload) => {
    return [...state, payload.project];
};

const updateProject = (state, payload) => {
    return [
        ...state.filter( project => project.id !== payload.project.id),
        payload.user
    ]
};

const deleteProject = (state, payload) => {
    return [
        ...state.filter(project => project.id !== payload.id)
    ]
};

export default createReducer(initialState, {
    [CREATE_PROJECT]: createProject,
    [UPDATE_PROJECT]: updateProject,
    [DELETE_PROJECT]: deleteProject,
});