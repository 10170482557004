import React, {Component} from 'react';
import {Grid} from "semantic-ui-react";
import firebase from "../../../firebase";
import cuid from "cuid";
import "./wireframes.css"
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";
import {createPage} from "../../../actions/pages-actions";
import Loading from "../../loading/loading";

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const projects = state.firestore.ordered.projects;

    const pages = projects && projects.filter(project => project.id === id)[0].pages;

    return  {
        pages,
        id,
    }


};

const actions = {
    createPage,
};

class Wireframes extends Component {

    state = {
        pageTitle: '',
    };

    handleChange =  async event => {
        await this.setState({pageTitle: event.target.value});
    };

    handleSubmit = async event => {
        event.preventDefault();
        const pageName = this.state.pageTitle;

        if(pageName.length === 0) {
            return;
        }

        await this.props.createPage(pageName,this.props.id);
        await this.setState({pageTitle: '' });


    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`projects`);
    }

    async componentWillUnmount() {
        const {firestore} = this.props;
        await firestore.unsetListener(`projects`);
    }


    render() {

        return (
            <div className="wireframes-main-background">
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <div className={"wireframes-main-back"}>
                                <Link to={`/dashboard/project-overview/${this.props.match.params.id}`}>
                                    <img src={"/assets/icons/arrow.svg"} style={{height: "34px"}} alt=""/>
                                    <span className={"wireframes-main-back-span"}>Back</span>
                                </Link>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={9}>
                            <div className={"wireframes-main-title"}>
                                <h2>Pages so far<i className="arrow right icon wireframes-main-arrow"></i></h2>
                                <p>
                                    Always start with a premade page layout to save time<br/>
                                    and give yourself a strong foundation to build on.
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <div className="wireframes-main-list">
                                <form action="" onSubmit={this.handleSubmit}>
                                    <input
                                        value={this.state.pageTitle}
                                        autoFocus
                                        type="text"
                                        onChange={this.handleChange}
                                        placeholder="Add Page..."
                                        className={"wireframes-main-form"}
                                    />
                                </form>
                                {this.props.pages && this.props.pages.map((page) => (
                                    <Link
                                        key={page.id}
                                        className="wireframes-main-list-item wireframes-main-list-item-link"
                                        to={`/dashboard/project-overview/${this.props.id}/wireframes/build/${page.id}`}
                                    >{page.pageName}
                                    </Link>
                                ))}

                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withFirestore,
    firestoreConnect([{collection: "projects"}]),
    connect(mapStateToProps, actions)

)(Wireframes);

