import React, {Component} from "react";
import {Form, Modal} from "semantic-ui-react";
import cuid from "cuid";

class PricingFormModal extends Component {

    state = {
        title: "",
        description: "",
        id: "",
        clicked: false,

    };

    handleSubmit = e => {
        e.preventDefault();
        if(this.state.title === "") {
            return;
        }

        let inc = {
            title: this.state.title,
            description: this.state.description,
            id: this.state.id,
        };

        this.props.handleSubmit(inc);
        this.setState({
            title: "",
            description: "",
            clicked: false,
        });
        this.props.handleClose()
    };

    handleSetup = () => {
        if(this.props.inc) {
            this.setState({
                title: this.props.inc.title,
                description: this.props.inc.description,
                id: this.props.inc.id,
                clicked: true
            })
        } else {
            this.setState({
                title: "",
                description: "",
                id: cuid(),
                clicked: true
            })
        }

    };

    componentDidUpdate() {
    if(this.props.open) {
        if(!this.state.clicked) {
            this.handleSetup()
        }
        }
    }


    render() {

        const {open, handleClose} = this.props;
        const {title, description} = this.state;

        console.log(this.props.handleSubmit)

        return (
            <Modal open={open} closeIcon onClose={handleClose}>
                <Modal.Header>Create an Addons</Modal.Header>
                <Modal.Content >
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Input onChange={e => this.setState({title: e.target.value})} value={title} fluid label='Title' placeholder='Website setup' />
                        <Form.TextArea onChange={e => this.setState({description: e.target.value})} value={description} label='Description' placeholder='Write or paste text in here...' />
                        <Form.Button>Save</Form.Button>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}

export default PricingFormModal;