import React, { Component } from "react";
import {
  addProjectComment,
  updateProjectComment,
  deleteProjectComment,
  addProjectJourney,
} from "../../../actions/project-actions";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import cuid from "cuid";

const mapStateToProps = (state, ownProps) => {
  const profile = state.firebase.profile;
  return {
    profile,
  };
};

const actions = {
  addProjectComment,
  updateProjectComment,
  deleteProjectComment,
  addProjectJourney,
};

class ProjectOverviewComments extends Component {
  state = {
    project: null,
    comments: [],
    showForm: false,
    addText: "",
    editText: "",
    editId: "",
  };

  componentDidMount() {
    this.setState({
      project: this.props.project,
      comments: this.props.project.comments || [],
    });
  }

  submitForm = (id, value, field) => {
    this.props.changeField(id, value, field);
  };
  handleAddChange = async (e) => {
    await this.setState({ addText: e.target.value });
  };
  handleEditChange = async (e) => {
    await this.setState({ editText: e.target.value });
  };
  handleSubmitAdd = async (e) => {
    e.preventDefault();
    const id = cuid();
    await this.props.addProjectComment(
      this.state.project.id,
      this.state.addText,
      id,
      this.props.profile.name
    );
    const newComments = [
      ...this.state.comments,
      { id: id, text: this.state.addText, author: this.props.profile.name },
    ];
    await this.setState({ addText: "", comments: newComments });
  };
  handleShowForm = async (text, id) => {
    await this.setState({ editText: text, showForm: true, showId: id });
  };
  handleSubmitEdit = async (e, commentId) => {
    e.preventDefault();
    await this.props.updateProjectComment(
      this.state.project,
      this.state.editText,
      commentId,
      this.props.profile.name
    );
    const newComments = this.state.comments.map((comment) =>
      comment.id === commentId
        ? {
            id: commentId,
            text: this.state.editText,
            author: this.props.profile.name,
          }
        : comment
    );
    await this.setState({
      editText: "",
      showForm: false,
      comments: newComments,
    });
  };
  handleDelete = async (comment) => {
    await this.props.deleteProjectComment(this.state.project.id, comment);
    const newComments = this.state.comments.filter(
      (oldComment) => oldComment.id !== comment.id
    );
    await this.setState({
      editText: "",
      showForm: false,
      comments: newComments,
    });
  };

  render() {
    return (
      <div>
        <div className="project-overview-section-packages">
          <div className="project-overview-section-packages-header">
            <h2>Team Comments</h2>
            <p>
              These comments are a way to keep track of the project. They are
              not visible to the client.
            </p>
          </div>
          <div
            className="project-overview-section-packages-overview"
            style={{ display: "block", width: "100%" }}
          >
            <div className="project-overview-container-bottom">
              {this.state.comments.map((comment) => (
                <div
                  key={comment.id}
                  style={{ width: "75%" }}
                  className="proposal-comment-flex"
                >
                  <div className="proposal-comment" key={comment.id}>
                    {this.state.showForm && this.state.showId === comment.id ? (
                      <div>
                        <p className={"comment-inside-h"}>{comment.author}</p>
                        <form
                          style={{}}
                          key={comment.id}
                          action=""
                          onSubmit={(e) => {
                            this.handleSubmitEdit(e, comment.id);
                          }}
                        >
                          <input
                            value={this.state.editText}
                            className="comments-add-input-po"
                            placeholder="Add Comment..."
                            onChange={this.handleEditChange}
                            autoFocus="true"
                          />
                        </form>
                        <div className={"comment-inside-f"}>
                          <span
                            onClick={() =>
                              this.handleShowForm(comment.text, comment.id)
                            }
                          >
                            EDIT
                          </span>
                          <span onClick={(e) => this.handleDelete(comment)}>
                            DELETE
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className={"comment-inside"}>
                        <p className={"comment-inside-h"}>{comment.author}</p>
                        <p className={"comment-inside-t"}>{comment.text}</p>
                        <div className={"comment-inside-f"}>
                          <span
                            onClick={() =>
                              this.handleShowForm(comment.text, comment.id)
                            }
                          >
                            EDIT
                          </span>
                          <span onClick={(e) => this.handleDelete(comment)}>
                            DELETE
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="revision-tasks-form proposal-ph"
              style={{ width: "75%", marginTop: "0px" }}
            >
              <form action="" onSubmit={(e) => this.handleSubmitAdd(e)}>
                <textarea
                  onChange={this.handleAddChange}
                  id="text"
                  type="text"
                  required={true}
                  value={this.state.addText}
                  placeholder="Add Comment..."
                  autoComplete="off"
                  style={{ fontSize: "16px", height: "100px" }}
                />
                <div className="revision-tasks-form-form">
                  <div className="revision-tasks-form-control">
                    <div className={"revision-tasks-form-control-func"}>
                      <img
                        height={"32px"}
                        src={"/assets/icons/add.png"}
                        alt="."
                        onClick={this.handleSubmitAdd}
                      />
                      <button type="submit">ADD COMMENT</button>
                    </div>
                    <div className="revision-tasks-form-control-func"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect([
    { collection: "projects" },
    { collection: "users" },
    { collection: "pricing" },
  ]),
  withRouter,
  connect(mapStateToProps, actions)
)(ProjectOverviewComments);
