import React, { Component, Fragment } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Field = ({ field, idx, handleOpenModal, handleDelete }) => (
  <Draggable key={field.id} draggableId={field.id} index={idx}>
    {(provided, snapshot) => (
      <div
        key={field.id}
        className="content-manager-main-list-flex"
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={getStyle(provided.draggableProps.style, snapshot)}
      >
        {field.formField === "section-label" && (
          <div className="content-manager-main-label">
            <span onClick={() => handleOpenModal(field)}>{field.title}</span>
            <i
              className="close icon content-manager-edit"
              onClick={() => handleDelete(field)}
            />
          </div>
        )}
        {field.formField !== "section-label" && (
          <div className="content-manager-main-item">
            <div onClick={() => handleOpenModal(field)}>
              <h4>{field.title}</h4>
              <p>{field.description}</p>
            </div>
            <div
              className="content-manager-main-item-func"
              onClick={() => handleDelete(field)}
            >
              Delete
            </div>
          </div>
        )}
      </div>
    )}
  </Draggable>
);

function getStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.0001s!important`,
  };
}

class DndzoneForm extends Component {
  render() {
    const {
      handleDrop,
      handleDelete,
      fields,
      handleOpenModal,
      toggleModal,
    } = this.props;

    return (
      <div>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={"2"}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className="content-manager-main-list">
                  {fields &&
                    fields.map((field, idx) => (
                      <Field
                        handleDelete={handleDelete}
                        idx={idx}
                        key={field.id}
                        field={field}
                        handleOpenModal={handleOpenModal}
                      />
                    ))}
                  <div className="content-add-section"></div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default DndzoneForm;
