import React, {Component} from 'react';
import {Grid} from "semantic-ui-react";
import {addPremadePage, updatePageName} from "../../../actions/pages-actions";
import "../build/build.css"
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";
import BuildMainMenu from "../../layout-components/build-main-menu/build-main-menu";
import BuildSecondMenu from "../../layout-components/build-second-menu/build-second-menu";
import firebase from "../../../firebase";
import cuid from "cuid";
import DragAndDropZone from "../../drag-and-drop-zone/dndzone";
import {updateComponents,deleteComponents} from "../../../actions/wireframes-actions";
import {deletePremadePage, clearPremadePage, updatePremadePageComponents, updatePremadePageName} from "../../../actions/pages-actions";
import BuildAddDesc from "../../layout-components/build-add-description/build-add-desc";
import {insertComment} from "../../../actions/pages-actions";
import UploadCustom from "../../layout-components/upload-custom/upload-custom"
import {uploadCustom} from "../../../actions/wireframes-actions";
import PremadePagesMenu from "../../layout-components/premade-pages-menu/premade-pages-menu";
import AddPremade from "../../layout-components/add-premade/add-premade";
import UploadPremade from "../../layout-components/upload-premade/upload-premade";

const mapStateToProps = (state,ownProps) => {
    const pageId = ownProps.match.params.id;

    const premadePages = state.firestore.ordered.premadePages;
    const components = state.firestore.ordered.components;

    const defaultComponents = components && components.filter(component => component.type === "default");
    const premadePage = premadePages&& premadePages.filter(page => page.id === pageId)[0];
    let categories = [];
    defaultComponents && defaultComponents.forEach(component => {
        categories.push(component.category);
    });
    categories = [...new Set(categories)];
    return  {
        pageId,
        premadePage,
        defaultComponents,
        categories,
    }
};

const actions = {
    updatePremadePageName,
    updatePremadePageComponents,
    deletePremadePage,
    clearPremadePage,
    insertComment,
    uploadCustom
};



class PremadePagesB extends Component {

    state = {
        showForm: false,
        formName: '',
        componentsToDisplay: [],
        requestName: '',
        description: '',
    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`components`);

        const db = firebase.firestore();

        const projectQuery = await db.collection("premade_pages").doc(this.props.match.params.id);
        const projectSnap = await projectQuery.get();
        const page = projectSnap.data();

        await this.setState({
            componentsToDisplay: page.components,
            formName: page.pageName,
        })

    };

    addWhiteSpace = async () => {
        const newComponent = {
            image: "https://firebasestorage.googleapis.com/v0/b/david-and-george-cms.appspot.com/o/whitespace%2Fwhite-stripe.png?alt=media&token=95f57a21-8bea-4456-8d26-9a0e5e0aca02",
            note: "",
            id: cuid(),
            orderId: cuid(),
        };

        await this.setState(prevState => ({
            componentsToDisplay: [...prevState.componentsToDisplay, newComponent]
        }));


        this.props.updatePremadePageComponents(this.props.pageId, this.state.componentsToDisplay);
    };

    handleClick = async () => {
        await this.setState({showForm: true});
        await this.setState({formName: this.props.pageName});
    };

    handleChange = async (e) => {
        await this.setState({formName: e.target.value})
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.state.formName.length === 0) {
            return;
        }
        await this.props.updatePremadePageName(this.state.formName,this.props.pageId);
        await this.setState({showForm: false});
    };


    handleSubmitDesc = async () => {
        this.props.insertComment(this.props.pageNameDoc, this.state.description, this.props.projectId)

    };

    handleChangeDesc = async (e) => {
        await this.setState({description: e.target.value})
    };

    handleDeleteComp = async component  => {
        const index = this.state.componentsToDisplay.indexOf(component);
        const oldComponentsOrder = [...this.state.componentsToDisplay];
        oldComponentsOrder.splice(index,1);
        await this.setState({componentsToDisplay: oldComponentsOrder});
        await this.props.updatePremadePageComponents(this.props.pageId, oldComponentsOrder);
    };


    uploadCustomComponent = async (image) => {

        const imageLink = await this.props.uploadCustom(image);

        const newComponent = {
            image: imageLink,
            note: "Custom Component",
            url: "",
            id: cuid(),
            orderId: cuid()
        };

        await this.setState(prevState => ({
            componentsToDisplay: [...prevState.componentsToDisplay, newComponent]
        }));
        this.props.updatePremadePageComponents(this.props.pageId, this.state.componentsToDisplay);
    };

    async componentWillUnmount() {
        const {firestore} = this.props;
        await firestore.unsetListener(`components`);
    };


    handleCategoryClick = async category => {
        await this.setState({requestName: category})
    };

    handleBack = async () => {
        await this.setState({requestName: ""})
    };

    handleDrop= async (result) => {
        const {destination, source, draggableId} = result;

        if(!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;

        }

        const oldComponentsOrder = [...this.state.componentsToDisplay];
        const draggedComponent = oldComponentsOrder.filter(component => component.orderId === draggableId)[0];
        oldComponentsOrder.splice(source.index,1);
        oldComponentsOrder.splice(destination.index, 0,draggedComponent );

        await this.setState({componentsToDisplay: oldComponentsOrder});
        await this.props.updatePremadePageComponents(this.props.pageId, oldComponentsOrder);
    };

    deletePremadePage = async () => {
        await this.props.deletePremadePage(this.props.pageId);
        this.props.history.push(`/dashboard/components/premade-pages`)
    };


    clearPremadePage = async () => {
        await this.props.clearPremadePage(this.props.pageId);
        await this.setState({componentsToDisplay: []})
    };

    handleComponent = async component => {
        const {image, note, id,url} = component;

        const newComponent = {
            image,
            note: note || "",
            id,
            url: url || "",
            orderId: cuid()
        };

        await this.setState(prevState => ({
            componentsToDisplay: [...prevState.componentsToDisplay, newComponent]
        }));
        this.props.updatePremadePageComponents(this.props.pageId, this.state.componentsToDisplay);

    };

    render() {


        const { defaultComponents, categories, pageId, premadePage} = this.props;
        const {requestName} = this.state;


        return (
            <div className="page-sketches-main" style={{minHeight: "100%"}}>
                <div className="page-sketches-left">
                    <div className="pages-sketches-left-page">
                        <div className="pages-sketches-left-page-header">
                            {this.state.showForm ?
                                <form className={"page-sketches-editor-form"} action="" onSubmit={this.handleSubmit}>
                                    <input
                                        value={this.state.formName}
                                        autoFocus
                                        type="text"
                                        onChange={this.handleChange}
                                        placeholder="Page Name..."

                                    />
                                </form>
                                :
                                <h2 onClick={this.handleClick}>{this.state.formName}</h2>
                            }
                            <p></p>
                            <div className="pages-sketches-left-page-header-func">
                                <div onClick={this.deletePremadePage}>Clear Page</div>
                                <div onClick={this.clearPremadePage}>Delete Page</div>
                                {/*<div><Link to={`/dashboard/components/premade-pages`}>All Pages</Link></div>*/}
                            </div>
                        </div>
                        <div className="pages-sketches-left-page-images">
                            <DragAndDropZone
                                components={this.state.componentsToDisplay}
                                handleDrop={this.handleDrop}
                                handleDeleteComp={this.handleDeleteComp}
                                disableForm={true}
                            />
                        </div>
                    </div>

                </div>
                <div className="page-sketches-right">
                    {requestName.length === 0 &&
                    <PremadePagesMenu categories={categories}
                                      categoryClick={this.handleCategoryClick}
                                      addWhiteSpace={this.addWhiteSpace}
                    />
                    }
                    {requestName === "upload-custom" &&
                    <UploadCustom
                        handleBack={this.handleBack}
                        uploadCustomComponent={this.uploadCustomComponent}
                    />}
                    {requestName.length !== 0 && requestName !== "add-detail" && requestName !== "upload-custom" && requestName !== "pre-made" &&
                    <BuildSecondMenu
                        handleBack={this.handleBack}
                        handleComponent={this.handleComponent}
                        category={requestName}
                        defaultComponents={defaultComponents}
                    />
                    }
                </div>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withFirestore,
    firestoreConnect([{collection: "projects"},{collection: "components"}]),
    connect(mapStateToProps, actions)

)(PremadePagesB);

