import React from 'react';

import "./form-delete-button.css";

import {Link} from "react-router-dom";

const FormDeleteButton = ({title, click}) => {
    return (
            <button onClick={click} className="ui red button">{title}</button>
    )
};

export default FormDeleteButton;
