import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import firebase from "../../../firebase";
import cuid from "cuid";
import "../wireframes/wireframes.css";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import {
  addPremadePage,
  clearPremadePage,
  deletePremadePage,
} from "../../../actions/pages-actions";
import Loading from "../../loading/loading";

const mapStateToProps = (state, ownProps) => {
  const premadePages =
    state.firestore.ordered && state.firestore.ordered.premade_pages;
  const showPage = premadePages && premadePages[0];

  return {
    premadePages,
    showPage,
  };
};

const actions = {
  addPremadePage,
  clearPremadePage,
  deletePremadePage,
};

class PremadePages extends Component {
  state = {
    pageTitle: "",
    showPage: null,
  };

  handleChange = async (event) => {
    await this.setState({ pageTitle: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const pageName = this.state.pageTitle;

    if (pageName.length === 0) {
      return;
    }

    await this.props.addPremadePage(pageName);
    await this.setState({ pageTitle: "" });
  };

  deletePremadePage = async () => {
    await this.props.deletePremadePage(this.state.showPage.id);
    this.setState({ showPage: null });
  };

  clearPremadePage = async () => {
    await this.props.clearPremadePage(this.state.showPage.id);
    await this.setState((prevState) => ({
      showPage: { ...prevState.showPage, components: [] },
    }));
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`premade_pages`);
  }

  async componentWillUnmount() {
    const { firestore } = this.props;
    await firestore.unsetListener(`premade_pages`);
  }

  render() {
    const { showPage, pageTitle } = this.state;
    const { premadePages } = this.props;

    return (
      <div className="page-sketches-main" style={{ minHeight: "100%" }}>
        <div className="page-sketches-left">
          <div className="page-sketches-left-default-text">
            <Link to="/dashboard/admin-settings/sketch-components">
              <div className={"admin-settings-side-back"}>
                <div>
                  <img src={"/assets/icons/arrow.svg"} alt="" />
                </div>
                <div className={"admin-settings-side-back-text"}>
                  Back to dashboard
                </div>
              </div>
            </Link>
            <h2>Premade Pages</h2>
            <p>This is the place where you can create and edit premade pages</p>
          </div>
          }
        </div>
        <div className="page-sketches-right">
          <div className="page-sketches-right-main-list">
            {premadePages &&
              premadePages.map((page) => (
                <span
                  key={page.id}
                  className={
                    showPage && showPage.id === page.id
                      ? "page-sketches-item-active"
                      : ""
                  }
                >
                  <Link
                    to={`/dashboard/components/wireframes/build/${page.id}`}
                    style={{ color: "white" }}
                  >
                    {page.pageName}
                  </Link>
                </span>
              ))}
            <form action="" onSubmit={this.handleSubmit}>
              <input
                value={pageTitle}
                autoFocus
                type="text"
                onChange={this.handleChange}
                placeholder="Add Page..."
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirestore,
  firestoreConnect([{ collection: "premade_pages" }]),
  connect(mapStateToProps, actions)
)(PremadePages);
