import React from 'react';

import "./search-bar-title.css";

import {Link} from "react-router-dom";

const SearchBarTitle = () => {
    return (

        <div className="ui search-bar-title-logo" style={{width: "20%"}}>
            <Link to="/dashboard/recent">
                <img src={"/assets/logo/logo.png"} alt="Logo"/>
            </Link>
        </div>

    );
};

export default SearchBarTitle;
