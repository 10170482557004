import React from 'react';
import {Form, Label} from 'semantic-ui-react';

const FormTextarea = ({input, type, placeholder, label, meta: {touched, error} }) => (
    <Form.Field error={touched && !!error}>
        <label >{label}</label>
        <textarea {...input} placeholder={placeholder} type={type}/>
        {touched && error && <Label basic color='red'>{error}</Label>}
    </Form.Field>
);


export default FormTextarea;
