import cuid from "cuid";
import { toastr } from "react-redux-toastr";
import firebase from "../firebase";

export const updateFormComponent = (fields, projectId, toaster = false) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`projects/${projectId}`, {
        fields: fields,
      });

      console.log("test");
      if (toaster) {
        toastr.success("Success", "Changes to this field saved successfully");
      }
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const uploadCustomFile = (image, formId) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const options = {
      name: image.path,
    };

    try {
      const uploadedFile = await firebase.uploadFile(
        `${formId}-custom`,
        image,
        null,
        options
      );
      let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
      console.log(downloadURL);
      return downloadURL;
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const deleteCustomFile = (fileName, formId) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    try {
      await firebase.deleteFile(`${formId}-custom/${fileName}`);
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};
