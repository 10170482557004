import React from "react";
import { Select } from "semantic-ui-react";

const SelectHook = ({
  options,
  placeholder,
  setValue,
  value,
  name,
  multiple,
  search,
}) => {
  return (
    <Select
      value={value}
      options={options}
      placeholder={placeholder}
      onChange={(e, d) => setValue(name, d.value)}
      multiple={multiple || false}
      search={search || false}
    />
  );
};

export default SelectHook;
