import React, {Component} from 'react';

class NotificationsList extends Component {



    render() {
        const {notifications, filter} = this.props;
        let notificationsList = [];

        if(filter === "all") {
            notificationsList = [...notifications];
        } else {
            notificationsList = notifications.filter(n => n.category === filter);
        }

        return (
            <div className="notifications-list">
                {notificationsList.map(n => (
                    <div className="notifications-list-item" key={n.id}>
                        <div className="notifications-list-item-header">
                            <h5>{n.title}</h5>
                            <p>{n.body}</p>
                        </div>
                        <div>
                            <img src="/assets/icons/current.png" alt=""/>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default NotificationsList;