import {createReducer} from "../utils/reducer-utils";
import {CREATE_USER, DELETE_USER, UPDATE_USER} from "../reduxConstants";


const initialState = [
    {
        id: "1",
        name: "John Doe",
        phone: "075654554646",
        email: "john.doe@domain.com",
        password: "Petr1234",
        role: "client"
    },
    {
        id: "2",
        name: "Jane Doe",
        phone: "075654554646",
        email: "jane.doe@domain.com",
        password: "Petr1234",
        role: "client"
    },
    {
        id: "3",
        name: "John Smith",
        phone: "075654554646",
        email: "john.smith@domain.com",
        password: "Petr1234",
        role: "employee"
    }
];

const createUser = (state, payload) => {
    return [...state, payload.user];
};

const updateUser = (state, payload) => {
    return [
        ...state.filter( user => user.id !== payload.user.id),
        payload.user
    ]
};

const deleteUser = (state, payload) => {
    return [
        ...state.filter(user => user.id !== payload.id)
    ]
};

export default createReducer(initialState, {
    [CREATE_USER]: createUser,
    [UPDATE_USER]: updateUser,
    [DELETE_USER]: deleteUser,
});