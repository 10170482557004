import React, { Component, useState } from "react";
import { Form, Input, Modal } from "semantic-ui-react";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import { compose } from "redux";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import cuid from "cuid";
import { changeField } from "../../../actions/project-actions";

const ModalForm = ({
  handleSubmitModal,
  val,
  handleModalState,
  showModal,
  toggleModal,
}) => (
  <Modal open={showModal} onClose={toggleModal}>
    <Modal.Header>Manage deposit</Modal.Header>
    <Modal.Content>
      <Form action="" onSubmit={handleSubmitModal}>
        <Form.Field>
          <Input
            placeholder={"999"}
            type="text"
            required
            value={val}
            onChange={handleModalState}
          />
        </Form.Field>
        <Form.Field>
          <FormSubmitButton text={"Submit"} />
        </Form.Field>
      </Form>
    </Modal.Content>
  </Modal>
);

const FormModal = ({
  handleSubmit,
  open,
  setValue,
  per,
  price,
  title,
  description,
  handleClose,
}) => (
  <Modal open={open} closeIcon onClose={() => handleClose("")}>
    <Modal.Header>New Addon</Modal.Header>
    <Modal.Content>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          onChange={(e) => setValue("addTitle", e.target.value)}
          value={title}
          required
          fluid
          label="Title"
          placeholder="Website setup"
        />
        <Form.Input
          onChange={(e) => setValue("addPrice", e.target.value)}
          value={price}
          required
          fluid
          label="Price"
          placeholder="£111"
        />
        <Form.Input
          onChange={(e) => setValue("addPer", e.target.value)}
          value={per}
          fluid
          label="Per"
          placeholder="Per"
        />
        <Form.TextArea
          onChange={(e) => setValue("addDescription", e.target.value)}
          value={description}
          label="Description"
          placeholder="Write or paste text in here..."
        />
        <Form.Button>Save</Form.Button>
      </Form>
    </Modal.Content>
  </Modal>
);

class ProjectOverviewPackages extends Component {
  state = {
    basePrice: 0,
    deposit: 0,
    addons: [],
    newAddons: [],
    project: {},
    showDeposit: false,
    revisions: 0,
    showModalAdd: "",
    addTitle: "",
    addDescription: "",
    addPrice: "",
    addPer: "",
  };

  setValue = (type, val) => {
    this.setState({ [type]: val });
  };

  submitModalAdd = () => {
    const newAddon = {
      title: this.state.addTitle,
      description: this.state.addDescription,
      price: this.state.addPrice,
      per: this.state.addPer,
      id: cuid(),
    };

    const newAddons = [...this.state.newAddons, newAddon];

    this.setState({
      showModalAdd: "",
      addTitle: "",
      addDescription: "",
      addPrice: "",
      addPer: "",
      newAddons: newAddons,
    });

    this.props.changeField(this.props.match.params.id, newAddons, "newAddons");
  };

  toggleModal = () => {
    this.setState((p) => ({ showDeposit: !p.showDeposit }));
  };

  toggleModalAdd = (val) => {
    this.setState({ showModalAdd: val });
  };

  updateDeposit = (e) => {
    e.preventDefault();
    this.props.changeField(
      this.props.project.id,
      this.state.deposit,
      "deposit"
    );
    this.toggleModal();
  };

  onChange = (e) => {
    this.setState({ deposit: e.target.value });
  };

  addRescheduleSession = async (e) => {
    const rev = this.state.revisions + 1;
    await this.props.changeField(this.props.match.params.id, rev, "revisions");
    await this.setState({
      revisions: rev,
    });
  };

  removeRescheduleSession = async (e) => {
    if (this.state.revisions > 0) {
      const rev = this.state.revisions - 1;
      await this.props.changeField(
        this.props.match.params.id,
        rev,
        "revisions"
      );

      await this.setState({
        revisions: rev,
      });
    }
  };

  componentDidMount() {
    const { project, services, packages } = this.props;

    let basePackage = {};
    let addons = [];

    if (project.addons) {
      addons = services.filter((service) =>
        project.addons.includes(service.id)
      );
      if (project.customAddons) {
        addons = [...addons, ...project.customAddons];
      }
    }
    if (project.package) {
      basePackage = packages.filter((pack) => pack.id === project.package)[0];
      this.setState({
        basePrice: (basePackage && basePackage.price) || 0,
        deposit: (((basePackage && basePackage.price) || 0) / 2).toFixed(2),
        addons: addons || [],
        newAddons: project.newAddons || [],
        project: project,
      });
    }
    if (project.prebuildMin) {
      const min = Number(project.prebuildMin) + Number(project.buildMin);
      const max = Number(project.prebuildMax) + Number(project.buildMax);
      this.setState({
        basePrice: `${min} - ${max}`,
        addons: addons,
        newAddons: project.newAddons || [],
        project: project,
      });
    }
    if (project.deposit) {
      this.setState({
        deposit: project.deposit,
      });
    }

    this.setState({
      revisions: Number(project.revisions) || 0,
    });
  }

  render() {
    return (
      <div className="project-overview-section-packages">
        <ModalForm
          handleSubmitModal={this.updateDeposit}
          val={this.state.deposit}
          handleModalState={this.onChange}
          showModal={this.state.showDeposit}
          toggleModal={this.toggleModal}
        />
        <FormModal
          handleSubmit={
            this.state.showModalAdd === "new"
              ? this.submitModalAdd
              : this.submitModalAdd
          }
          open={this.state.showModalAdd.length > 0}
          setValue={this.setValue}
          per={this.state.addPer}
          price={this.state.addPrice}
          title={this.state.addTitle}
          description={this.state.addDescription}
          handleClose={this.toggleModalAdd}
        />
        <div className="project-overview-section-packages-header">
          {!this.props.client ? (
            <>
              <h2>Client's package</h2>
              <p>
                This is an overview of the key elements in your package and any
                add-ons that apply.
              </p>
              <br />
              <span
                onClick={this.toggleModal}
                className={"project-overview-date-control"}
                style={{ width: "auto", padding: "5px 10px", fontSize: "14px" }}
              >
                MANAGE DEPOSIT
              </span>
            </>
          ) : (
            <>
              <h2>Your package</h2>
              <p>
                This is an overview of the key elements in your package and any
                add-ons that apply.
              </p>
              <br />
            </>
          )}
        </div>
        <div className="project-overview-section-packages-overview">
          <div className="project-overview-section-packages-overview-item">
            <div>£{this.state.basePrice}</div>
            <div> Base Package Cost</div>
          </div>
          <div className="project-overview-section-packages-overview-item">
            <div>
              {this.state.deposit === 0 ? "N/A" : "£" + this.state.deposit}{" "}
            </div>
            <div>Deposit paid</div>
          </div>
          <div className="project-overview-section-packages-overview-item">
            <div>{this.state.project.revisions || 0}/3</div>
            <div>Revision sessions used</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "60%",
          }}
        >
          <div className="project-overview-section-packages-addons">
            <h3>ADDONS</h3>
            <div className="project-overview-section-packages-addons-list">
              {this.state.addons.map((addon) => (
                <div key={addon.id}>
                  - {addon.title} - <span>£{addon.price}</span>
                </div>
              ))}
            </div>
          </div>
          {!this.props.client && (
            <div className="project-overview-section-packages-addons">
              <h3>NEW ADDONS</h3>
              <div className="project-overview-section-packages-addons-list">
                {this.state.newAddons.map((addon) => (
                  <div key={addon.id}>
                    - {addon.title} - <span>£{addon.price}</span>
                  </div>
                ))}
              </div>
              <br />
              <span
                onClick={() => this.toggleModalAdd("new")}
                className={"project-overview-date-control"}
                style={{ width: "auto", padding: "4px 8px", fontSize: "13px" }}
              >
                ADD +
              </span>
            </div>
          )}
          {/* <div className="project-overview-section-packages-addons">
            <h3>NEW ADDONS</h3>
            <div className="project-overview-section-packages-addons-list">
              {this.state.newAddons.map((addon) => (
                <div key={addon.id}>
                  - {addon.title} - <span>£{addon.price}</span>
                </div>
              ))}
            </div>
            <br />
            <span
              onClick={() => this.toggleModalAdd("new")}
              className={"project-overview-date-control"}
              style={{ width: "auto", padding: "4px 8px", fontSize: "13px" }}
            >
              ADD +
            </span>
          </div> */}
        </div>
        {!this.props.client && (
          <>
            <div style={{ marginTop: "30px" }}>
              <strong style={{ color: "#8C8C8C" }}>
                Revision session used {this.state.revisions} / 3
              </strong>
            </div>
            <br />
            <span
              className="project-overview-date-control"
              onClick={this.addRescheduleSession}
            >
              +
            </span>
            <span
              className="project-overview-date-control"
              onClick={this.removeRescheduleSession}
            >
              -
            </span>
          </>
        )}
      </div>
    );
  }
}

const actions = {
  changeField,
};

export default compose(
  withFirestore,
  withRouter,
  connect(null, actions)
)(ProjectOverviewPackages);
