import {createReducer} from "../utils/reducer-utils";
import {CREATE_PRICING, DELETE_PRICING, UPDATE_PRICING} from "../reduxConstants";


const initialState = [];

const createPricing = (state, payload) => {
    return [...state, payload.pricing];
};

const updatePricing = (state, payload) => {
    return [
        ...state.filter( pricing => pricing.id !== payload.pricing.id),
        payload.pricing
    ]
};

const deletePricing = (state, payload) => {
    return [
        ...state.filter(pricing => pricing.id !== payload.id)
    ]
};

export default createReducer(initialState, {
    [CREATE_PRICING]: createPricing,
    [UPDATE_PRICING]: updatePricing,
    [DELETE_PRICING]: deletePricing,
});