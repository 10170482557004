import { toastr } from "react-redux-toastr";
import firebase from "../firebase";
import cuid from "cuid";

export const updateComponents = (projectId, pageId, components) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const db = firebase.firestore();
    const firestore = getFirestore();

    try {
      const projectsQuery = await db
        .collection("projects")
        .where("id", "==", projectId);
      const projectsSnap = await projectsQuery.get();
      const project = await projectsSnap.docs[0].data();

      const page = project.pages.filter((page) => page.id === pageId)[0];

      console.log(page);

      const newPage = { ...page, components: components };

      console.log(newPage);

      replacePage(page, newPage, projectId)
        .then((result) => {
          console.log(
            "Page replacement was " +
              (result === "replaced" ? "" : " not") +
              " successful"
          );
        })
        .catch((err) => console.error("failed: ", err));

      // await firestore.update(`projects/${projectId}`, {
      //   pages: firestore.FieldValue.arrayRemove(page),
      // });

      // await firestore.update(`projects/${projectId}`, {
      //   pages: firestore.FieldValue.arrayUnion(newPage),
      // });

      // toastr.success("Success", "Page Name was updated")
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

// Updating list while keeping current index
export const replacePage = (oldPage, newPage, projectId) => {
  const db = firebase.firestore();
  const projectsQuery = db.doc(`projects/${projectId}`);

  return db.runTransaction(async (t) => {
    const snapshot = await t.get(projectsQuery);

    if (!snapshot.exists) {
      // no previous data, abort.
      return "aborted";
    }

    const pagesArray = snapshot.get("pages");

    const index = pagesArray.findIndex((page) => page.id === oldPage.id);

    if (index === -1) return "not-found";

    pagesArray[index] = newPage;

    await t.set(projectsQuery, { pages: pagesArray }, { merge: true });

    return "replaced";
  });
};

// Update pagelist once page has been dragged to new position

export const updatePageList = (updatedList, projectId) => {
  const db = firebase.firestore();
  const projectsQuery = db.doc(`projects/${projectId}`);

  return db.runTransaction(async (t) => {
    const snapshot = await t.get(projectsQuery);

    if (!snapshot.exists) {
      // no previous data, abort.
      return "aborted";
    }

    // const pagesArray = snapshot.get("pages");

    // const index = pagesArray.findIndex((page) => page.id === draggedPage);

    // if (index === -1) return "not-found";

    // pagesArray[index] = newIndex;
    // await t.set(projectsQuery, { pages: updatedList }, { merge: true });
    await t.update(projectsQuery, { pages: updatedList });

    return "replaced";
  });
};

export const deleteComponents = (projectId, pageId) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const db = firebase.firestore();
    const firestore = getFirestore();

    try {
      const projectsQuery = await db
        .collection("projects")
        .where("id", "==", projectId);
      const projectsSnap = await projectsQuery.get();
      const project = await projectsSnap.docs[0].data();

      const page = project.pages.filter((page) => page.id === pageId)[0];

      const newPage = { ...page, components: [] };

      await firestore.update(`projects/${projectId}`, {
        pages: firestore.FieldValue.arrayRemove(page),
      });

      await firestore.update(`projects/${projectId}`, {
        pages: firestore.FieldValue.arrayUnion(newPage),
      });
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const uploadCustom = (image) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const options = {
      name: image.path,
    };

    try {
      const uploadedFile = await firebase.uploadFile(
        "custom",
        image,
        null,
        options
      );
      let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
      return downloadURL;
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};
