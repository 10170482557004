import React from 'react';
import "./no-match.css";
import {Button} from "semantic-ui-react";
import {withRouter} from "react-router-dom"


const NoMatch = ({history}) => {
    return (
        <div id="main">
            <div className="fof">
                <h1>Oops... you should not be here</h1>
                <br/>
                <Button
                    content={"Back to Homepage"}
                    size={"huge"}
                    onClick={() =>  history.push("/")}
                />
            </div>
        </div>
    );
};

export default withRouter(NoMatch);
