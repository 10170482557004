import React, {Component} from 'react';
import firebase from "../../../firebase";
import {compose} from "redux";
import {withRouter} from "react-router";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import {connect} from "react-redux";

class PreviousRevisions extends Component {

    state = {
        hide: true,
        tasks: [

        ]
    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`projects`);
        const db = firebase.firestore();
        const projectQuery = await db.collection("projects").doc(this.props.match.params.id);
        const form = await projectQuery.get();
        const formComponents = form.data().prevTasks;
        await this.setState({
            tasks: formComponents || []
        });

    }

    toggleHide = async () => {
        this.setState(prevState =>({
            hide: !prevState.hide
        }));
        const {firestore} = this.props;
        await firestore.setListener(`projects`);
        const db = firebase.firestore();
        const projectQuery = await db.collection("projects").doc(this.props.match.params.id);
        const form = await projectQuery.get();
        const formComponents = form.data().prevTasks;
        await this.setState({
            tasks: formComponents || []
        });
    };

    render() {
        return (
            <div className={"previous-sessions"}>
                {this.state.tasks.length > 0 &&
                <div onClick={this.toggleHide} className={"previous-sessions-header"}>
                    {this.state.hide ?
                        <i className="chevron right icon" style={{color: "#969696"}}></i>
                        :
                        <i className="chevron down icon" style={{color: "#969696"}}></i>
                    }
                    {this.state.hide ?
                        <span>VIEW ITEMS FROM PREVIOUS REVISION SESSIONS</span>
                        :
                        <span>HIDE ITEMS FROM PREVIOUS REVISION SESSIONS</span>
                    }
                </div>
                }
                <div>
                    {!this.state.hide && this.state.tasks.map(t => (
                        <div key={t.id} className="revision-tasks-task">
                            <div className="revision-tasks-task-border">
                                <div className="revision-tasks-task-flex">
                                <div className="revision-tasks-task-icon">
                                    <img className="revision-tasks-task-ticked" src="/assets/icons/ticked.png" alt=""/>
                                </div>
                                <div className="revision-tasks-task-header">
                                    <div><h4 style={{color: "rgb(210, 210, 210)", textDecoration: "line-through"}}>{t.text}</h4>
                                    </div>
                                    <div className="revision-tasks-task-header-func"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withFirestore,
    firestoreConnect([{collection: 'projects'}]),
    connect(null,null),
)(PreviousRevisions)