import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import firebase from "../../../firebase";
import FormHeader from "../../layout-components/form-header/form-header";
import { Field } from "redux-form";
import FormInput from "../../form-components/form-input/form-input";
import FormSelect from "../../form-components/form-select/form-select";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import { Input } from "semantic-ui-react";
import cuid from "cuid";
import "./client-content.css";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import ClientInput from "../../form-components/client-input/client-input";
import {
  updateFormComponent,
  uploadCustomFile,
  deleteCustomFile,
} from "../../../actions/form-actions";
import ClientDropzone from "../../form-components/client-dropzone/client-dropzone";
import Loading from "../../loading/loading";
import ScrollIntoView from "react-scroll-into-view";
import ScrollTrigger from "react-scroll-trigger";

const actions = {
  updateFormComponent,
  uploadCustomFile,
  deleteCustomFile,
};

class ClientContent extends Component {
  state = {
    formsToDisplay: [],
    project: null,
    currentElement: "",
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);
    const db = firebase.firestore();
    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const form = await projectQuery.get();
    const formComponents = form.data().fields;
    await this.setState({
      formsToDisplay: formComponents || [],
      project: form.data(),
      currentElement:
        (formComponents.find((f) => f.formField === "section-label") &&
          formComponents.find((f) => f.formField === "section-label").id) ||
        "",
    });
  }

  updateComponents = async (field, text) => {
    const updatedField = { ...field, text: text };

    const updatedForms = this.state.formsToDisplay.map((fieldToDisplay) => {
      if (field.id === fieldToDisplay.id) {
        return updatedField;
      }
      return fieldToDisplay;
    });

    await this.props.updateFormComponent(
      updatedForms,
      this.props.match.params.id,
      true
    );

    await this.setState({
      formsToDisplay: updatedForms,
    });
  };

  async componentWillUnmount() {
    const { firestore } = this.props;
    await firestore.unsetListener(`projects`);
  }

  uploadCustomFile = async (field, file) => {
    const fileURL = await this.props.uploadCustomFile(file, field.id);
    const fileObj = {
      url: fileURL,
      name: file.path,
      id: cuid(),
    };

    const updatedField = { ...field, files: [...field.files, fileObj] };

    const updatedForms = this.state.formsToDisplay.map((fieldToDisplay) => {
      if (field.id === fieldToDisplay.id) {
        return updatedField;
      }
      return fieldToDisplay;
    });

    await this.props.updateFormComponent(
      updatedForms,
      this.props.match.params.id,
      true
    );

    await this.setState({
      formsToDisplay: updatedForms,
    });
  };

  deleteCustomImage = async (field, file) => {
    const updatedFiles = field.files.filter(
      (filteredFile) => filteredFile.id !== file.id
    );
    const updatedField = { ...field, files: [...updatedFiles] };

    const updatedForms = this.state.formsToDisplay.map((fieldToDisplay) => {
      if (field.id === fieldToDisplay.id) {
        return updatedField;
      }
      return fieldToDisplay;
    });

    await this.props.updateFormComponent(
      updatedForms,
      this.props.match.params.id,
      true
    );

    await this.setState({
      formsToDisplay: updatedForms,
    });

    await this.props.deleteCustomFile(file.name, field.id);
  };

  async onEnterViewport(t) {
    await this.setState({
      currentElement: t,
    });
  }

  render() {
    if (!this.state.project) {
      return <Loading />;
    }

    if (!this.state.project.shareContentForm) {
      return (
        <div className={"revision-main"}>
          <div className={"revisions-tasks-header"}>
            <h1>
              Once we've agreed your page layouts with you we will make a lost
              of everything we need & put together a form for you to fill out
              with your content.
            </h1>
            <div style={{ marginRight: "50px" }}>
              We require that you complete the form before the website build. In
              the meantime feel free to upload initial content into the boxes
              below.
            </div>
          </div>
          <div className={"client-content-form"}>
            {this.state.formsToDisplay &&
              this.state.formsToDisplay
                .filter((f) => f.id === "a1" || f.id === "a2" || f.id === "a3")
                .map((form) => {
                  switch (form.formField) {
                    case "input":
                      return (
                        <section id={form.id}>
                          <ScrollTrigger
                            key={form.id}
                            onProgress={() => this.onEnterViewport(form.id)}
                          >
                            <ClientInput
                              key={form.id}
                              updateForm={this.updateComponents}
                              form={form}
                            />
                          </ScrollTrigger>
                        </section>
                      );
                    case "dz":
                      return (
                        <section id={form.id}>
                          <ScrollTrigger
                            key={form.id}
                            onProgress={() => this.onEnterViewport(form.id)}
                          >
                            <ClientDropzone
                              deleteImage={this.deleteCustomImage}
                              uploadFile={this.uploadCustomFile}
                              form={form}
                              key={form.id}
                            />
                          </ScrollTrigger>
                        </section>
                      );
                    case "section-label":
                      return (
                        <ScrollTrigger
                          id={form.id}
                          key={form.id}
                          onProgress={() => this.onEnterViewport(form.id)}
                        >
                          <p
                            id={form.id}
                            className={"client-content-form-label"}
                          >
                            {form.title}
                          </p>
                        </ScrollTrigger>
                      );
                    case "text":
                      return (
                        <ClientInput
                          key={form.id}
                          updateForm={this.updateComponents}
                          form={form}
                        />
                      );
                    default:
                      return;
                  }
                })}
          </div>
          <div className="proposal-client-content">
            <div className="proposal-client-content-nav content-nav-sidekick">
              {this.state.formsToDisplay
                .filter((f) => f.id === "a1" || f.id === "a2" || f.id === "a3")
                .map((f) => (
                  <ScrollIntoView
                    key={f.id}
                    selector={"#" + f.id}
                    onClick={() =>
                      setTimeout(
                        () => this.setState({ currentElement: f.id }),
                        1500
                      )
                    }
                  >
                    <p
                      className={
                        this.state.currentElement === f.id
                          ? "proposal-client-content-nav-o"
                          : "proposal-client-content-nav-p"
                      }
                    >
                      {f.title}
                      {this.state.currentElement === f.id && " —"}
                    </p>
                  </ScrollIntoView>
                ))}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={"revision-main"}>
        <div className={"revisions-tasks-header"}>
          <h1>
            This form lists every image and piece of text that we need from you,
            please fill out each field individually. We require that you
            complete the form before the website is built.
          </h1>
          <div style={{ marginRight: "50px" }}>
            If you do not have time or are unable to organise your content into
            the relevant fields, let us know and our team can organise it for
            you for an admin fee.
          </div>
        </div>
        <div className={"client-content-form"}>
          {this.state.formsToDisplay &&
            this.state.formsToDisplay.map((form) => {
              switch (form.formField) {
                case "input":
                  return (
                    <ClientInput
                      key={form.id}
                      updateForm={this.updateComponents}
                      form={form}
                    />
                  );
                case "dz":
                  return form.id === "a1" || form.id === "a2" ? (
                    <section id={form.id}>
                      <ScrollTrigger
                        key={form.id}
                        onProgress={() => this.onEnterViewport(form.id)}
                      >
                        <ClientDropzone
                          deleteImage={this.deleteCustomImage}
                          uploadFile={this.uploadCustomFile}
                          form={form}
                          key={form.id}
                        />
                      </ScrollTrigger>
                    </section>
                  ) : (
                    <ClientDropzone
                      deleteImage={this.deleteCustomImage}
                      uploadFile={this.uploadCustomFile}
                      form={form}
                      key={form.id}
                    />
                  );
                case "section-label":
                  return (
                    <ScrollTrigger
                      key={form.id}
                      onProgress={() => this.onEnterViewport(form.id)}
                    >
                      <p id={form.id} className={"client-content-form-label"}>
                        {form.title}
                      </p>
                    </ScrollTrigger>
                  );
                case "text":
                  return form.id !== "a3" ? (
                    <ClientInput
                      key={form.id}
                      updateForm={this.updateComponents}
                      form={form}
                    />
                  ) : (
                    <section id={form.id}>
                      <ScrollTrigger
                        key={form.id}
                        onProgress={() => this.onEnterViewport(form.id)}
                      >
                        <ClientInput
                          key={form.id}
                          updateForm={this.updateComponents}
                          form={form}
                        />
                      </ScrollTrigger>
                    </section>
                  );
                default:
                  return;
              }
            })}
        </div>
        <div className="proposal-client-content">
          <div className="proposal-client-content-nav content-nav-sidekick">
            {this.state.formsToDisplay
              .filter(
                (f) =>
                  f.formField === "section-label" ||
                  f.id === "a1" ||
                  f.id === "a2" ||
                  f.id === "a3"
              )
              .map((f) => (
                <ScrollIntoView
                  key={f.id}
                  selector={"#" + f.id}
                  onClick={() =>
                    setTimeout(
                      () => this.setState({ currentElement: f.id }),
                      1500
                    )
                  }
                >
                  <p
                    className={
                      this.state.currentElement === f.id
                        ? "proposal-client-content-nav-o"
                        : "proposal-client-content-nav-p"
                    }
                  >
                    {f.title}
                    {this.state.currentElement === f.id && " —"}
                  </p>
                </ScrollIntoView>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirestore,
  connect(null, actions)
)(ClientContent);
