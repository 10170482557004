import React, { Component } from "react";
import firebase from "../../../firebase";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  manageResources,
  changeGoogle,
} from "../../../actions/project-actions";
import cuid from "cuid";

const actions = {
  manageResources,
  changeGoogle,
};

const urlPrepend = (s) => {
  if (!s.match(/^[a-zA-Z]+:\/\//)) {
    return "https://" + s;
  }
  return s;
};

class ProjectOverviewResources extends Component {
  state = {
    resources: [],
    newLinkName: "",
    newLinkUrl: "",
    editLinkUrl: "",
    editId: "",
  };

  addLink = async (e) => {
    e.preventDefault();

    const newLink = {
      title: this.state.newLinkName,
      url: this.state.newLinkUrl,
      id: cuid(),
    };

    const newLinks = [...this.state.resources, newLink];

    await this.props.manageResources(this.props.match.params.id, newLinks);
    await this.setState({
      resources: newLinks,
      newLinkName: "",
      newLinkUrl: "",
    });
  };

  removeLink = async (id) => {
    const newLinks = this.state.resources.filter((link) => link.id !== id);

    await this.props.manageResources(this.props.match.params.id, newLinks);
    await this.setState({
      resources: newLinks,
    });
  };

  updateLink = async (e, id) => {
    e.preventDefault();

    let updatedLink = this.state.resources.find((link) => link.id === id);
    updatedLink = {
      ...updatedLink,
      url: this.state.editLinkUrl,
    };

    console.log(updatedLink);

    const newLinks = this.state.resources.map((link) =>
      link.id === id ? updatedLink : link
    );

    await this.props.manageResources(this.props.match.params.id, newLinks);
    await this.setState({
      resources: newLinks,
      editId: "",
    });
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);

    const db = firebase.firestore();

    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const projectSnap = await projectQuery.get();
    const data = projectSnap.data();

    await this.setState({
      resources: data.resources || [],
    });
  }

  render() {
    return (
      <div className="project-overview-section">
        <div className="project-overview-section-header">
          <h2>Client Resources</h2>
          <h4 style={{ fontWeight: "400", marginBottom: "30px" }}>
            These links are visible to the client on their dashboard
          </h4>
          <div className="project-overview-section-content-link-form">
            <p>Add new link</p>
            <form action="" onSubmit={this.addLink}>
              <input
                onChange={(e) => this.setState({ newLinkName: e.target.value })}
                value={this.state.newLinkName}
                placeholder="Resource title"
                type="text"
              />
              <input
                onChange={(e) => this.setState({ newLinkUrl: e.target.value })}
                value={this.state.newLinkUrl}
                placeholder={"Resource URL"}
                type="text"
              />
              <button style={{}} type={"submit"} onClick={this.addLink}>
                SAVE
              </button>
            </form>
          </div>
        </div>
        <div className="project-overview-section-content">
          {this.state.resources.map((link) => {
            if (this.state.editId === link.id) {
              return (
                <div
                  key={link.id}
                  className="project-overview-section-content-edit-form"
                >
                  <form action="" onSubmit={(e) => this.updateLink(e, link.id)}>
                    <input
                      value={this.state.editLinkUrl}
                      onChange={(e) =>
                        this.setState({ editLinkUrl: e.target.value })
                      }
                      type="text"
                    />
                  </form>
                  <span
                    onClick={() =>
                      this.setState({ editId: "", editLinkUrl: "" })
                    }
                  >
                    CANCEL
                  </span>
                </div>
              );
            } else {
              return (
                <div
                  key={link.id}
                  className="project-overview-section-content-link"
                >
                  <a href={urlPrepend(link.url)} target="_blank">
                    {link.title}
                  </a>
                  <div className="project-overview-section-content-link-func">
                    <span
                      onClick={() =>
                        this.setState({
                          editId: link.id,
                          editLinkUrl: link.url,
                        })
                      }
                    >
                      EDIT
                    </span>
                    <span onClick={() => this.removeLink(link.id)}>DELETE</span>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect([{ collection: "projects" }]),
  withRouter,
  connect(null, actions)
)(ProjectOverviewResources);
