import React, { Component } from "react";

import "../project-overview/project-overview.css";

import { compose } from "redux";
import { firestoreConnect, withFirebase } from "react-redux-firebase";
import { connect } from "react-redux";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { reduxForm } from "redux-form";
import { Dropdown, Menu } from "semantic-ui-react";

import ProjectOverviewProfile from "./pjc-profile";
import ProjectOverviewDashboard from "./pjc-dashboard";
import WebsiteLink from "../project-overview/website-link";
import Revision from "../project-overview/revision";
import ClientContent from "../client-content/client-content";
import PageSketchesClient from "./pjc-page-sketches";
import ProposalClient from "./proposal-client";
import firebase from "../../../firebase";

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;

  return {
    projectId: id,
  };
};

class ProjectOverviewClient extends Component {
  state = {
    project: null,
  };

  async componentDidMount() {
    if (this.props.match.params.id === "no-project") {
      this.props.history.push("/dashboard/no-project");
    }

    const db = firebase.firestore();

    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const projectSnap = await projectQuery.get();
    const project = projectSnap.data();

    this.setState({
      project: project,
    });
  }

  handleLogout = async () => {
    console.log(this.props);
    await this.props.firebase.logout();
    this.props.history.push(`/`);
  };

  render() {
    if (this.state.project && this.state.project.cJourney) {
      var journey = this.state.project.cJourney.find(
        (j) => j.status === "active"
      );
    }

    return (
      <div className="project-overview">
        <div>
          <div className="project-overview-header">
            <div className="project-overview-header-logo">
              <Link
                to={`/dashboard/project-overview-client/${this.props.projectId}/dashboard`}
              >
                <img src={"/assets/logo/logo.png"} alt="logo" />
              </Link>
            </div>
            <div className="project-overview-header-account">
              <Menu className="account-dropdown">
                <Dropdown item text="Account">
                  <Dropdown.Menu>
                    <Dropdown.Header
                      icon="user circle"
                      content={this.props.userEmail}
                    />
                    <Dropdown.Item>
                      <Link
                        to={`/dashboard/project-overview-client/${this.props.projectId}/profile`}
                      >
                        Profile
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleLogout}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
              {/* <Link
                to={`/dashboard/project-overview-client/${this.props.projectId}/profile`}
              >
                Account
              </Link> */}
            </div>
          </div>
          <div className="project-overview-menu">
            {/* <NavLink
              className="item"
              to={`/dashboard/project-overview-client/${this.props.projectId}/dashboard`}
              activeClassName="navbar-active"
            >
              Project Dashboard
            </NavLink>
            <NavLink
              className="item"
              to={`/dashboard/project-overview-client/${this.props.projectId}/proposal`}
              activeClassName="navbar-active"
            >
              Packages & Costs
            </NavLink> */}
            <NavLink
              className="item"
              to={`/dashboard/project-overview-client/${this.props.projectId}/page-sketches`}
              activeClassName="navbar-active"
            >
              Page Wireframes
            </NavLink>
            <NavLink
              className="item"
              to={`/dashboard/project-overview-client/${this.props.projectId}/content`}
              activeClassName="navbar-active"
            >
              Upload Content
            </NavLink>
            <NavLink
              className="item"
              to={`/dashboard/project-overview-client/${this.props.projectId}/website-link`}
              activeClassName="navbar-active"
            >
              Your New Website
            </NavLink>
            {/* <NavLink
              className="item"
              to={`/dashboard/project-overview-client/${this.props.projectId}/revision`}
              activeClassName="navbar-active"
            >
              Feedback & Questions
            </NavLink> */}
          </div>
        </div>
        <Switch>
          <Route
            exact
            path={`/dashboard/project-overview-client/:id`}
            render={() => (
              <Redirect
                to={`/dashboard/project-overview-client/${this.props.projectId}/dashboard`}
              />
            )}
          />
          <Route
            path={`/dashboard/project-overview-client/:id/dashboard`}
            component={ProjectOverviewDashboard}
          />
          <Route
            path={`/dashboard/project-overview-client/:id/page-sketches`}
            component={PageSketchesClient}
          />
          <Route
            path={"/dashboard/project-overview-client/:id/website-link"}
            component={() => <WebsiteLink client={true} />}
          />
          <Route
            path={"/dashboard/project-overview-client/:id/content"}
            component={ClientContent}
          />
          <Route
            path={`/dashboard/project-overview-client/:id/revision`}
            component={() => <Revision client={true} />}
          />
          <Route
            path={`/dashboard/project-overview-client/:id/proposal`}
            component={ProposalClient}
          />
          <Route
            path={`/dashboard/project-overview-client/:id/profile`}
            component={ProjectOverviewProfile}
          />
        </Switch>

        {/* {this.state.project && this.state.project.cJourney && journey && (
          <div className={"journey-bar"}>
            <div className={"journey-bar-f1"}>
              <h4>{journey.heading}</h4>
              <p>{journey.body}</p>
            </div>
            {journey.actionText && (
              <div className={"journey-bar-f2"}>
                <div>{journey.actionText}→</div>
              </div>
            )}
          </div>
        )} */}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, null)
)(ProjectOverviewClient);
