import React from 'react';

import "./form-header.css";

import {Link} from "react-router-dom";

const FormHeader = ({url,title}) => {
    return (
        <div>
            <div className="form-header">
                <div className="form-header-back">
                    <Link to={url}>
                        <div className={"admin-settings-side-back"} style={{width: "200px"}}>
                            <div><img src={"/assets/icons/arrow.svg"} alt=""/></div>
                            <div className={"admin-settings-side-back-text"}>Back to dashboard</div>
                        </div>
                    </Link>
                </div>
                <h1>{title}</h1>
                <br/>
            </div>
        </div>

    );
};

export default FormHeader;
