import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Modal, Select } from "semantic-ui-react";
import FormWysiwyg from "../../form-components/form-wysiwyg/form-wysiwyg";
import SelectHook from "./select-hook";
import { connect } from "react-redux";
import { updateProject } from "../../../actions/project-actions";
import { Link } from "react-router-dom";
import cuid from "cuid";
import PricingFormModal from "../../pages-forms/pricing-form/pricing-form-modal";

const FormModal = ({
  handleSubmit,
  open,
  setATitle,
  setADescription,
  aPer,
  setAPer,
  setAPrice,
  aTitle,
  aDescription,
  aPrice,
  handleClose,
}) => (
  <Modal open={open} closeIcon onClose={() => handleClose("")}>
    <Modal.Header>Custom Addon</Modal.Header>
    <Modal.Content>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          onChange={(e) => setATitle(e.target.value)}
          value={aTitle}
          required
          fluid
          label="Title"
          placeholder="Website setup"
        />
        <Form.Input
          onChange={(e) => setAPrice(e.target.value)}
          value={aPrice}
          required
          fluid
          label="Price"
          placeholder="£111"
        />
        <Form.Input
          onChange={(e) => setAPer(e.target.value)}
          value={aPer}
          fluid
          label="Per"
          placeholder="Per"
        />
        <Form.TextArea
          onChange={(e) => setADescription(e.target.value)}
          value={aDescription}
          label="Description"
          placeholder="Write or paste text in here..."
        />
        <Form.Button>Save</Form.Button>
      </Form>
    </Modal.Content>
  </Modal>
);

const ProposalCostForm = ({ project, updateProject, services, packages }) => {
  const [cust, setCust] = useState(
    (project.prebuildMin && project.prebuildMin.length > 0) || false
  );

  const [customAddons, setCustomAddons] = useState(project.customAddons || []);
  const [aTitle, setATitle] = useState("");
  const [aDescription, setADescription] = useState("");
  const [aPrice, setAPrice] = useState("");
  const [aPer, setAPer] = useState("");
  const [showModal, setShowModal] = useState("");
  const [customId, setCustomId] = useState("");

  const { register, control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      costOverview: project.costOverview || "",
      termsConditions: project.termsConditions || "",
      squareSpacePlan: project.squareSpacePlan || "",
      package: project.package || "",
      addons: project.addons || [],
      unconfirmedAddons: project.unconfirmedAddons || [],
      prebuildMin: project.prebuildMin || "",
      prebuildMax: project.prebuildMax || "",
      buildMin: project.buildMin || "",
      buildMax: project.buildMax || "",
    },
  });

  const handleChange = async () => {
    if (!cust) {
      setValue("package", "");
      await setCust(!cust);
      setValue("buildMin", "");
      setValue("buildMax", "");
      setValue("prebuildMin", "");
      setValue("prebuildMax", "");
    } else {
      setValue("buildMin", "");
      setValue("buildMax", "");
      setValue("prebuildMin", "");
      setValue("prebuildMax", "");
      await setCust(!cust);
      setValue("package", "");
    }
  };

  const onSubmit = (data) => {
    let newData = {};
    if (cust) {
      newData = { ...data, package: "" };
    } else {
      newData = {
        ...data,
        prebuildMax: "",
        prebuildMin: "",
        buildMax: "",
        buildMin: "",
      };
    }
    const updatedProject = { ...project, ...newData };

    updateProject(updatedProject);
  };

  const addCustomAddon = () => {
    const customAddon = {
      id: cuid(),
      title: aTitle,
      price: aPrice,
      description: aDescription,
    };

    const newAddons = [...customAddons, customAddon];

    setCustomAddons(newAddons);

    const updatedProject = { ...project, customAddons: newAddons };

    updateProject(updatedProject);

    setADescription("");
    setAPrice("");
    setATitle("");
    setCustomId("");
    setShowModal("");
  };

  const editCustomAddon = (id) => {
    setShowModal("edit");

    const findModal = customAddons.find((a) => a.id === id);

    setADescription(findModal.description);
    setAPrice(findModal.price);
    setATitle(findModal.title);
    setCustomId(id);
  };

  const updateCustomAddon = () => {
    const customAddon = {
      id: customId,
      title: aTitle,
      price: aPrice,
      description: aDescription,
    };

    const newAddons = customAddons.map((a) =>
      a.id === customId ? customAddon : a
    );

    setCustomAddons(newAddons);

    const updatedProject = { ...project, customAddons: newAddons };

    updateProject(updatedProject);

    setADescription("");
    setAPrice("");
    setATitle("");
    setCustomId("");
    setShowModal("");
  };

  const deleteCustomAddon = (id) => {
    const newAddons = customAddons.filter((a) => a.id !== id);

    setCustomAddons([newAddons]);

    const updatedProject = { ...project, customAddons: newAddons };

    updateProject(updatedProject);
  };

  const optionPackages = packages.map((pack) => ({
    value: pack.id,
    key: pack.id,
    text: pack.title,
  }));
  const optionServices = services.map((pack) => ({
    value: pack.id,
    key: pack.id,
    text: pack.title,
  }));

  return (
    <div className="proposal-form-border">
      <FormModal
        aPer={aPer}
        aDescription={aDescription}
        aTitle={aTitle}
        aPrice={aPrice}
        setADescription={setADescription}
        setAPer={setAPer}
        setAPrice={setAPrice}
        setATitle={setATitle}
        handleClose={setShowModal}
        open={showModal.length > 0}
        handleSubmit={showModal === "new" ? addCustomAddon : updateCustomAddon}
      />
      <h4 className="project-proposal-section-header">Costs</h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <Controller
            setValue={setValue}
            label="Cost Overview"
            value={getValues("costOverview")}
            name="costOverview"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <div className="form-switch-flex">
            <label className="form-switch">
              <input type="checkbox" checked={cust} onClick={handleChange} />
              <div className="form-slider form-round"></div>
            </label>
            <div className="form-switch-label">
              Package pricing / Custom Pricing
            </div>
          </div>
        </Form.Field>
        <br />
        {!cust && getValues("prebuildMin").length === 0 ? (
          <Form.Field>
            <label>Package</label>
            <Controller
              placeholder="Select one..."
              control={control}
              name="package"
              as={SelectHook}
              value={getValues("package")}
              setValue={setValue}
              options={optionPackages}
            />
          </Form.Field>
        ) : (
          <div>
            <Form.Field>
              <label>Pre-build & Design Phase</label>
              <input
                style={{ width: "100px" }}
                placeholder={"Min"}
                type="text"
                required
                ref={register}
                name="prebuildMin"
              />
              <span className="proposal-cost-dash"> - </span>
              <input
                placeholder={"Max"}
                style={{ width: "100px" }}
                type="text"
                required
                ref={register}
                name="prebuildMax"
              />
            </Form.Field>
            <br />
            <br />
            <Form.Field>
              <label>Website Build cost in £</label>
              <input
                style={{ width: "100px" }}
                placeholder={"Min"}
                type="text"
                required
                ref={register}
                name="buildMin"
              />
              <span className="proposal-cost-dash"> - </span>
              <input
                placeholder={"Max"}
                style={{ width: "100px" }}
                type="text"
                required
                ref={register}
                name="buildMax"
              />
            </Form.Field>
          </div>
        )}
        <br />
        <Form.Field>
          <label>Addons</label>
          <Controller
            placeholder="Select one or more..."
            control={control}
            name="addons"
            as={SelectHook}
            setValue={setValue}
            value={getValues("addons")}
            options={optionServices}
            multiple={true}
          />
        </Form.Field>
        <br />
        <Form.Field>
          <label>Optional Addons</label>
          <Controller
            placeholder="Select one or more..."
            control={control}
            name="unconfirmedAddons"
            as={SelectHook}
            setValue={setValue}
            value={getValues("unconfirmedAddons")}
            options={optionServices}
            multiple={true}
          />
        </Form.Field>
        <br />

        <div style={{ margin: "0 0 1em" }}>
          <label>Custom Addons</label>
          {customAddons.map((a) => (
            <div
              className={"custom-addon-item"}
              key={a.id}
              onClick={() => editCustomAddon(a.id)}
            >
              {a.title} - £{a.price}
            </div>
          ))}
        </div>
        <div
          className="create-custom-addon"
          onClick={() => setShowModal("new")}
          style={{ display: "flex", width: "190px" }}
        >
          <h4>Create custom addon</h4>
          <i class="plus icon" style={{ marginLeft: "5px" }}></i>
        </div>
        <Form.Field>
          <Controller
            setValue={setValue}
            label="Terms and Conditions"
            value={getValues("termsConditions")}
            name="termsConditions"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <Form.Field style={{ marginTop: "2.1em" }}>
          <Controller
            setValue={setValue}
            label="Squarespace Fees"
            value={getValues("squareSpacePlan")}
            name="squareSpacePlan"
            as={FormWysiwyg}
            control={control}
          />
        </Form.Field>
        <Button type="submit" className="fluid">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default connect(null, { updateProject })(ProposalCostForm);
