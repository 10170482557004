import {
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
} from "../reduxConstants";
import { getFirestore } from "redux-firestore";
import cuid from "cuid";
import { toastr } from "react-redux-toastr";
import firebase from "../firebase";
import { format } from "date-fns";

export const createProject = (project) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const projectID = cuid();
    try {
      await firestore.set(`projects/${projectID}`, {
        id: projectID,
        revisions: 0,
        rescheduled: 0,
        status: "newEnquiry",
        createdAt: firestore.FieldValue.serverTimestamp(),
        fields: [
          {
            id: "a1",
            title: "Your logo & Brand Files",
            files: [],
            formField: "dz",
            length: "",
            text: "",
            description: "",
          },
          {
            id: "a2",
            title: "Miscellaneous Files",
            files: [],
            formField: "dz",
            length: "",
            description:
              "An optional field where you can gather loose bits and pieces",
            text: "",
          },

          {
            id: "a3",
            title: "Your Notes",
            files: [],
            formField: "text",
            length: "",
            text: "",
            description:
              "An optional field where you can pass on any key info that you would like our team to know when building your website.",
          },
        ],
        ...project,
      });
      toastr.success("Success", "New Project was created");
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const updateProject = (project) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const prevProjectDoc = await firestore.get(`projects/${project.id}`);
    const prevClient = prevProjectDoc.data().client;

    try {
      await firestore.update(`projects/${project.id}`, {
        createdAt: firestore.FieldValue.serverTimestamp(),
        ...project,
      });

      if (prevClient && prevClient.length !== 0) {
        console.log(1);
        await firestore.update(`users/${prevClient}`, {
          projects: firestore.FieldValue.arrayRemove(project.id),
        });
      }

      if (project.client) {
        console.log(2);
        await firestore.update(`users/${project.client}`, {
          projects: firestore.FieldValue.arrayUnion(project.id),
        });
      }

      toastr.success("Success", "Project was updated");
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const changeField = (id, value, field) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`projects/${id}`, {
        [field]: value,
      });
      toastr.success("Success", "Field was updated");
    } catch (e) {
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const manageLinks = (id, links) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`projects/${id}`, {
        links: links,
      });
      toastr.success("Success", "Links were updated");
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const manageResources = (id, resources) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`projects/${id}`, {
        resources: resources,
      });
      toastr.success("Success", "Resources were updated");
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const changeGoogle = (id, google) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`projects/${id}`, {
        clientGoogleDrive: google,
      });
      toastr.success("Success", "Links were updated");
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const changeUrl = (id, url) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`projects/${id}`, {
        url: url,
      });
      toastr.success("Success", "Url was updated");
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const changeShowUrl = (id, val) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`projects/${id}`, {
        shareWebsiteLink: val,
      });
      toastr.success("Success", "Url was updated");
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const deleteProject = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = firebase.firestore();
    const firebaseStorage = getFirebase();
    const usersQuery = await firestore
      .collection("users")
      .where("projects", "array-contains", id);
    const projectQuery = await firestore
      .collection("projects")
      .where("id", "==", id);
    const usersSnap = await usersQuery.get();
    const projectSnap = await projectQuery.get();

    try {
      if (
        projectSnap.docs[0].data().brandingFiles &&
        projectSnap.docs[0].data().brandingFiles.length > 0
      ) {
        for (let branding of projectSnap.docs[0].data().brandingFiles) {
          dispatch(deleteFile(branding.name, `${id}-brand`));
        }
      }
      if (
        projectSnap.docs[0].data().tasks &&
        projectSnap.docs[0].data().tasks.length > 0
      ) {
        for (let task of projectSnap.docs[0].data().tasks) {
          for (let file of task.files) {
            dispatch(deleteFile(file.name, `${task.id}-task`));
          }
        }
      }
      if (
        projectSnap.docs[0].data().taskComments &&
        projectSnap.docs[0].data().taskComments.length > 0
      ) {
        for (let comment of projectSnap.docs[0].data().taskComments) {
          for (let file of comment.files) {
            dispatch(deleteFile(file.name, `${comment.id}-task`));
          }
        }
      }

      await firestore.collection(`projects`).doc(id).delete();

      for (let i = 0; i < usersSnap.docs.length; i++) {
        const userId = usersSnap.docs[i].data().id;
        await firestore
          .collection("users")
          .doc(userId)
          .update({
            projects: firebase.firestore.FieldValue.arrayRemove(id),
          });
      }

      const projectsForms = projectSnap.docs[0].data().fields || [];

      for (const field of projectsForms) {
        if (field.formField === "dz" && field.files) {
          for (const file of field.files) {
            await firebaseStorage.deleteFile(`${field.id}-custom/${file.name}`);
          }
        }
      }
      toastr.success("Success", "Project was deleted");
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const deleteFile = (fileName, folder) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    try {
      await firebase.deleteFile(`${folder}/${fileName}`);
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const toggleArchiveProject = (id, state) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const prevProjectDoc = await firestore.get(`projects/${id}`);
    const prevClient = prevProjectDoc.data().client;

    try {
      // if(prevProjectDoc.data().branding) {
      //     for (let branding of prevProjectDoc.data().branding ) {
      //         dispatch(deleteFile(branding.name, `${id}-brand`))
      //     }
      // }
      // if(prevProjectDoc.data().tasks) {
      //     for(let task of prevProjectDoc.data().tasks) {
      //         for(let file of task.files) {
      //             dispatch(deleteFile(file.name, `${task.id}-task`))
      //         }
      //     }
      // }
      // if(prevProjectDoc.data().taskComments) {
      //     for(let comment of prevProjectDoc.data().taskComments) {
      //         for(let file of comment.files) {
      //             dispatch(deleteFile(file.name, `${comment.id}-task`))
      //         }
      //     }
      // }

      if (state === "archived") {
        await firestore.update(`projects/${id}`, {
          createdAt: firestore.FieldValue.serverTimestamp(),
          status: "archived",
          client: "",
        });
        if (prevClient) {
          await firestore.update(`users/${prevClient}`, {
            projects: firestore.FieldValue.arrayRemove(id),
          });
        }
      } else {
        await firestore.update(`projects/${id}`, {
          createdAt: firestore.FieldValue.serverTimestamp(),
          status: "newEnquiry",
        });
      }

      toastr.success("Success", "Project was archived");
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const createTemplate = (project, type) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const projectID = cuid();
    try {
      if (type === "newEnquiry") {
        if (project.branding) {
          for (let branding of project.branding) {
            dispatch(deleteFile(branding.name, `${project.id}-brand`));
          }
        }
        if (project.tasks) {
          for (let task of project.tasks) {
            for (let file of task.files) {
              dispatch(deleteFile(file.name, `${task.id}-task`));
            }
          }
        }
        if (project.taskComments) {
          for (let comment of project.taskComments) {
            for (let file of comment.files) {
              dispatch(deleteFile(file.name, `${comment.id}-task`));
            }
          }
        }
        await firestore.set(`projects/${projectID}`, {
          id: projectID,
          createdAt: firestore.FieldValue.serverTimestamp(),
          title: project.title,
          status: "templates",
          pages: project.pages || [],
        });
        toastr.success("Success", "Template was created");
      } else {
        await firestore.set(`projects/${projectID}`, {
          id: projectID,
          createdAt: firestore.FieldValue.serverTimestamp(),
          title: project.title + " Template",
          status: "newEnquiry",
          pages: project.pages || [],
        });
        toastr.success("Success", "Template was added to Projects");
      }
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const addProjectComment = (projectId, text, id, author) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const comment = {
      createdAt: format(new Date(), "MM/dd/yyyy"),
      id: id,
      text: text,
      author: author,
    };

    try {
      await firestore.update(`projects/${projectId}`, {
        comments: firestore.FieldValue.arrayUnion(comment),
      });
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const deleteProjectComment = (projectId, comment) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`projects/${projectId}`, {
        comments: firestore.FieldValue.arrayRemove(comment),
      });
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const updateProjectComment = (
  project,
  commentText,
  commentId,
  author
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      const oldComments = project.comments.filter(
        (comment) => comment.id !== commentId
      );
      const comments = [
        ...oldComments,
        {
          id: commentId,
          createdAt: format(new Date(), "MM/dd/yyyy"),
          text: commentText,
          author: author,
        },
      ];
      await firestore.update(`projects/${project.id}`, {
        comments: comments,
      });
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const addProjectCommentC = (projectId, text, id, author) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    const comment = {
      createdAt: format(new Date(), "MM/dd/yyyy"),
      id: id,
      text: text,
      author: author,
    };

    try {
      await firestore.update(`projects/${projectId}`, {
        commentsC: firestore.FieldValue.arrayUnion(comment),
      });
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const deleteProjectCommentC = (projectId, comment) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`projects/${projectId}`, {
        commentsC: firestore.FieldValue.arrayRemove(comment),
      });
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const updateProjectCommentC = (
  project,
  commentText,
  commentId,
  author
) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      const oldComments = project.comments.filter(
        (comment) => comment.id !== commentId
      );
      const comments = [
        ...oldComments,
        {
          id: commentId,
          createdAt: format(new Date(), "MM/dd/yyyy"),
          text: commentText,
          author: author,
        },
      ];
      await firestore.update(`projects/${project.id}`, {
        commentsC: comments,
      });
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const addProjectJourney = (project, journey) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    try {
      console.log(journey.clientComponents);

      if (journey.id) {
        let cJourney = journey.clientComponents.map((c, idx) =>
          idx > 0 ? { ...c, status: "toDo" } : { ...c, status: "active" }
        );
        let tJourney = journey.devComponents.map((c, idx) =>
          idx > 0 ? { ...c, status: "toDo" } : { ...c, status: "active" }
        );
        await dispatch(updateProject({ ...project, cJourney, tJourney }));
      }
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const projectJourneyClick = (project, step) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      if (step.premade) {
      } else {
      }
    } catch (e) {
      console.log(e);
      toastr.error("Oops", "Something went wrong");
    }
  };
};
