import {SubmissionError, reset} from "redux-form";
import {toastr} from "react-redux-toastr";
import firebase from "../firebase";


export const login = (creds) => {
    return async (dispatch, getState, {getFirebase}) => {

        const firebase = getFirebase();

        try {
            await firebase.auth().signInWithEmailAndPassword(creds.email, creds.password);
            return firebase.auth().currentUser.displayName;
        } catch (error) {
            throw new SubmissionError({
                _error: "Email or Password is incorrect"
            })

        }

    };
};




export const changePassword = (creds) => (
    async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const user = firebase.auth().currentUser;
        const credentials = firebase.auth.EmailAuthProvider.credential(user.email, creds.currentPassword);

        try {
            await user.reauthenticateWithCredential(credentials);
            await user.updatePassword(creds.newPassword);
            await firebase.firestore().collection('users').doc(user.uid).update({password: creds.newPassword});
            await dispatch(reset("newPassword"));
            toastr.success('Success', 'Your password has been updated');
        } catch (error) {
            throw new SubmissionError({
                _error: error.message
            })
        }
    }
);

export const changeProfileInfo = (data) => (
    async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const user = firebase.auth().currentUser;


        try {
            await firebase.firestore().collection("users").doc(user.uid).update({...data});
            toastr.success('Success', 'Your profile has been updated');
        } catch (error) {
            throw new SubmissionError({
                _error: "Invalid input"
            })
        }

    }
);