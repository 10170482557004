import React, { Component, Fragment, useCallback, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Input, Dropdown, Modal, Label } from "semantic-ui-react";

import "./content-manager.css";
import FormSubmitButton from "../../form-components/form-submit-button/form-submit-button";
import firebase from "../../../firebase";
import { compose } from "redux";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import {
  updateFormComponent,
  uploadCustomFile,
} from "../../../actions/form-actions";
import DndzoneForm from "../../drag-and-drop-form/dndzone-form";
import cuid from "cuid";
import { getOutputFileNames } from "typescript";

const options = [
  { key: "01", value: "input", text: "Short Text" },
  { key: "02", value: "text", text: "Long Text" },
  { key: "04", value: "dz", text: "Upload" },
];

const SectionModal = ({
  handleSubmitModal,
  handleModalState,
  val,
  toggleModal,
  modalOpened,
}) => (
  <Modal open={modalOpened} onClose={toggleModal}>
    <Modal.Header>Create Section</Modal.Header>
    <Modal.Content>
      <Form action="" onSubmit={handleSubmitModal}>
        <Form.Field>
          <Input
            placeholder={"Title"}
            type="text"
            required
            value={val}
            onChange={(e) => handleModalState({ createTitle: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <FormSubmitButton text={"Add Section"} />
        </Form.Field>
      </Form>
    </Modal.Content>
  </Modal>
);

const EditForm = ({
  handleSubmitModal,
  data,
  handleClose,
  handleModalChange,
}) => (
  <Form action="" onSubmit={handleSubmitModal}>
    <Form.Field>
      <Input
        type="text"
        placeholder="Title"
        required
        value={data.editTitle}
        onChange={(e) => handleModalChange("editTitle", e.target.value)}
      />
    </Form.Field>
    <Form.Field>
      <Input
        type="text"
        placeholder="Character / image limit"
        pattern="[0-9]*$"
        value={data.editLength}
        onChange={(e) => handleModalChange("editLength", e.target.value)}
      />
    </Form.Field>
    <Form.TextArea
      placejolder="Description..."
      value={data.editDesc}
      onChange={(e) => handleModalChange("editDesc", e.target.value)}
    />
    {data.type !== "label" && (
      <Form.Field>
        <Dropdown
          placeholder="Select a field type"
          search
          selection
          options={options}
          value={data.editFormField}
          onChange={(e, data) => handleModalChange("editFormField", data.value)}
        />
      </Form.Field>
    )}
    <Form.Field>
      <FormSubmitButton text={"Update Field"} />
    </Form.Field>
    <Form.Field>
      <button className="fluid ui button" onClick={handleClose}>
        Cancel
      </button>
    </Form.Field>
  </Form>
);

const actions = {
  updateFormComponent,
};

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.id;

  const projects = state.firestore.ordered.projects;
  const project =
    projects && projects.filter((project) => project.id === projectId)[0];

  return {
    projectId,
    project,
  };
};

class ContentManager extends Component {
  state = {
    modalOpened: false,
    formsToDisplay: [],
    createTitle: "",
    createLength: "",
    createDesc: "",
    createFormField: "",
    editFormOpen: false,
    editTitle: "",
    editLength: "",
    editDesc: "",
    editFormField: "",
    supportFiles: [],
  };

  handleModalState = (obj) => {
    this.setState(obj);
  };

  handleModalSubmit = async (e) => {
    e.preventDefault();

    const dataObj = {
      title: this.state.createTitle,
      length: "",
      description: "",
      formField: "section-label",
      files: [],
      text: "",
      id: cuid(),
    };
    await this.setState((prevState) => ({
      modalOpened: false,
      createTitle: "",
      createLength: "",
      createDesc: "",
      createFormField: "",
      formsToDisplay: [...prevState.formsToDisplay, dataObj],
    }));

    await this.props.updateFormComponent(
      this.state.formsToDisplay,
      this.props.projectId
    );
  };

  toggleModal = async () => {
    await this.setState((prevState) => ({
      modalOpened: !prevState.modalOpened,
      createTitle: "",
      createLength: "",
      createDesc: "",
      createFormField: "",
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.createFormField.length === 0) {
      return;
    }

    const dataObj = {
      title: this.state.createTitle,
      length: this.state.createLength,
      description: this.state.createDesc,
      formField: this.state.createFormField,
      files: this.state.supportFiles,
      text: "",
      id: cuid(),
    };

    await this.setState((prevState) => ({
      createTitle: "",
      createLength: "",
      createDesc: "",
      createFormField: "",
      formsToDisplay: [...prevState.formsToDisplay, dataObj],
    }));

    await this.props.updateFormComponent(
      this.state.formsToDisplay,
      this.props.projectId
    );
  };

  handleModalChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleClose = () => {
    this.setState({ editFormOpen: false });
  };

  async componentDidMount() {
    const { firestore } = this.props;
    await firestore.setListener(`projects`);

    const db = firebase.firestore();

    const projectQuery = await db
      .collection("projects")
      .doc(this.props.match.params.id);
    const form = await projectQuery.get();
    const formComponents = form.data().fields;
    await this.setState({
      formsToDisplay: formComponents || [],
    });
  }

  handleOpenModal = async (field) => {
    await this.setState({
      editTitle: field.title,
      editLength: field.length || "",
      editDesc: field.description || "",
      editFormField: field.formField,
      editFormId: field.id,
    });

    await this.setState({
      editFormOpen: true,
    });
  };

  handleSubmitModal = async (e) => {
    e.preventDefault();

    const updatedObj = {
      title: this.state.editTitle,
      length: this.state.editLength,
      description: this.state.editDesc,
      formField: this.state.editFormField,
      id: this.state.editFormId,
    };

    const fields = this.state.formsToDisplay.map((field) => {
      if (field.id === this.state.editFormId) {
        return { ...field, ...updatedObj };
      }
      return field;
    });

    await this.setState({
      editFormOpen: false,
      formsToDisplay: fields,
    });

    await this.props.updateFormComponent(
      this.state.formsToDisplay,
      this.props.projectId
    );
  };

  handleDelete = async (component) => {
    const index = this.state.formsToDisplay.indexOf(component);
    const oldFieldsOrder = [...this.state.formsToDisplay];
    oldFieldsOrder.splice(index, 1);
    await this.setState({
      formsToDisplay: oldFieldsOrder,
      editFormOpen: false,
    });
    this.props.updateFormComponent(oldFieldsOrder, this.props.projectId);
  };

  handleDrop = async (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const oldFieldsOrder = [...this.state.formsToDisplay];

    const draggedComponent = oldFieldsOrder.filter(
      (component) => component.id === draggableId
    )[0];
    oldFieldsOrder.splice(source.index, 1);
    oldFieldsOrder.splice(destination.index, 0, draggedComponent);

    await this.setState({ formsToDisplay: oldFieldsOrder });
    await this.props.updateFormComponent(oldFieldsOrder, this.props.projectId);
  };

  imageHandler = async (event) => {
    const storageRef = firebase.storage().ref();
    const files = event.target.files;
    const fileArr = Array.from(files);
    fileArr.forEach(async (file) => {
      const fileRef = storageRef.child(file.name);
      await fileRef.put(file);
      const fileURL = await fileRef.getDownloadURL();
      console.log(fileURL);
      // const fileObj = { name: fileRef.name, url: fileURL };
      // this.state.supportFiles.push(fileObj);

      await this.setState((prevState) => ({
        supportFiles: [
          ...prevState.supportFiles,
          {
            name: fileRef.name,
            url: fileURL,
          },
        ],
      }));
      //   supportFiles:
      //     prevState.supportFiles,
      //     fileObj,
      // { ...prevState.supportFiles },
      // {
      //   name: fileRef.name,
      //   url: fileURL,
      // },
      // }));
    });
  };

  // const file = event.target.files[0];
  // const fileRef = storageRef.child(file.name);
  // await fileRef.put(file);
  // const fileURL = await fileRef.getDownloadURL();
  // this.setState({
  //   supportFiles: {
  //     name: fileRef.name,
  //     url: fileURL,
  //   },
  // });

  // const fileURL = await fileRef.getDownloadURL();
  // console.log(fileURL);

  // .then((snapshot) => {
  //   console.log(snapshot);
  // this.setState({
  //   supportFiles: {
  //     name: snapshot.metadata.name,
  //     image: snapshot.metadata.fullPath,
  //   },
  // });

  render() {
    return (
      <div className="content-manager">
        {/*<div className="row">*/}
        {/*    <div className="sixteen wide column" style={{paddingBottom: "0px!important"}}>*/}
        {/*        <div className="content-manager-top">*/}
        {/*            <Link to={`/dashboard/project-overview/${this.props.projectId}`}>*/}
        {/*                <img src={"/assets/icons/arrow.svg"} style={{height: "30px"}} alt=""/>*/}
        {/*                &nbsp;&nbsp;Back to project*/}
        {/*            </Link>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <SectionModal
          modalOpened={this.state.modalOpened}
          val={this.state.createTitle}
          toggleModal={this.toggleModal}
          handleModalState={this.handleModalState}
          handleSubmitModal={this.handleModalSubmit}
        />
        <div className="content-manager-side">
          {this.state.editFormOpen ? (
            <div>
              <EditForm
                handleClose={this.handleClose}
                handleSubmitModal={this.handleSubmitModal}
                handleModalChange={this.handleModalChange}
                data={this.state}
              />
            </div>
          ) : (
            <div>
              <Form action="" onSubmit={this.handleSubmit}>
                <Form.Field>
                  <Input
                    placeholder={"Title"}
                    type="text"
                    required
                    value={this.state.createTitle}
                    onChange={(e) =>
                      this.setState({ createTitle: e.target.value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    placeholder="Select a field type"
                    value={this.state.createFormField}
                    search
                    selection
                    options={options}
                    onChange={(e, data) =>
                      this.setState({ createFormField: data.value })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    placeholder={"Character / image limit"}
                    type="text"
                    value={this.state.createLength}
                    pattern="[0-9]*$"
                    onChange={(e) =>
                      this.setState({ createLength: e.target.value })
                    }
                  />
                </Form.Field>
                <Form.TextArea
                  placeholder={"Description..."}
                  value={this.state.createDesc}
                  onChange={(e) =>
                    this.setState({ createDesc: e.target.value })
                  }
                />
                {this.state.createFormField === "dz" && (
                  <Form.Field>
                    <Input
                      type="file"
                      value={this.state.supportImages}
                      accept="image/*"
                      multiple
                      onChange={this.imageHandler}
                    />
                  </Form.Field>
                )}
                <Form.Field>
                  <FormSubmitButton text={"ADD FIELD"} />
                </Form.Field>
              </Form>
              <button
                className="fluid ui button"
                onClick={this.toggleModal}
                style={{ width: "400px", marginLeft: "50px" }}
              >
                ADD SECTION
              </button>
            </div>
          )}
        </div>
        <div className="content-manager-main">
          <p
            className="content-client-view"
            onClick={(e) =>
              window.open(
                `/dashboard/project-overview-client/${this.props.projectId}/content`
              )
            }
          >
            Client's view
          </p>
          <DndzoneForm
            handleDelete={this.handleDelete}
            fields={this.state.formsToDisplay}
            handleOpenModal={this.handleOpenModal}
            handleDrop={this.handleDrop}
            toggleModal={this.toggleModal}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirestore,
  firestoreConnect([{ collection: "projects" }]),
  connect(mapStateToProps, actions)
)(ContentManager);
