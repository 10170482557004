import firebase from "../firebase";
import cuid from "cuid";
import {toastr} from "react-redux-toastr";


export const createPage = (pageName, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();

        try {
            await firestore.update(`projects/${projectId}`, {
                    pages: firestore.FieldValue.arrayUnion({
                        pageName: pageName,
                        id: cuid(),
                        components: [],
                        description: '',
                        comments: [],
                    })
                });
            toastr.success("Success", "New Page was created")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const updatePageName = (page, pageName, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();


        try {

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayRemove(page)
            });

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayUnion({
                    ...page,
                    pageName: pageName
                })
            });
            toastr.success("Success", "Page Name was updated")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const deletePage = (page, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();

        try {

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayRemove(page)
            });

            toastr.success("Success", "Page was deleted")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const insertComment = (page, desc, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();

        try {

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayRemove(page)
            });

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayUnion({
                    ...page,
                    description: desc,
                })
            });
            toastr.success("Success", "Description uploaded")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const addComment = (page, text, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();

        try {

            const comments = [...page.comments, {id: cuid(),text:text}];



            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayRemove(page)
            });

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayUnion({
                    ...page,
                    comments,
                })
            });
            toastr.success("Success", "Comment Added")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const updateComment = (page, text, id, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();

        try {

            let oldComments = page.comments.filter(comment => comment.id !== id);
            let comments = [...oldComments, {id: id, text:text}]


            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayRemove(page)
            });

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayUnion({
                    ...page,
                    comments,
                })
            });
            toastr.success("Success", "Comment Updated")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const deleteComment = (page, commentId, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();

        try {

            const comments = page.comments.filter(comment => comment.id !== commentId);



            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayRemove(page)
            });

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayUnion({
                    ...page,
                    comments,
                })
            });
            toastr.success("Success", "Comment Deleted")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const addPremadePage = (pageName) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const id = cuid()
        try {
            await firestore.set(`premade_pages/${id}`, {
                    pageName: pageName,
                    id: id,
                    components: [],
                });
                toastr.success("Success", "New Premade Page was created")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const deletePremadePage = (id) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        try {
            await firestore.delete(`premade_pages/${id}`);
            toastr.success("Success", "Page was deleted")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};


export const clearPremadePage = (id) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        try {
            await firestore.update(`premade_pages/${id}`, {
               components: [],
            });
            toastr.success("Success", "Page was cleared")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};

export const updatePremadePageComponents = (id, components) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        try {
            await firestore.update(`premade_pages/${id}`, {
                components: components,
            });
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};

export const updatePremadePageName = (name, id) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        try {
            await firestore.update(`premade_pages/${id}`, {
                pageName: name,
            });
            toastr.success("Success", "Page name was updated");
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};

export const updateComponentNote = (note, orderId, projectId, componentsToDisplay, page) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();

        let components = componentsToDisplay;
        console.log(components);
        const oldComponent = componentsToDisplay.filter(component => component.orderId === orderId)[0];
        console.log(oldComponent);
        console.log({...oldComponent, note:note});
        components[componentsToDisplay.indexOf(oldComponent)] = {...oldComponent, note:note};
        console.log(components);


        try {

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayRemove(page)
            });

            await firestore.update(`projects/${projectId}`, {
                pages: firestore.FieldValue.arrayUnion({
                    ...page,
                    components: components
                })
            });

            return components;
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};