import React, {Component} from 'react';

import "./project-overview-comment.css";

class ProjectOverviewComment extends Component {

    render() {

        const {text, date} = this.props;

        return (
            <div className="ui message">
                <span className={"project-overview-comment-edit"}><i className="edit outline icon"></i></span>
                <i className="close icon"/>
                <p>{text}</p>
                <p className="project-overview-comment-date">{date}</p>
            </div>
        );
    }
}

export default ProjectOverviewComment;