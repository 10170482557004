import React, {Component} from 'react';

import "./admin-settings.css";

import DashboardHeader from "../../layout-components/dashboard-header/dashboard-header";
import DashboardUsersTable from "../../layout-components/dashboard-users-table/dashboard-users-table";
import FormNewButton from "../../form-components/form-new-button/form-new-button";
import {deleteUser} from "../../../actions/user-actions";
import {connect} from "react-redux";
import {compose} from "redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import Loading from "../../loading/loading";
import AdminSettingsTableC from "./admin-settings-table-c";
import {Link} from "react-router-dom";
import {deleteComponent} from "../../../actions/component-actions";


const mapStateToProps = state => ({
    components: state.firestore.ordered.components || []
});

const actions = {
    deleteComponent
};

class Sketch extends Component {

    onDeleteComponent = (id, fileName, iconName) => {
        this.props.deleteComponent(id, fileName, iconName);
    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`components`);
    }

    async componentWillUnmount() {
        const {firestore} = this.props;
        await firestore.unsetListener(`components`);
    }

    render(){

        if(!this.props.components) {
            return <Loading/>
        }

        return (
            <div className="admin-settings-content-table">
                <h2 className="admin-settings-content-table-h">Components</h2>
                <div style={{display: "flex"}}>
                    <div className="admin-settings-content-table-add"><Link to={"/dashboard/components/create"}>+ Add new Component</Link></div>
                    <div style={{marginLeft: "40px"}} className="admin-settings-content-table-add"><Link to={"/dashboard/components/premade-pages"}>Manage Premade Pages</Link></div>
                </div>

                <AdminSettingsTableC
                    deleteComponent={this.onDeleteComponent}
                    components={this.props.components}
                />
            </div>
        );
    }
}


export default compose(
    withFirestore,
    firestoreConnect([{collection: "users"}]),
    connect(mapStateToProps, actions)
)(Sketch)






