import React, {Component} from 'react';
import {Link} from "react-router-dom";
import DashboardProfile from "../dashboard-profile/dashboard-profile";


class Profile extends Component {
    render() {
        return (
            <div className="admin-settings-content-table">
                <h2 className="admin-settings-content-table-h">Profile Settings</h2>
                <DashboardProfile/>
            </div>
        );
    }
}

export default Profile;