import React from "react";

import "./form-submit.css";

const FormSubmitButton = ({text, submit, disabled}) => {
    return (
        <div className="form-submit-button">
            <button className="fluid ui button" disabled={disabled}>{text} </button>
        </div>
    );
};

export default FormSubmitButton;