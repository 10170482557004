import React, { Component, Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { Droppable } from "react-beautiful-dnd";
// import { Draggable } from "react-beautiful-dnd";

import "./dndzone.css";
import { updateComponentNote } from "../../actions/pages-actions";
import { Confirm, Dropdown } from "semantic-ui-react";

const Wireframe = ({
  component,
  idx,
  onFormClick,
  formData,
  handleBackground,
  openLink,
  onChange,
  submitNoteForm,
  handlePadding,
  handleDeleteComp,
  disableForm,
}) => (
  <Draggable
    key={component.orderId}
    draggableId={component.orderId}
    index={idx}
  >
    {(provided, snapshot) => (
      <div
        key={component.orderId}
        className={"pages-sketches-left-page-image"}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={getStyle(provided.draggableProps.style, snapshot)}
      >
        {component.image === "none" ? (
          <>
            <section
              className={"insert-component-page-sketches"}
              style={{
                padding: `${(Number(component.padding) || 0) + 20}px 0`,
                background: `${component.background || "#FFFFFF"}`,
              }}
            >
              <p>{component.text}</p>
            </section>
            <Dropdown
              text=""
              icon="ellipsis horizontal"
              className="page-sketches-delete-comp"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Delete component"
                  onClick={() => handleDeleteComp(component)}
                />
                <Dropdown.Item
                  text="Add grey background"
                  onClick={() => handleBackground(component, "#F5F5F5")}
                />
                <Dropdown.Item
                  text="Add white background"
                  onClick={() => handleBackground(component, "#FFFFFF")}
                />
                <Dropdown.Item
                  text="Add small padding"
                  onClick={() => handlePadding(component, 20)}
                />
                <Dropdown.Item
                  text="Add medium padding"
                  onClick={() => handlePadding(component, 40)}
                />
                <Dropdown.Item
                  text="Add large padding"
                  onClick={() => handlePadding(component, 60)}
                />
                <Dropdown.Item
                  text="Remove padding"
                  onClick={() => handlePadding(component, 0)}
                />
              </Dropdown.Menu>
            </Dropdown>
          </>
        ) : (
          <>
            <img
              loading="lazy"
              src={
                component.image ===
                  "https://firebasestorage.googleapis.com/v0/b/david-and-george-cms.appspot.com/o/whitespace%2Fwhite-stripe.png?alt=media&token=95f57a21-8bea-4456-8d26-9a0e5e0aca02" &&
                component.background === "#F5F5F5"
                  ? "/assets/form/grey.png"
                  : component.image
              }
              alt="wireframe"
              style={{
                padding: `${component.padding || 0}px 0`,
                background: `${component.background || "#FFFFFF"}`,
              }}
            />
            <Dropdown
              text=""
              icon="ellipsis horizontal"
              className="page-sketches-delete-comp"
            >
              <Dropdown.Menu>
                {console.log(`Component DND: ${component.key}`)}
                <Dropdown.Item
                  text="Build Instructions"
                  onClick={() => openLink(component.url)}
                />
                <Dropdown.Item
                  text="Delete component"
                  onClick={() => handleDeleteComp(component)}
                />
                <Dropdown.Item
                  text="Add grey background"
                  onClick={() => handleBackground(component, "#F5F5F5")}
                />
                <Dropdown.Item
                  text="Add dark grey background"
                  onClick={() => handleBackground(component, "#5e605f")}
                />
                <Dropdown.Item
                  text="Add white background"
                  onClick={() => handleBackground(component, "#FFFFFF")}
                />
                <Dropdown.Item
                  text="Add small padding"
                  onClick={() => handlePadding(component, 20)}
                />
                <Dropdown.Item
                  text="Add medium padding"
                  onClick={() => handlePadding(component, 40)}
                />
                <Dropdown.Item
                  text="Add large padding"
                  onClick={() => handlePadding(component, 60)}
                />
                <Dropdown.Item
                  text="Remove padding"
                  onClick={() => handlePadding(component, 0)}
                />
              </Dropdown.Menu>
            </Dropdown>
            {formData.showForm && formData.showFormId === component.orderId ? (
              <form
                className="page-sketches-form-image"
                action=""
                onSubmit={(e) => submitNoteForm(e, component.orderId)}
              >
                <input
                  onChange={(e) => onChange(e)}
                  value={formData.formText}
                  autoFocus
                  type="text"
                  className={""}
                  maxLength="50"
                  disabled={disableForm}
                />
              </form>
            ) : (
              <Fragment>
                <p
                  onClick={() => onFormClick(component)}
                  className={"dnd-zone-item-text"}
                >
                  {component.note || "Insert Description"}
                </p>
                {/*{component.url && component.url.length !== 0 &&*/}
                {/*<i className="icon file wireframe-arrow" onClick={() => openLink(component.url)}/>*/}
                {/*}*/}
              </Fragment>
            )}
          </>
        )}
      </div>
    )}
  </Draggable>
);

function getStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.0001s!important`,
  };
}

class DragAndDropZone extends Component {
  state = {
    showForm: false,
    showFormId: "",
    formText: "",
  };

  onFormClick = async (component) => {
    await this.setState({
      showForm: true,
      showFormId: component.orderId,
      formText: component.note,
    });
  };

  onChange = (e) => {
    this.setState({
      formText: e.target.value,
    });
  };

  openLink = (link) => {
    if (link) {
      window.open(link);
    }
  };

  submitNoteForm = async (e, id) => {
    e.preventDefault();
    const components = await this.props.updateComponentNote(
      this.state.formText,
      id,
      this.props.projectId,
      this.props.components,
      this.props.page
    );
    this.setState({
      showForm: false,
      showFormId: "",
      formText: "",
    });
    await this.props.reloadComponents(components);
  };

  render() {
    const {
      components,
      handleDrop,
      handleDeleteComp,
      handleBackground,
      disableForm,
      handlePadding,
    } = this.props;

    return (
      <div>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={"1"}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className={"pages-sketches-left-page-images"}>
                  {components &&
                    components.map((component, idx) => (
                      <Wireframe
                        handlePadding={handlePadding}
                        handleBackground={handleBackground}
                        disableForm={disableForm}
                        handleDeleteComp={handleDeleteComp}
                        submitNoteForm={this.submitNoteForm}
                        onChange={this.onChange}
                        openLink={this.openLink}
                        formData={this.state}
                        onFormClick={this.onFormClick}
                        key={component.orderId}
                        component={component}
                        idx={idx}
                      />
                    ))}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default DragAndDropZone;
