import {toastr} from "react-redux-toastr";
import cuid from "cuid";
import firebase from "../firebase";

export const manageJourney = (components, type, id) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        try {
            await firestore.update(`journey/${id}`, {
                [type]: components
            });
            //toastr.success("Success", "Tasks were updated")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }

};

export const createJourney = (journey) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        try {
            await firestore.set(`journey/${journey.id}`, journey);
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const deleteJourney = (id) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const db= firebase.firestore();

        try {
            const projectsQuery = await db.collection('projects').where("journey", "==", id);
            const projectsSnap = await projectsQuery.get();
            for(let i = 0; i < projectsSnap.docs.length; i++) {
                const projectId = projectsSnap.docs[i].data().id;
                await db.collection('projects').doc(projectId).update({
                    journey: ""
                })
            }

            await firestore.delete(`journey/${id}`);
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};