import React, {Component} from 'react';

import "./dashboard-notifications.css";

import DashboardHeader from "../../layout-components/dashboard-header/dashboard-header";
import {deleteProject, toggleArchiveProject, createTemplate} from "../../../actions/project-actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Loading from "../../loading/loading";
import {firestoreConnect} from "react-redux-firebase";
import NotificationsList from "./notifications-list";

const mapStateToProps = state => ({
    projects: state.firestore.ordered.projects,
});

const actions = {
    deleteProject,
    toggleArchiveProject,
    createTemplate,
};

const notifications = [
    {
        id: 1,
        title: "Blah 1",
        body: "test test",
        category: "depositRequests",
    },
    {
        id: 2,
        title: "Blah 2",
        body: "test test",
        category: "depositRequests",
    },
    {
        id: 3,
        title: "Blah 3",
        body: "test test",
        category: "depositRequests",
    },
    {
        id: 4,
        title: "Blah 4",
        body: "test test",
        category: "contentDeadline",
    }
]

class DashboardNotifications extends Component {

    state = {
        filter: "all"
    };


    render() {

        return (
            <div className="dashboard">
                <DashboardHeader changeSearch={this.changeSearch}/>
                <div className="notifications-cont">
                    <div className="notifications-panel-left">
                        <div className="notifications-panel-list">
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "all"})}
                                    className={`${this.state.filter === "all" && "panel-list-active"}`}
                                >All
                                </span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "proposalRequired"})}
                                    className={`${this.state.filter === "proposalRequired" && "panel-list-active"}`}
                                >Proposal required</span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "depositRequests"})}
                                    className={`${this.state.filter === "depositRequests" && "panel-list-active"}`}
                                >Deposit requests
                                </span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "productionHandovers"})}
                                    className={`${this.state.filter === "productionHandovers" && "panel-list-active"}`}
                                >Production handovers</span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "clientBookingSession"})}
                                    className={`${this.state.filter === "clientBookingSession" && "panel-list-active"}`}
                                >Client booking session</span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "prepContentForm"})}
                                    className={`${this.state.filter === "prepContentForm" && "panel-list-active"}`}
                                >Prep content form</span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "contentDeadline"})}
                                    className={`${this.state.filter === "contentDeadline" && "panel-list-active"}`}
                                >Content deadline</span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "bookBuild"})}
                                    className={`${this.state.filter === "bookBuild" && "panel-list-active"}`}
                                >Book build</span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "buildToday"})}
                                    className={`${this.state.filter === "buildToday" && "panel-list-active"}`}
                                >Build today</span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "seniorBuilder"})}
                                    className={`${this.state.filter === "seniorBuilder" && "panel-list-active"}`}
                                >Senior builder</span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "testingRequired"})}
                                    className={`${this.state.filter === "testingRequired" && "panel-list-active"}`}
                                >Testing required</span>
                            </div>
                            <div className="notifications-panel-list-item">
                                <span
                                    onClick={e => this.setState({filter: "SEOInvoice"})}
                                    className={`${this.state.filter === "SEOInvoice" && "panel-list-active"}`}
                                >SEO & invoice</span>
                            </div>
                        </div>
                    </div>
                    <div className="notifications-panel-right">
                        <NotificationsList notifications={notifications} filter={this.state.filter}/>
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, actions)(firestoreConnect([{collection: 'projects'}])(DashboardNotifications));
