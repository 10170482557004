import React, {Component} from 'react';
import {Input} from "semantic-ui-react";

import "./client-input.css";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const placeholder = "Content will automatically save when you click outside of the form...";

const editorConfiguration = {
    toolbar: [ 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
};

class ClientInput extends Component {

    state = {
        text: "",
        remainingLength: null,
        showOriginal: true,
    };

    handleChange = (val) => {
        this.setState(prevState =>({
            text: val,
            remainingLength: prevState.remainingLength + (prevState.text.replace(/<\/?[^>]+(>|$)/g, "").length - val.replace(/<\/?[^>]+(>|$)/g, "").length),
        }));
    };

     componentWillMount() {
        if(this.props.form.text && this.props.form.text.length > 0) {
            this.setState({text: this.props.form.text})
        }
        if(this.props.form.length) {
            this.setState({ remainingLength: this.props.form.text ? Number(this.props.form.length) - this.props.form.text.length : Number(this.props.form.length)})
        }
    };

    render() {

        const {form, updateForm} = this.props;
        console.log("First Render");
        console.log(this.state.text);


        return (
            <div className="client-input">
                <h4>{form.title}</h4>
                {form.description.length > 0 && <p>{form.description}</p>}
                {form.formField === "input" ?
                    <Input className={"client-input-input"} onBlur={e => updateForm(form, this.state.text)} value={this.state.text} onChange={e => this.handleChange(e.target.value)} fluid placeholder={placeholder} type="text"/>
                :
                <CKEditor
                    className={"client-input-input"}
                    config={editorConfiguration}
                    data={this.state.text}
                    editor={ ClassicEditor }
                    onBlur={(event, editor) => updateForm(form, this.state.text)}
                    onChange={(event, editor) => this.handleChange(editor.getData())}
                />
                }

                {form.length  && this.state.remainingLength > 0 &&
                <p className={"client-input-counter"}>{this.state.remainingLength} characters remaining</p>
                }
                {form.length && this.state.remainingLength < 0 &&
                <p className={"client-input-counter"} style={{color: "red"}}>{this.state.remainingLength}  | Your text is a little bit longer than we would recommend</p>
                }
            </div>
        );
    }
}

export default ClientInput;