import React, {Component} from 'react';

import "./admin-settings.css";

import DashboardHeader from "../../layout-components/dashboard-header/dashboard-header";
import DashboardUsersTable from "../../layout-components/dashboard-users-table/dashboard-users-table";
import FormNewButton from "../../form-components/form-new-button/form-new-button";
import {deleteUser} from "../../../actions/user-actions";
import {connect} from "react-redux";
import {compose} from "redux";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import Loading from "../../loading/loading";
import AdminSettingsTable from "./admin-settings-table";
import {Link} from "react-router-dom";
import {deletePricing} from "../../../actions/pricing-actions";
import AdminSettingsTablePricing from "./admin-settings-table-pricing";

const mapStateToProps = state => ({
    pricing: state.firestore.ordered.pricing || [],
});

const actions = {
    deletePricing
};

class PricingPackages extends Component {

    onDeletePricing = (id, type) => {
        this.props.deletePricing(id, type);
    };

    async componentDidMount() {
        const {firestore} = this.props;
        await firestore.setListener(`pricing`);
    }

    async componentWillUnmount() {
        const {firestore} = this.props;
        await firestore.unsetListener(`pricing`);
    }


    render() {

        if(!this.props.pricing) {
            return <Loading/>
        }

        return (
            <div className="admin-settings-content-table">
                <h2 className="admin-settings-content-table-h">Pricing & Packages</h2>
                <div className="admin-settings-content-table-add"><Link to={"/dashboard/pricing/create"}>+ Add new pricing</Link></div>
                <AdminSettingsTablePricing
                    deletePricing={this.onDeletePricing}
                    pricing={this.props.pricing}
                />
            </div>
        );
    }
}


export default compose(
    withFirestore,
    firestoreConnect([{collection: "users"}]),
    connect(mapStateToProps, actions)
)(PricingPackages)






