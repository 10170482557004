import React from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";

import "./dashboard-header.css";

import DashboardSearchBar from "../dashboard-search-bar/dashboard-search-bar";
import DashboardNavbar from "../dashboard-navbar/dashboard-navbar";

const DashboardHeader = ({ changeSearch, logout, history }) => {
  return (
    <div className={"dashboard-header"} style={{ paddingTop: "30px" }}>
      <DashboardSearchBar
        changeSearch={changeSearch}
        logout={logout}
        history={history}
      />
      <DashboardNavbar />
    </div>
  );
};

export default DashboardHeader;
