import {toastr} from "react-redux-toastr";


export const manageTasks = (tasks, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        try {
            await firestore.update(`projects/${projectId}`, {
                tasks: tasks
            });
            //toastr.success("Success", "Tasks were updated")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }

};

export const manageTaskComments = (taskComments, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        try {
            await firestore.update(`projects/${projectId}`, {
                taskComments: taskComments
            });
            //toastr.success("Success", "Tasks were updated")
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }

};

export const uploadFile = (image, taskId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const firebase = getFirebase();
        const options = {
            name: image.name,

        };

        try {
            const uploadedFile = await firebase.uploadFile(`${taskId}-task`, image, null, options);
            let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
            return  downloadURL;
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};

export const archiveRevision = (projectId, tasks, comments) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        try {
            await firestore.update(`projects/${projectId}`, {
                taskComments: [],
                tasks: [],
                prevTasks: firestore.FieldValue.arrayUnion(...tasks)
            });
            for(let task of tasks) {
                for(let file of task.files) {
                    dispatch(deleteFile(file.name, task.id))
                }
            }
            for(let comment of comments) {
                for(let file of comment.files) {
                    dispatch(deleteFile(file.name, comment.id))
                }
            }
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }

    }
};

export const deleteFile = (fileName, formId) => {
    console.log(fileName, formId);
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const firebase = getFirebase();

        try {
            await firebase.deleteFile(`${formId}-task/${fileName}`);
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};

export const uploadFileBranding = (image, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const firebase = getFirebase();
        const options = {
            name: image.name,

        };

        try {
            const uploadedFile = await firebase.uploadFile(`${projectId}-brand`, image, null, options);
            let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
            return  downloadURL;
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};

export const deleteFileBranding = (fileName, projectId) => {
    return async (dispatch, getState, {getFirestore, getFirebase}) => {
        const firestore = getFirestore();
        const firebase = getFirebase();

        try {
            await firebase.deleteFile(`${projectId}-brand/${fileName}`);
        } catch (e) {
            console.log(e);
            toastr.error('Oops', 'Something went wrong');
        }
    }
};

