import React from 'react';

import "./form-edit-button.css";

import {Link} from "react-router-dom";

const FormEditButton = ({title, link}) => {
    return (
        <Link to={link}>
            <button className="ui button">{title}</button>
        </Link>
    )
};

export default FormEditButton;
