import React, {Component} from 'react';
import Masonry from "react-masonry-css";
import {Modal} from "semantic-ui-react";

class AddPremade extends Component {
    render() {

        const {open, handlePremade, handleClose, premadePages} = this.props;

        console.log(open);

        return (
            <Modal className="modal-masonry" open={open} closeIcon onClose={handleClose}>
                <Modal.Header>Select a premade page</Modal.Header>
                <Modal.Content image>
                        <Masonry
                            breakpointCols={3}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column">
                            {premadePages && premadePages.map((page,i) => (
                                <div key={i} onClick={() => handlePremade(page)}>
                                    {page.components && page.components.map((component,i) => (
                                        <img key={i} src={component.image} alt=""/>
                                    ))}
                                    <p>{page.pageName}</p>
                                </div>
                            ))}
                        </Masonry>
                </Modal.Content>
            </Modal>
        );
    }
}

export default AddPremade;